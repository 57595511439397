import {useRef,useState,useCallback,useEffect} from 'react';
import {Button, Table, Spin, Modal, Col, Row,Form,Input,Select,message} from 'antd';
import Header from '../../../components/HeaderList';
import {ReactComponent as Edit} from '../../../assets/icons/edit.svg';
import {ReactComponent as Trash} from '../../../assets/icons/trash.svg';
import {ReactComponent as Lock} from '../../../assets/icons/lock.svg';
import {getColumnSearchProps} from '../../../utils/tables';
import styles from '../styles/UsuariosUI.module.css';
import React from 'react';
import { MaskedInput } from 'antd-mask-input';
import moment from 'moment';
import {createReferencia} from '../../../firebase/queryProvider';
import { updateDocument,createDocument} from '../../../firebase/queryProvider';
import instance from "../../../api/Request";

const {Option} = Select;

const UsuariosUI = props => {
  const searchInput = useRef(null);
  const [sending, handleSending] = useState(false);
  const [sendingPass, handleSendingPass] = useState(false);
  const [form] = Form.useForm();
  const [formPass] = Form.useForm();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [visibleSucursal, setVisibleSucursal] = useState(false);
  const [visibleSucursalMultiple, setVisibleSucursalMultiple] = useState(false);
  const [visibleCategoria, setVisibleCategoria] = useState(false);
  const [visibleCategoriaMultiple, setVisibleCategoriaMultiple] = useState(false);
  const [disabledPass, setDisabledPass] = useState(false);
  const [tipoUsuario, setTipoUsuario] = useState([
    {key:"GerenteOperaciones",value:"Gerente Operaciones"},
    {key:"GerenteZona",value:"Gerente de Zona"},
    {key:"GerenteRestaurante",value:"Gerente de Restaurante"},
    {key:"SupervisorTicket",value:"Supervisor de Tickets"},
    {key:"Agentes",value:"Agentes"},
    {key:"Meseros",value:"Meseros"}
  ]);

  const {
    loading,
    users,
    showDetails,
    showDetailsPass,
    confirmDelete,
    newUser,
    sucursales,
    categorias,
    item,
    isModalDetails,
    isModalDetailsPass,
    cancelModalDetails,
    cancelModalDetailsPass,
    actualizarData,
    agregarData,
    itemPass,
  } = props;

 
  useEffect(() => {
    const initialFetch = async () => {
      if (item) {
        console.log("item",item)
        var typeUsuario = ""
        var sucursal = ""
        var categoria = ""
        var categoriaMultiple = []
        var sucursalMultiple = []

        if(item.id != null){
          setDisabledPass(true)

          //buscar que tipo de usuario es
          var itemTipoUsuario = tipoUsuario.find((data) => data.key == item.rol)
          if(typeof itemTipoUsuario !== 'undefined'){
            typeUsuario = itemTipoUsuario.key
          }
          handleChange(itemTipoUsuario.key)

          
          if(itemTipoUsuario.key == "GerenteRestaurante" || itemTipoUsuario.key == "Meseros" ){
            //buscamos a que restaurante pertenece si es gerente de restaurante
            var itemTienda = sucursales.find((data) => data.id == item.tienda.id)
            if(typeof itemTienda !== 'undefined'){
              sucursal = itemTienda.nombre
            }
          }else if(itemTipoUsuario.key == "Agentes"){
            var itemCategoria = categorias.find((data) => data.id == item.categoria.id)
            if(typeof itemCategoria !== 'undefined'){
              categoria = itemCategoria.nombre
            }
          }else if(itemTipoUsuario.key == "SupervisorTicket"){
            for( var z = 0; z < item.categoriaST.length ; z++){ 
              var itemCategoria = categorias.find((data) => data.id ==  item.categoriaST[z])
              if(typeof itemCategoria !== 'undefined'){
                categoriaMultiple.push(itemCategoria.id)
              }

            }
          }else if(itemTipoUsuario.key == "GerenteZona"){
            for( var z = 0; z < item.tiendas.length ; z++){
              var itemTiendaF = item.tiendas[z]
              var itemTienda = sucursales.find((data) => data.id == itemTiendaF.id)
              if(typeof itemTienda !== 'undefined'){
                sucursalMultiple.push(itemTienda.id)
              }

            }
          }

        }else{
          setDisabledPass(false)
          setVisibleSucursal(false)
          setVisibleSucursalMultiple(false)
          setVisibleCategoria(false)
          setVisibleCategoriaMultiple(false)
        }
        
     


        const newValues = {
          name: item.nombre,
          apellido: item.apellido,
          correo: item.correo,
          telefono: item.telefono,
          tipoUsuario: typeUsuario,
          sucursal: sucursal,
          categoria:categoria,
          categoriaMultiple: categoriaMultiple,
          sucursalMultiple: sucursalMultiple
        };

        console.log(newValues)
        form.setFieldsValue(newValues);
      }else{
        const newValues = {
          name: "",
          apellido: "",
          correo: "",
          telefono: "",
          tipoUsuario: "",
          sucursal: "",
          categoria:"",
          password: "",
          categoriaMultiple: [],
          sucursalMultiple: []
        };

        form.setFieldsValue(newValues);
      }
    };
    initialFetch();
  }, [item]);


  useEffect(() => {
    const initialFetch = async () => {
      
    };
    initialFetch();
  }, [itemPass]);

  const getUser = () => {
    const localities = [];
    users.map(d => {
      localities.push({
        text: d.nombre + " " +  d.apellido,
        value: d.nombre + " " +  d.apellido,
      });
    });
    return localities;
  };
 
  const cambiarPass = async () => {
    try {
      const values = await formPass.validateFields();
      if(values.newPassword != values.repetirPassword){
        message.error('Contraseñas no coinciden');
      }else{

        var dataSend = {}
        dataSend.action = "newpassword"
        dataSend.correo = itemPass.correo
        dataSend.valor = values.newPassword

        console.log(dataSend)
        handleSendingPass(true)

        const request = await instance();
        let data = await request
        .post('/auth.php', {dataSend})
        .catch((error) => {
          return {
            error,
          };
        });

        console.log("data",data)

        var itemSend = {
          clave : values.newPassword,
          fechaActualizado: moment(new Date(), 'DD/MM/YYYY').toDate()
        }

        const response =  await updateDocument(itemSend,`/Usuario/${itemPass.id}`)
        console.log("response",response)
        if (response.ok === true) {
          message.success('Cambios realizados con éxito');
          cancelModalDetailsPass()
        } else {
          message.error('¡Hubo un problema! Inténtalo de nuevo');
        }

        handleSendingPass(false)

      }

    } catch (e) {
    }
  }

  const save = async () => {
   
    try {

      const values = await form.validateFields();
      console.log("values",values)
      if(values.tipoUsuario == "GerenteZona" && values.sucursalMultiple.length == 0){
        message.error('Ingrese sucursales');
      }else if(values.tipoUsuario == "GerenteRestaurante" && values.sucursal == ""){
        message.error('Seleccione sucursal');
      }else if(values.tipoUsuario == "SupervisorTicket" && values.categoriaMultiple.length == 0){
        message.error('Ingrese areas');
      }else if(values.tipoUsuario == "Agentes" && values.categoria == ""){
        message.error('Seleccione área');
      }else if(values.tipoUsuario == "Meseros" && values.sucursal == ""){
        message.error('Seleccione sucursal');
      }else{

          var typeUsuario = ""
          //buscar que tipo de usuario es
          var itemTipoUsuario = tipoUsuario.find((data) => data.key == values.tipoUsuario)
          if(typeof itemTipoUsuario !== 'undefined'){
            typeUsuario = itemTipoUsuario.key
          }
          console.log("values ===>",values)

          var itemSend = {}
          itemSend.activo = true
          itemSend.apellido = values.apellido
          itemSend.nombre = values.name
          itemSend.correo = values.correo
          itemSend.horario = "-"
          itemSend.observaciones = ""
          itemSend.telefono = values.telefono
          itemSend.fechaActualizado = moment(new Date(), 'DD/MM/YYYY').toDate();

          if(typeUsuario != ""){
            itemSend.rol = typeUsuario
          }

          console.log("categorias",categorias,typeUsuario)


          if(typeUsuario == "GerenteZona"){
            var tiendas = []
            
            for( var z = 0; z < values.sucursalMultiple.length ; z++){
              var itemTienda = sucursales.find((data) => data.id == values.sucursalMultiple[z])
              if(typeof itemTienda !== 'undefined'){
                const tiendaRef = await createReferencia('/Tiendas/'+itemTienda.id)
                tiendas.push(tiendaRef)
              }else{
                var itemTienda = sucursales.find((data) => data.nombre == values.sucursalMultiple[z])
                if(typeof itemTienda !== 'undefined'){
                  const tiendaRef = await createReferencia('/Tiendas/'+itemTienda.id)
                  tiendas.push(tiendaRef)
                }
              }
            }

            itemSend.tiendas = tiendas
          }else if(typeUsuario == "GerenteRestaurante" || typeUsuario == "Meseros"){
            var itemTienda = sucursales.find((data) => data.id == values.sucursal)
            if(typeof itemTienda !== 'undefined'){
              const tiendaRef = await createReferencia('/Tiendas/'+itemTienda.id)
              itemSend.tienda = tiendaRef
            }else{
              var itemTienda = sucursales.find((data) => data.nombre == values.sucursal)
              if(typeof itemTienda !== 'undefined'){
                const tiendaRef = await createReferencia('/Tiendas/'+itemTienda.id)
                itemSend.tienda = tiendaRef
              }
            }
          }else if(typeUsuario == "SupervisorTicket"){
            var categoriaST = []
            for( var z = 0; z < values.categoriaMultiple.length ; z++){
              var itemCategoria = categorias.find((data) => data.id == values.categoriaMultiple[z])
              if(typeof itemCategoria !== 'undefined'){
                categoriaST.push(itemCategoria.id)
              }else{
                var itemCategoria = categorias.find((data) => data.nombre == values.categoriaMultiple[z])
                if(typeof itemCategoria !== 'undefined'){
                  categoriaST.push(itemCategoria.id)
                }
              }
            }

            itemSend.categoriaST = categoriaST
          }else if(typeUsuario == "Agentes"){

            var itemCategoria = categorias.find((data) => data.id == values.categoria)
            if(typeof itemCategoria !== 'undefined'){
              const categoriaRef = await createReferencia('/AreaSoporte/'+itemCategoria.id)
              itemSend.categoria = categoriaRef
            }else{
              var itemCategoria = categorias.find((data) => data.nombre == values.categoria)
              if(typeof itemCategoria !== 'undefined'){
                const categoriaRef = await createReferencia('/AreaSoporte/'+itemCategoria.id)
                itemSend.categoria = categoriaRef
              }
            }

          }



          console.log("==========",itemSend)


        if(item.nuevo == undefined){
          itemSend.fechaCreado = moment(new Date(), 'DD/MM/YYYY').toDate();

          const response =  await updateDocument(itemSend,`/Usuario/${item.id}`)
          console.log("response",response)
          if (response.ok === true) {
            message.success('Cambios realizados con éxito');
            itemSend.id = item.id
            itemSend.activo = true
            
            handleSending(false)
            actualizarData(itemSend)
            cancelModalDetails()
          } else {
            message.error('¡Hubo un problema! Inténtalo de nuevo');
          }

        }else{
          itemSend.clave = values.password
          itemSend.fechaCreado = moment(new Date(), 'DD/MM/YYYY').toDate();
        
          const response =  await createDocument(`Usuario`,itemSend)
          console.log("response",response)
          var idUsuario = response.info.id

         
          itemSend.id = idUsuario
          console.log("itemSend",itemSend)
          
          message.success('Usuario creada');
          handleSending(false)
          agregarData(itemSend)
          cancelModalDetails()

        }
      }

      
      
    } catch (e) {
    }

  };

  const DynamicPhone = (props: any) => {
    const cellphoneMask = '0000-0000';
    const phoneMask = '0000-0000';
  
    // always memoize dynamic masks
    const mask = React.useMemo(
      () => [
        {
          mask: cellphoneMask,
          lazy: false,
        },
        {
          mask: phoneMask,
          lazy: false,
        },
      ],
      []
    );
  
    return (
      <MaskedInput
        {...props}
        mask={mask}
        className={styles.inputPhone}
        maskOptions={{
          dispatch: function (appended, dynamicMasked) {
            const isCellPhone = dynamicMasked.unmaskedValue[2] === '9';
            return dynamicMasked.compiledMasks[isCellPhone ? 0 : 1];
          },
        }}
      />
    );
  };

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      align: 'left',
      ...getColumnSearchProps('nombre'),
    },
    {
      title: 'Apellido',
      dataIndex: 'apellido',
      align: 'left',
      ...getColumnSearchProps('apellido'),
    },
    {
      title: 'Correo',
      dataIndex: 'correo',
      ...getColumnSearchProps('correo'),
    },{
      title: 'Telefono',
      dataIndex: 'telefono',
      align: 'left',
      ...getColumnSearchProps('telefono'),
    },
    {
      title: 'Rol',
      dataIndex: 'rol',
      align: 'left',
      ...getColumnSearchProps('rol'),
    },
    {
      title: 'Editar',
      dataIndex: 'id',
      width: 170,
      render: (id, record) => (
        <div className={styles.actions}>
          <div className={styles.edit} onClick={() => showDetails(record)}>
            <Edit />
          </div>
          <div className={styles.divider} />
          <div className={styles.delete} onClick={() => confirmDelete(id)}>
            <Trash />
          </div>
          <div className={styles.divider} />
          <div className={styles.delete} onClick={() => showDetailsPass(record)}>
            <Lock />
          </div>
          
        </div>
      )
    },
    
  ];

  const handleChange = (value: string) => {
    
    if(value == "GerenteOperaciones"){
      setVisibleSucursal(false)
      setVisibleSucursalMultiple(false)
      setVisibleCategoria(false)
      setVisibleCategoriaMultiple(false)
    }else if(value == "GerenteZona"){
      setVisibleSucursal(false)
      setVisibleSucursalMultiple(true)
      setVisibleCategoria(false)
      setVisibleCategoriaMultiple(false)
    }else if(value == "GerenteRestaurante"){
      setVisibleSucursal(true)
      setVisibleSucursalMultiple(false)
      setVisibleCategoria(false)
      setVisibleCategoriaMultiple(false)
    }else if(value == "SupervisorTicket"){
      setVisibleSucursal(false)
      setVisibleSucursalMultiple(false)
      setVisibleCategoria(false)
      setVisibleCategoriaMultiple(true)
    }else if(value == "Agentes"){
      setVisibleSucursal(false)
      setVisibleSucursalMultiple(false)
      setVisibleCategoria(true)
      setVisibleCategoriaMultiple(false)
    }else if(value == "Meseros"){
      setVisibleSucursal(true)
      setVisibleSucursalMultiple(false)
      setVisibleCategoria(false)
      setVisibleCategoriaMultiple(false)
    }
  };

  return (
    <div className={styles.view}>
      <Header
        title="Listado de Usuarios"
        actions={
          <Button
            onClick={newUser}
            type="primary"
            size="large"
            className="main-buttonHeader">
            AGREGAR USUARIO
          </Button>
        }
      />
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Table
            columns={columns}
            dataSource={users}
            rowKey="id"
          />
        </div>
      )}

      { 
        item != null ?
        <Modal 
        className='primary-modal'
        title="Usuario"
        width={650}
        style={{ top: 20 }}
        visible={isModalDetails}
        onCancel={() => cancelModalDetails()}
        footer = { <Row>

          <Col span={6} style={{textAlign:"left"}}>
            
              
            
          </Col>

          <Col span={12} style={{textAlign:"right"}}>
            
          </Col>

          <Col span={6} style={{textAlign:"right"}}>
          

            <Button
                loading={sending}
                onClick={() => {
                  save()
                }}
                type="primary" 
                shape="round" 
                htmlType="submit"
                style={{ width: "90%" }}
                >
                Guardar
            </Button>
          </Col>
        </Row> }>

          <Form
            name="branch-editor"
            autoComplete="off"
            form={form}
            colon={false}
            requiredMark={false}
            onFinish={save}
          >

            <Row gutter={20}>
              

              <Col span={12}>
                <Form.Item
                  label={
                    <span className={styles.label}>
                      Nombre
                    </span>
                  }
                  name="name"
                  labelCol={{ span: 24 }}
                  className={styles.itemColumn}
                  rules={[{required: true, message: 'Ingresar Nombre'}]}
                >
                  <Input size="large" className={styles.input} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={
                    <span className={styles.label}>
                      Apellido
                    </span>
                  }
                  name="apellido"
                  labelCol={{ span: 24 }}
                  className={styles.itemColumn}
                  rules={[{required: true, message: 'Ingresar Apellido'}]}
                >
                  <Input size="large" className={styles.input} />
                </Form.Item>
              </Col>

             

              <Col span={12}>
                <Form.Item
                  label={
                    <span className={styles.label}>
                      Correo
                    </span>
                  }
                  name="correo"
                  labelCol={{ span: 24 }}
                  className={styles.itemColumn}                  
                  rules={[{required: true, message: 'Ingresar Correo',type: 'email'}]}
                >
                  <Input size="large" className={styles.input} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={
                    <span className={styles.label}>
                      Password
                    </span>
                  }
                  name="password"
                  labelCol={{ span: 24 }}
                  className={styles.itemColumn}
                >
                  <Input.Password disabled={disabledPass} size="large" className={styles.input} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={
                    <span className={styles.label}>
                      Teléfono
                    </span>
                  }
                  name="telefono"
                  labelCol={{ span: 24 }}
                  className={styles.itemColumn}
                  rules={[{required: true, message: 'Ingresar Nombre'}]}
                >
                  <DynamicPhone className={styles.input} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={
                    <span className={styles.label}>
                      Tipo de usuario
                    </span>
                  }
                  name="tipoUsuario"
                  labelCol={{ span: 24 }}
                  className={styles.itemColumn}
                  rules={[{required: true, message: 'Ingresar tipo de usuario'}]}
                >
                  <Select size="large" className={styles.input} onChange={handleChange}>
                    {tipoUsuario.map(branch => (
                        <Option key={branch.key} value={branch.key}>{branch.value}</Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              {
                visibleSucursal ?
                <Col span={12}>
                <Form.Item
                  label={
                    <span className={styles.label}>
                      Sucursal
                    </span>
                  }
                  name="sucursal"
                  labelCol={{ span: 24 }}
                  className={styles.itemColumn}
                >
                  <Select size="large" className={styles.input}>
                      {sucursales.map(branch => (
                        <Option key={branch.id} value={branch.id}>{branch.nombre}</Option>
                      ))}
                    </Select>

                </Form.Item>
              </Col>
              : null
              }

              {

              visibleSucursalMultiple ?
              <Col span={24}>
              <Form.Item
                label={
                  <span className={styles.label}>
                    Sucursal
                  </span>
                }
                name="sucursalMultiple"
                labelCol={{ span: 24 }}
                className={styles.itemColumn}
              >
                <Select size="large" className={styles.input} mode="multiple">
                    {sucursales.map(branch => (
                      <Option key={branch.id} value={branch.id}>{branch.nombre}</Option>
                    ))}
                  </Select>

              </Form.Item>
              </Col>
              : null
              }
              

              {
                visibleCategoria ?
                <Col span={12}>
                <Form.Item
                  label={
                    <span className={styles.label}>
                      Área
                    </span>
                  }
                  name="categoria"
                  labelCol={{ span: 24 }}
                  className={styles.itemColumn}
                >
                  <Select size="large" className={styles.input}>
                      {categorias.map(branch => (
                        <Option key={branch.id} value={branch.id}>{branch.nombre}</Option>
                      ))}
                    </Select>

                </Form.Item>
              </Col>
              : null
              }

              {

              visibleCategoriaMultiple ?
              <Col span={24}>
              <Form.Item
                label={
                  <span className={styles.label}>
                  Áreas
                  </span>
                }
                name="categoriaMultiple"
                labelCol={{ span: 24 }}
                className={styles.itemColumn}
              >
                <Select size="large" className={styles.input} mode="multiple">
                    {categorias.map(branch => (
                      <Option key={branch.id} value={branch.id}>{branch.nombre}</Option>
                    ))}
                  </Select>

              </Form.Item>
              </Col>
              : null
              }
              

            </Row>

           

          </Form>

        
        </Modal>
        : null
      }


      { 
        itemPass != null ?
        <Modal 
        className='primary-modal'
        title="Cambiar contraseña"
        width={650}
        style={{ top: 20 }}
        visible={isModalDetailsPass}
        onCancel={() => cancelModalDetailsPass()}
        footer = { <Row>

          <Col span={6} style={{textAlign:"left"}}>
            
              
            
          </Col>

          <Col span={12} style={{textAlign:"right"}}>
            
          </Col>

          <Col span={6} style={{textAlign:"right"}}>
          

            <Button
                loading={sendingPass}
                onClick={() => {
                  cambiarPass()
                }}
                type="primary" 
                shape="round" 
                htmlType="submit"
                style={{ width: "90%" }}
                >
                Guardar
            </Button>
          </Col>
        </Row> }>

          <Form
            name="branch-editor"
            autoComplete="off"
            form={formPass}
            colon={false}
            requiredMark={false}
            onFinish={cambiarPass}
          >

            <Row gutter={20}>
              

            

              <Col span={12}>
                <Form.Item
                  label={
                    <span className={styles.label}>
                      Nueva Contraseña
                    </span>
                  }
                  name="newPassword"
                  labelCol={{ span: 24 }}
                  className={styles.itemColumn}
                  rules={[
                    {required: true, message: 'Ingresar contraseña'},
                    { min: 5, message: 'Contraseña muy corta' }
                  ]}
                >
                  <Input.Password disabled={disabledPass} size="large" className={styles.input} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={
                    <span className={styles.label}>
                      Repetir Contraseña
                    </span>
                  }
                  name="repetirPassword"
                  labelCol={{ span: 24 }}
                  className={styles.itemColumn}
                  rules={[
                    {required: true, message: 'Ingresar contraseña'},
                    { min: 5, message: 'Contraseña muy corta' }
                  ]}
                >
                  <Input.Password disabled={disabledPass} size="large" className={styles.input} />
                </Form.Item>
              </Col>
            </Row>

           

          </Form>

        
        </Modal>
        : null
      }

    </div>
  );
};

export default UsuariosUI;
