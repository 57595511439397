import axios from "axios";

const getInstance = async (country, file) => {
  const timeout = 60000;
  const baseURL = "https://checklist.papajohns.com.sv"
  const request = axios.create({
    baseURL,
    timeout,
    headers: {
      "Content-Type": file ? "multipart/form-data" : "application/json",
    },
  });
  return request;
};

export default getInstance;
