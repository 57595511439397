import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {logout, selectUser} from '../../redux/slices/user';
import {setLoaded} from '../../redux/slices/monitor';
import DashboardUI from './components/DashboardUI';
import MonitorUI from '../monitor/components/MonitorUI';
import Monitor from '../monitor/Main';

const Main = () => {
  const user =  useSelector(selectUser);
  const dispatch = useDispatch();
  const idGO = user.rol === "GerenteOperaciones";
  const isST = user.rol === "SupervisorTicket";
  const isAccountant = user.role === "6";
  const navigate = useNavigate();

  useEffect(() => {


    /*if (isAccountant) {
      navigate('/historial');
    } else if (!isAdmin) {
      navigate('/');
    }*/

    //navigate('/');
  }, []);

  const closeSession = () => {
    dispatch(setLoaded(false));
    dispatch(logout());
  };

  return (
    <DashboardUI closeSession={closeSession} user={user} rol={user.rol} />
  );
  
}

export default Main;
