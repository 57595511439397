import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {message, Modal} from 'antd';
import { deleteBitacora, getAllUser, getListBitacora,getAllRestaurante } from '../../firebase/queryProvider';
import BitacoraUI from './components/BitacoraUI';


const Bitacora = () => {
  const [loading, handleLoading] = useState(true);
  const [editorVisible, handleEditorVisible] = useState(false);
  const [isModalDetails, handleIsModalDetails] = useState(false);
  const [bitacoras, setBitacoras] = useState([]);
  const [item, setItem] = useState(null);
  const [restaurantes, setRestaurantes] = useState([]);
  const [users, setUsers] = useState([]);
  const [loadingBuscar, handleLoadingBuscar] = useState(false);


  const dispatch = useDispatch();

  useEffect(() => {
   

    const initialFetch = async () => {

      const result = await getListBitacora("","");
      console.log("result",result)
      if(result.ok){
        setBitacoras(result.listadoBitacora)
      }else{
        message.error('¡Hubo un problema!');
      }

      const resultUsers = await getAllUser();
      if(resultUsers.ok){
        setUsers(resultUsers.listadoUser)
      }

      const resultRestaurantes = await getAllRestaurante();
      if(resultRestaurantes.ok){
        setRestaurantes(resultRestaurantes.listadoUser)
      }


      handleLoading(false);
    };

    initialFetch();
  }, [dispatch]);

  const buscarFechas = async(fechaInicio,fechaFin) => {
    console.log("fechaInicio",fechaInicio)
    console.log("fechaFin",fechaFin)
    handleLoadingBuscar(true);
    const result = await getListBitacora(fechaInicio,fechaFin);
    console.log("result",result)
    if(result.ok){
      setBitacoras(result.listadoBitacora)
    }else{
      message.error('¡Hubo un problema!');
    }
    handleLoadingBuscar(false);

  }

  const confirmDelete = (idZone) => {
    Modal.confirm({
      title: '¿Estás segúro de eliminar esta cobertura?',
      content: 'Esta información no se podrá recuperar.',
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        //await removeZone(idZone);
      },
      onCancel() {},
    });
  };

  const removeBitacora = async (idBitacora) => {
    const response = await dispatch(deleteBitacora(idBitacora));
    if (response.status !== 'success') {
      message.error('¡Hubo un problema!');
    } else {
      message.success('Registro eliminado correctamente');
      handleIsModalDetails(false)
      var listBitacoras = [... bitacoras]

      const index = listBitacoras.findIndex(el => el.id === idBitacora);
      if (index > -1) {
        listBitacoras.splice(index, 1);
      }

      setBitacoras(listBitacoras)
    }
  };


  const handleCancelModalDetails = async () => {
    handleIsModalDetails(false)
  };

  const handleDetail = async (item) => {
    setItem(item)
    handleIsModalDetails(true)
  };

  return (
    <BitacoraUI
      loading={loading}
      bitacoras={bitacoras}
      restaurantes={restaurantes}
      users={users}
      editorVisible={editorVisible}
      isModalDetails={isModalDetails}
      buscarFechas={buscarFechas}
      cancelModalDetails={handleCancelModalDetails}
      item={item}
      showDetails={handleDetail}
      confirmDelete={confirmDelete}
      removeBitacora={removeBitacora}
      loadingBuscar={loadingBuscar}
    />
  );
}

export default Bitacora;
