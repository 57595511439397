import {createSlice} from '@reduxjs/toolkit';
import {login, validateLogin} from '../../api/User';

const initialState = {
  info: localStorage.getItem('checklist_dash')
  ? JSON.parse(localStorage.getItem('checklist_dash'))
  : null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setInfo: (state, action) => {
      state.info = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {setInfo} = userSlice.actions;


export const validation = (response) => async dispatch => {
  try {
    await localStorage.setItem('checklist_dash', JSON.stringify(response));
    dispatch(setInfo(response));
    return {
      status: 'success',
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const logout = () => dispatch => {
  localStorage.removeItem('checklist_dash');
  dispatch(setInfo(null));
};

export const selectUser = state => state.user.info;

export default userSlice.reducer;
