// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
//import { getFirestore } from 'firebase/firestore/lite';
import { getFirestore } from 'firebase/firestore'
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

const firebaseConfig = {
    apiKey: "AIzaSyD432-qqMHVdYf4ZojfJqpWJs7R9lObhZs",
    authDomain: "papajohns-sv.firebaseapp.com",
    databaseURL: "https://papajohns-sv.firebaseio.com",
    projectId: "papajohns-sv",
    storageBucket: "papajohns-sv.appspot.com",
    messagingSenderId: "667877711150",
    appId: "1:667877711150:web:9df8e79fbb93c0a1466ebf",
  };


// Initialize Firebase
export const FirebaseApp  = initializeApp(firebaseConfig);
export const FirebaseAuth = getAuth( FirebaseApp );
export const FirebaseDB   = getFirestore( FirebaseApp );
export const FirebaseStorage = getStorage(FirebaseApp);
