import {useRef,useState} from 'react';
import {Button, Table, Spin, Modal, Tabs, DatePicker,Select} from 'antd';
import Header from '../../../components/HeaderList';
import {ReactComponent as Edit} from '../../../assets/icons/edit.svg';
import {ReactComponent as Trash} from '../../../assets/icons/trash.svg';
import {getColumnSearchProps} from '../../../utils/tables';
import styles from '../styles/TareasReporteUI.module.css';
import { Chart } from "react-google-charts";
import locale from 'antd/es/date-picker/locale/es_ES';
import XLSX from 'xlsx';
import { renderToString } from 'react-dom/server';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const { Option } = Select;


const TareasReporteUI = props => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [periodo, setPeriodo] = useState("Últimos 7 días");
  const [activeTab, setActiveTab] = useState('1');

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const searchInput = useRef(null);
  const dateFormat = 'DD/MM/YYYY';
  const customFormat = (value) => `${value.format(dateFormat)}`;

  const onChangeFecha = (date, dateString) => {
    console.log(date, dateString);
    setStartDate(dateString[0])
    setEndDate(dateString[1])
  };

  const {
    loading,
    dataRestaurante,
    dataGerenteZona,
    users,
    datagrafica,
    buscarTickets
  } = props;


  
  const exportToExcel = () => {

    let data = null;
    let nameFile = "";
    let encabezados = [];

    switch (activeTab) {
      case '1':
        data = dataRestaurante.map(item => {
          const { tiempoAsignarse, tiempoIniciar, tiempoFinalizado, ...rest } = item;
          return {
            ...rest,
            tiempoAsignarse: secondsToHms(tiempoAsignarse),
            tiempoIniciar: secondsToHms(tiempoIniciar),
            tiempoFinalizado: secondsToHms(tiempoFinalizado)
          };
        });
        nameFile = "Restaurantes";
        encabezados = columnsRestaurante;
        break;
      case '2':
        data = dataGerenteZona.map(item => {
          const { tiempoAsignarse, tiempoFinalizado, tiempoIniciar, ...rest } = item;
          return rest;
        });
        nameFile = "GerentesArea";
        encabezados = columnsGerenteArea;
        break;
      default:
        data = dataRestaurante;
        nameFile = "Restaurantes";
        encabezados = columnsRestaurante;
        break;
    }

    const headers = encabezados.map(column => {
      if (typeof column.title === 'object' && 'props' in column.title) {
        const element = column.title;
        const htmlString = renderToString(element);

        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        const bodyAsString = doc.body.innerHTML + '';

        const textWithoutLineBreak = bodyAsString.replace(/<br\s*\/?>/g, ' ');
        const textWithoutDiv = textWithoutLineBreak.replace(/<div\s+data-reactroot=".*?">/g, '');
        const textWithoutClosingDiv = textWithoutDiv.replace(/<\/div>/g, '');

        return textWithoutClosingDiv;
      }
      return column.title;
    });

    const rows = [headers];
    data.forEach(item => {
      const rowData = Object.values(item);
      rows.push(rowData);
    });

    const workbook = XLSX.utils.book_new();
    const sheet = XLSX.utils.aoa_to_sheet(rows);
    XLSX.utils.book_append_sheet(workbook, sheet, nameFile);

    XLSX.writeFile(workbook, `${nameFile}Tareas.xlsx`);
  };

  const toHoursAndMinutes = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
  
    return `${(hours)}:${(minutes)}`;
  }

  const padToTwoDigits = (num) => {
    return num.toString().padStart(2, '0');
  }

  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var mShow = m < 10 ? "0"+m : m

    var hDisplay = h > 0 ? h + (h == 1 ? " hora, " : " horas, ") : "";
    var mDisplay = m > 0 ? mShow + (m == 1 ? " minuto, " : " minutos, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " segundo" : " segundos") : "";

    if(h>0){
      return h + ":" + mShow +  " horas"; 
    }else if(m>0){
      return m > 0 ? mShow + (m == 1 ? " minuto " : " minutos ") : "";; 
    }else{
      return hDisplay + mDisplay + sDisplay; 
    }
    
  }


  const getUser = () => {
    const localities = [];
    dataRestaurante.map(d => {
      localities.push({
        text: d.nombre,
        value: d.nombre,
      });
    });
    return localities;
  };

  const geUserName = locality => {
    let name = "-";
    const index = dataRestaurante.findIndex(el => el.nombre === locality);
    if (index > -1) {
      name = dataRestaurante[index].nombre;
    }
    return name;
  };

  const getUserGR = () => {
    const localities = [];
    dataGerenteZona.map(d => {
      localities.push({
        text: d.nombre,
        value: d.nombre,
      });
    });
    return localities;
  };

  const geUserNameGR = locality => {
    let name = "-";
    const index = dataGerenteZona.findIndex(el => el.nombre === locality);
    if (index > -1) {
      name = dataGerenteZona[index].nombre;
    }
    return name;
  };

  const columnsRestaurante = [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      align: 'center',
      filterSearch: true,
      filters: getUser(),
      onFilter: (val, record) => record.nombre === val,
      render: val => geUserName(val, searchInput),
    },
    {
      title: 'Abiertos',
      dataIndex: 'abiertos',
      sorter: (a, b) => a.abiertos - b.abiertos,
    },
    {
      title: 'Atrasados',
      dataIndex: 'atrasados',
      sorter: (a, b) => a.atrasados - b.atrasados,
    },
    {
      title: 'Finalizados',
      dataIndex: 'finalizados',
      sorter: (a, b) => a.finalizados - b.finalizados,
    },
    {
      title: 'Tareas',
      dataIndex: 'totalTicket',
      sorter: (a, b) => a.totalTicket - b.totalTicket,
    },
    {
      title:  (
        <div>
          Cumplimiento
        </div>
      ),
      dataIndex: 'cumplimiento',
      align: 'left',
      sorter: (a, b) => a.cumplimiento - b.cumplimiento,
      render: (id, record) => (
        <div>
         {record.cumplimiento}%
        </div>
      ),
    },
  ];


  const columnsGerenteArea = [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      align: 'center',
      filterSearch: true,
      filters: getUserGR(),
      onFilter: (val, record) => record.nombre === val,
      render: val => geUserNameGR(val, searchInput),
    },
    {
      title: 'Abiertos',
      dataIndex: 'abiertos',
      sorter: (a, b) => a.abiertos - b.abiertos,
    },
    {
      title: 'Atrasados',
      dataIndex: 'atrasados',
      sorter: (a, b) => a.atrasados - b.atrasados,
    },
    {
      title: 'Finalizados',
      dataIndex: 'finalizados',
      sorter: (a, b) => a.finalizados - b.finalizados,
    },
    {
      title: 'Tareas',
      dataIndex: 'totalTicket',
      sorter: (a, b) => a.totalTicket - b.totalTicket,
    },
    {
      title:  (
        <div>
          Cumplimiento
        </div>
      ),
      dataIndex: 'cumplimiento',
      align: 'left',
      sorter: (a, b) => a.cumplimiento - b.cumplimiento,
      render: (id, record) => (
        <div>
         {record.cumplimiento}%
        </div>
      ),
    },
  ];

  const options = {
    title: "",
    curveType: "function",
    legend: { position: "right" },
  };

  const optionss = {
    hAxis: {
      title: "Time",
    },
    vAxis: {
      title: "Popularity",
    },
    series: {
      1: { curveType: "function" },
    },
  };
  
  

  return (
    <div className={styles.view}>
      <Header
        title="Actividad de Tareas"
      />
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
        <div className={styles.contMain}>
          <div className={styles.contBuscador}>
          <span className={styles.spanPeriodo}>Período</span>
          <RangePicker format={customFormat} locale={locale} style={{
              width: "250px",
            }}
            
            onChange={onChangeFecha} />
            <span className={styles.spanPeriodo}>Período</span>
            <Select
                id={"idPeriodo"}
                defaultValue={periodo}
                style={{
                  width: 200,
                }}
                onChange={(value)=>{
                  setPeriodo(value)
                  /*let newArr = [...listPreguntas]; // copying the old datas array
                  newArr[key].type = value
                  if(value == "option"){
                    newArr[key].opciones = []
                    newArr[key].opciones.push({posicion:0,valor:""})
                  }else{
                    newArr[key].opciones = []
                  }

                  setListPreguntas(newArr);*/
                }}
              >
              <Option value="Hoy">Hoy</Option>
              <Option value="Ayer">Ayer</Option>
              <Option value="Últimos 7 días">Últimos 7 días</Option>
              <Option value="Últimos 30 días">Últimos 30 días</Option>
            </Select>

            <Button
                disabled={loading}
                onClick={() => {
                  var tipo = periodo
                  if(startDate != ""){
                    tipo = "fecha"
                  }
                  buscarTickets(tipo,startDate,endDate)
                }}
                size="large"
                type="primary"
                className={styles.driverAssign}
              >
                BUSCAR
              </Button>

              <Button
              style={{marginLeft:"20px"}}
              size="large"
              onClick={() => {
                exportToExcel()
              }}
              disabled={loading}
              type={'primary'}
              className={styles.driverAssign}>
                Exportar Excel
            </Button>
          </div>
          <Chart
              chartType="LineChart"
              width="100%"
              height="400px"
              data={datagrafica}
              options={options}
            />

            <div className={styles.espacio}></div>
            <div>
              <Tabs defaultActiveKey="1" activeKey={activeTab} onChange={handleTabChange}>
                <TabPane tab="RESTAURANTES" key="1">
                  <Table
                      columns={columnsRestaurante}
                      rowKey="id"
                      dataSource={dataRestaurante}
                      pagination={{ defaultPageSize: 7 }}
                    />
                </TabPane>
                <TabPane tab="GERENTES DE AREA" key="2">
                  <Table
                      columns={columnsGerenteArea}
                      rowKey="id"
                      dataSource={dataGerenteZona}
                      pagination={{ defaultPageSize: 7 }}
                    />
                </TabPane>
              </Tabs>
            </div>
          
            <div className={styles.espacio}></div>
        </div>
       


        </div>
      )}
      
    </div>
  );
};

export default TareasReporteUI;
