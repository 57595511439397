import {useRef,useState,useEffect,useCallback} from 'react';
import {Button, Table, Spin, Modal, Col, Row,Form,Input,Select,message,Upload,DatePicker} from 'antd';
import Header from '../../../components/HeaderList';
import {getColumnSearchProps} from '../../../utils/tables';
import Editor from './Editor';
import styles from '../styles/MonitorUI.module.css';
import ImageViewer from 'react-simple-image-viewer';
import {useSelector, useDispatch} from 'react-redux';
import { getRutas, getRutasActivas,createDocument,updateDocument} from '../../../firebase/queryProvider';
import {selectUser} from '../../../redux/slices/user';
import moment from 'moment';
import instance from "../../../api/Request";
import locale from 'antd/es/date-picker/locale/es_ES';
import dayjs from 'dayjs';
import {ReactComponent as Edit} from '../../../assets/icons/edit.svg';
import {ReactComponent as Trash} from '../../../assets/icons/trash.svg';
import { NavLink } from 'react-router-dom';

import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"

dayjs.extend(weekday)
dayjs.extend(localeData)


const {Option} = Select;
const { TextArea } = Input;

const dateFormat = 'DD/MM/YYYY';
const customFormat = (value) => `${value.format(dateFormat)}`;

const RutasTiempoRealUI = props => {
  const [loading, handleLoading] = useState(true);
  const [sending, handleSending] = useState(false);
  const [form] = Form.useForm();
  const [tipificacionSelect, setTipificacionSelect] = useState([]);
  const [isModalAsignar, setIsModalAsignar] = useState(false);
  const [loadingAsignar, setLoadingAsignar] = useState(false);
  const [rutas, setRutas] = useState([]);
  const [ubicaciones, setUbicaciones] = useState([]);
  const [item, setItem] = useState(null);

  
  const dispatch = useDispatch();
  
  const user = useSelector(selectUser);

  
  const {
    newTicket,
    isModalNew,
    cancelModalNew,
    tipificacion,
  } = props;

  useEffect(() => {
    const initialFetch = async () => {
      const result = await getRutasActivas();
      
      if(result.ok){
        setRutas(result.listadoRutas)
      }else{
        message.error('¡Hubo un problema!');
      }

      handleLoading(false);
    };
    initialFetch();
  }, [dispatch]);


  const agregarData = async (data) => {
    var allRutas = [... rutas]

    var itemRutasOrigen = ubicaciones.find((item) => item.id == data.origen)
    if(typeof itemRutasOrigen !== 'undefined'){
        data.origenName =  itemRutasOrigen.nombre
    }else{
        data.origenName =  ""
    }

    var itemRutasDestino = ubicaciones.find((item) => item.id == data.destino)
    if(typeof itemRutasDestino !== 'undefined'){
        data.destinoName =  itemRutasDestino.nombre
    }else{
        data.destinoName =  ""
    }

    allRutas.unshift(data)
    setRutas(allRutas)
  }

  const openEditor = (item) => {
    setItem(item);

    const newValues = {
      origen: item.origen,
      destino: item.destino,
      recorrido: item.recorrido
    };
    form.setFieldsValue(newValues);

    setIsModalAsignar(true);
  };

  const confirmDelete = (idItem) => {
    Modal.confirm({
      title: '¿Estás segúro de eliminar esta ruta?',
      content: 'Esta información no se podrá recuperar.',
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        //await removeRuta(idItem);
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: 'Fecha',
      dataIndex: 'fechaCreado',
      align: 'left'
    },
    {
      title: 'Origen',
      dataIndex: 'origenNombre',
      align: 'left',
      ...getColumnSearchProps('origenNombre'),
    },
    {
      title: 'Destino',
      dataIndex: 'destinoNombre',
      ...getColumnSearchProps('destinoNombre'),
    },
    {
      title: 'Usuario',
      dataIndex: 'de',
      render: (id, record) => (
        <div>
          {record.de}
        </div>
      ),
      ...getColumnSearchProps('de'),
    },
    {
      title: 'Ver',
      align: 'center',
      dataIndex: 'id',
      render: (id, record) => (
        <div className={styles.actions}>
          <NavLink to={`/monitor/mapa/${id}`}>
            <div>MAPA</div>
          </NavLink>
        </div>
      ),
    },
  ];

  const save = () => {
    
  }

  return (
    <div className={styles.view}>
      <Header
        title="RUTAS EN TIEMPO REAL"
        back="/monitor"
      />
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Table
            columns={columns}
            dataSource={rutas}
            rowKey="id"
          />
        </div>
      )}



        <Modal 
        className='primary-modal'
        title="Ruta"
        visible={isModalAsignar}
        style={{ top: 20 }}
        onCancel={() => setIsModalAsignar(false)}
        footer = { <Row>

          <Col span={6} style={{textAlign:"left"}}>
            
              
            
          </Col>

          <Col span={12} style={{textAlign:"right"}}></Col>

          <Col span={6} style={{textAlign:"right"}}>
            <Button 
                // disabled={requests.isSaving}
                loading={loading}
                className={styles.btCerrar}
                type="primary" 
                shape="round" 
                style={{ width: "90%" }} 
                onClick={() => {
                  //save()
                  //asignarAgenteTicket(item,itemAgente)
                }}
                >
                Guardar
            </Button>
          </Col>
        </Row> }>

        <div>
        
          <Form
            name="branch-editor"
            autoComplete="off"
            form={form}
            colon={false}
            requiredMark={false}
            onFinish={save}
          >

          
            
            <Row>
              <Col span={24}>
                <Form.Item
                  label={
                    <span className={styles.label}>
                      Origen
                    </span>
                  }
                  name="origen"
                  className={styles.itemColumn}
                  rules={[{required: true, message: 'Seleccione origen'}]}
                >
                <Select size="large" className={styles.input}>
                  {ubicaciones.map(branch => (
                    <Option key={branch.id} value={branch.id}>{branch.nombre}</Option>
                    ))}
                </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label={
                    <span className={styles.label}>
                      Destino   
                    </span>
                  }
                  name="destino"
                  className={styles.itemColumn}
                  rules={[{required: true, message: 'Seleccione destino'}]}
                >
                <Select size="large" className={styles.input}>
                  {ubicaciones.map(branch => (
                    <Option key={branch.id} value={branch.id}>{branch.nombre}</Option>
                    ))}
                </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label={
                    <span className={styles.label}>
                      Recorrido (km)
                    </span>
                  }
                  name="recorrido"
                  className={styles.itemColumn}
                  rules={[{required: true, message: 'Ingrese recorrido'}]}
                >
                <Input size="large" className={styles.input} />
                </Form.Item>
              </Col>
            </Row>

          
            
            
          </Form>
        </div>

        
        </Modal>

    </div>
  );
};

export default RutasTiempoRealUI;
