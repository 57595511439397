import { signInWithEmailAndPassword, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, updateProfile } from 'firebase/auth';
import { FirebaseAuth,FirebaseDB } from './config';
import { collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";


const googleProvider = new GoogleAuthProvider();

export const singInWithGoogle = async() => {

    try {
        
        const result = await signInWithPopup(FirebaseAuth, googleProvider );
        // const credentials = GoogleAuthProvider.credentialFromResult( result );
        const { displayName, email, photoURL, uid } = result.user;
        
        return {
            ok: true,
            // User info
            displayName, email, photoURL, uid
        }
        

    } catch (error) {
        
        const errorCode = error.code;
        const errorMessage = error.message;
    
        return {
            ok: false,
            errorMessage,
        }
    }

}


export const registerUserWithEmailPassword = async({ email, password, displayName }) => {

    try {
        const resp = await createUserWithEmailAndPassword( FirebaseAuth, email, password );
        const { uid, photoURL } = resp.user;

        await updateProfile( FirebaseAuth.currentUser, { displayName });

        return {
            ok: true,
            uid, photoURL, email, displayName
        }

    } catch (error) {
        console.log(error);
        return { ok: false, errorMessage: error.message }
    }

}


export const loginWithEmailPassword = async({ user, password }) => {

    try {

        const collectionRef = collection( FirebaseDB, `Usuario`);
        const queryMessages = query(collectionRef, 
            where("correo", "==", user),
            where("clave", "==", password),
            //where("clave", "==", password),
            //where("rol", "==", "GerenteOperaciones")
            );
        const querySnapshot = await getDocs(queryMessages);
       
        if(querySnapshot.size == 0){
            return {
                ok: false,
                errorMessage: "Credenciales no validas"
            }
        }

        var rol = ""
        var apellido = ""
        var nombre = ""
        var idUser = ""
        
        querySnapshot.forEach( async (documment) => {
            let data = documment.data();
            idUser = documment.id
            rol = data.rol
            apellido = data.apellido
            nombre = data.nombre
        });

        if(rol == "GerenteOperaciones" || rol == "SupervisorTicket"){
            await signInWithEmailAndPassword( FirebaseAuth, user, password );
        
            return {
                ok: true,
                rol, apellido, nombre, idUser
            }
        }else if(rol == "GerenteZona"){
            //codigo temporal, se debe crear modulo con permisos
            if(idUser == "WXDUCLIT88xsnmcUmOl7"){
                await signInWithEmailAndPassword( FirebaseAuth, user, password );
                return {
                    ok: true,
                    rol, apellido, nombre, idUser
                }
            }else{
                return {
                    ok: false,
                    errorMessage: "Credenciales no validas"
                }
            }
        }else if(rol == "GerenteRestaurante"){
            //codigo temporal, se debe crear modulo con permisos
            if(idUser == "TpNVWvSveQoPgJKVaTi5"){
                await signInWithEmailAndPassword( FirebaseAuth, user, password );
                return {
                    ok: true,
                    rol, apellido, nombre, idUser
                }
            }else{
                return {
                    ok: false,
                    errorMessage: "Credenciales no validas"
                }
            }
        }else{
            return {
                ok: false,
                errorMessage: "Credenciales no validas"
            }
        }

        
        

        //const data = await getQueryDocByCollectionAnId("profile", "user_id" , "==", id);

        /*const resp = await signInWithEmailAndPassword( FirebaseAuth, email, password );
        const { uid, photoURL, displayName } = resp.user;

        return {
            ok: true,
            uid, photoURL, displayName
        }*/

    } catch (error) {
        return { ok: false, errorMessage: error.message }
    }
}

export const logoutFirebase = async() => {
    return await FirebaseAuth.signOut();
}