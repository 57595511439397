import {Form, Input, Button} from 'antd';
import {ReactComponent as Logo} from '../../../assets/logo/logo_header.svg';
import {ReactComponent as LogoFooter} from '../../../assets/logo/logo_pjs.svg';
import {ReactComponent as Wave1} from '../../../assets/backgrounds/wave1.svg';
import {ReactComponent as Wave2} from '../../../assets/backgrounds/wave2.svg';
import {ReactComponent as Person} from '../../../assets/icons/person.svg';
import {ReactComponent as Lock} from '../../../assets/icons/lock.svg';
import styles from '../styles/LoginUI.module.css';
import { NavLink } from 'react-router-dom';

const LoginUI = ({login, sending, form, code}) => (
  <div className={styles.loginView}>
    <div className={styles.form}>
      <div className={styles.encabezado}>
        <Logo className={styles.logo} />
      </div>
     
      <span className={styles.subtitle}></span>
      <Form
        className={styles.info}
        onFinish={login}
        onFinishFailed={login}
        autoComplete="off"
        form={form}
      >
        {!code ? (
          <>
            <Form.Item
              name="user"
              rules={[{ required: true, message: 'Ingresa tu usuario' }]}
            >
              <Input
                prefix={<Person className={styles.icon} />}
                bordered={false}
                size="large"
                className={styles.input}
                placeholder="Usuario"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Ingresa tu contraseña' }]}
            >
              <Input.Password
                prefix={<Lock className={styles.icon} />}
                bordered={false}
                size="large"
                className={styles.input}
                visibilityToggle={false} 
                placeholder="Contraseña"
              />
            </Form.Item>
          </>
        ) : (
          <Form.Item
            name="codeSent"
            rules={[{required: true, message: 'Ingresa código enviado a tu correo'}]}
          >
            <Input
              bordered={false}
              size="large"
              className={styles.input}
              placeholder="Código enviado a tu correo"
            />
          </Form.Item>
        )}
        <Form.Item>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            loading={sending}
            className={styles.submit}>
            {code ? 'VERIFICAR' : 'INGRESAR'}
          </Button>
        </Form.Item>
      </Form>
      <Wave1 className={styles.wave1} />
      <Wave2 className={styles.wave2} />
      <div className={styles.powered}>
            <LogoFooter />
            <NavLink to={`/privacy_policy`}>
            Condiciones de uso
          </NavLink>
      </div>
     
    </div>
        
  </div>
);

export default LoginUI;
