import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {message, Modal} from 'antd';
import {
  getAllZones,
  selectZones,
  getAllLevels,
  removeMonitorZone,
  activeZone,
} from '../../redux/slices/zones';
import {getAllDepartments, getAllBranches, selectBranches} from '../../redux/slices/branches';
import TicketsUI from './components/TicketsUI';
import { getAllRestaurante, getAllUser, getAreaSoporte, getListTickets,getTipificacion,updateTicketAsignar } from '../../firebase/queryProvider';
import {logout, selectUser} from '../../redux/slices/user';
import moment from 'moment';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);



const Tickets = () => {
  const [loading, handleLoading] = useState(true);
  const [editorVisible, handleEditorVisible] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [restaurantes, setRestaurantes] = useState([]);
  const [users, setUsers] = useState([]);
  const [agentes, setAgentes] = useState([]);
  const [tipificacion, setTipificacion] = useState([]);
  const [item, setItem] = useState(null);
  const [images, setImages] = useState([]);
  const [isModalDetails, handleIsModalDetails] = useState(false);
  const [isModalAsignar, handleIsModalAsignar] = useState(false);
  const [isModalNew, handleIsModalNew] = useState(false);
  const [areas, setAreas] = useState([]);
  const [loadingBuscar, handleLoadingBuscar] = useState(false);
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const dateFormat = 'DD/MM/YYYY';

  const user =  useSelector(selectUser);
  
  const dispatch = useDispatch();

  useEffect(() => {
   

    const initialFetch = async () => {
     
      
      let today = new Date();
      let fechaNueva =  new Date(moment(today).add(-6, 'day'));

      var fechaInicio = new Date(fechaNueva.setHours(0,0,0,0));
      var fechaFinal = new Date(new Date().setHours(23,59,59,59));

      var formattedDate = moment(fechaInicio).format(dateFormat);
      var formattedDateF = moment(fechaFinal).format(dateFormat);

      console.log("========",fechaFinal,today)


      
      setFechaInicio(formattedDate)
      setFechaFin(formattedDateF)

      const result = await getListTickets(user,fechaInicio,fechaFinal);
      console.log("result",result)
      if(result.ok){
        setTickets(result.listadoTicket)
      }else{
        message.error('¡Hubo un problema!');
      }

      const resultUsers = await getAllUser();
      if(resultUsers.ok){
        setUsers(resultUsers.listadoUser)
      }

      const resultAreaSoporte = await getAreaSoporte();
      if(resultAreaSoporte.ok){
        setAreas(resultAreaSoporte.listadoAreaSoporte)
      }

      const resultTiendas = await getAllRestaurante();
      if(resultTiendas.ok){
        setRestaurantes(resultTiendas.listadoUser)
      }

      const resultTipificacion = await getTipificacion();
      if(resultTipificacion.ok){
        setTipificacion(resultTipificacion.listadoTipificacion)
      }

      handleLoading(false);

      /*const [response, res_departments, res_levels] = await Promise.all([
        dispatch(getAllZones()),
        dispatch(getAllDepartments()),
        dispatch(getAllLevels()),
        dispatch(getAllBranches())
      ]);
      if (response.status !== 'success') {
        message.error('¡Hubo un problema!');
      } else {
        handleDepartments(res_departments.departments);
        handleLevels(res_levels.levels);
      }
      handleLoading(false);*/
    };

    initialFetch();
  }, [dispatch]);

  const confirmDelete = (idZone) => {
    Modal.confirm({
      title: '¿Estás segúro de eliminar esta cobertura?',
      content: 'Esta información no se podrá recuperar.',
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        await removeZone(idZone);
      },
      onCancel() {},
    });
  };

  const removeZone = async (idZone) => {
    const response = await dispatch(removeMonitorZone(idZone));
    if (response.status !== 'success') {
      message.error('¡Hubo un problema!');
    } else {
      message.success('Zona eliminada correctamente');
    }
  };

  const handleActive = async (idZone, val) => {
    const response = await dispatch(activeZone(idZone, val));
    if (response.status !== 'success') {
      message.error('¡Hubo un problema!');
    }
  };

  const handleDetail = async (item) => {
    setItem(item)
    setImages([])

    var images = []
    if(item.imageUno != null){
      images.push(item.imageUno)
    }

    if(item.imageDos != null){
      images.push(item.imageDos)
    }

    setImages(images)
    handleIsModalDetails(true)
  };

  const handleCancelModalDetails = async () => {
    setImages([])
    handleIsModalDetails(false)
  };
  
  const handleCancelModalAsignar = async (type,item) => {
    if(type == true){
      var userFilter = users.filter((item) => item.categoria != undefined)
      var agentesFilter = userFilter.filter((itemA) => "AreaSoporte/"+itemA.categoria.id == item.idCategoria)
      setAgentes(agentesFilter)
    }
    handleIsModalAsignar(type)
  };


  const handleCancelModalNew = async () => {
    handleIsModalNew(false)
  };

  const newTicket = async () => {
    handleIsModalNew(true)
  };

  const agregarData = async (data) => {
    var allTickets = [... tickets]
    allTickets.unshift(data)
    setTickets(allTickets)
  }

  const updateAsignar = async (itemTicket,data) => {
    var allTickets = [... tickets]
    

    var itemSucursales = allTickets.find((item) => item.id == itemTicket.id)
    console.log("itemSucursales",data)
    if(typeof itemSucursales !== 'undefined'){
      var index = allTickets.indexOf(itemSucursales);
      if (index != -1) {
        allTickets[index].idUserAsignado = data.idUserAsignado
        allTickets[index].idUserAsignadoNombre = data.idUserAsignadoNombre
        allTickets[index].tiempoAsignarse = data.tiempoAsignarse
        allTickets[index].fechaAsignarse = moment(data.fechaAsignarse, 'DD/MM/YYYY')
      }

    }else{
      allTickets.push(data)
    }
    console.log("itemSucursales",allTickets[index])


    setTickets(allTickets)
    
  }

  const buscarFechas = async(fechaInicio,fechaFin) => {


    let today = new Date(fechaInicio);
    let fechaNueva =  new Date(fechaFin);

    console.log("fecha ==>",today,fechaNueva)

    
    handleLoadingBuscar(true);
    const result = await getListTickets(user,today,fechaNueva);
    console.log("result",result)
    if(result.ok){
      setTickets(result.listadoTicket)
    }else{
      message.error('¡Hubo un problema!');
    }
    handleLoadingBuscar(false);

  }
 

  return (
    <TicketsUI
      loading={loading}
      tickets={tickets}
      users={users}
      isModalDetails={isModalDetails}
      item={item}
      images={images}
      cancelModalDetails={handleCancelModalDetails}
      cancelModalNew={handleCancelModalNew}
      cancelModalAsignar={handleCancelModalAsignar}
      showDetails={handleDetail}
      editorVisible={editorVisible}
      confirmDelete={confirmDelete}
      handleActive={handleActive}
      newTicket={newTicket}
      isModalNew={isModalNew}
      areas={areas}
      restaurantes={restaurantes}
      tipificacion={tipificacion}
      agregarData={agregarData}
      isModalAsignar={isModalAsignar}
      agentes={agentes}
      updateAsignar={updateAsignar}
      loadingBuscar={loadingBuscar}
      buscarFechas={buscarFechas}
      fechaInicio={fechaInicio}
      fechaFin={fechaFin}
    />
  );
}

export default Tickets;
