import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Form, message} from 'antd';
import {signIn, validation} from '../../redux/slices/user';
import LoginUI from './components/LoginUI';
import { loginWithEmailPassword, logoutFirebase } from '../../firebase/authProvider';

const Main = () => {
  const [sending, handleSending] = useState(false);
  const [code, handleCode] = useState(null);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const login = async () => {
    handleSending(true);
    try {
      if (!code) {
        const {user, password} = await form.validateFields();
        if (user.length && password.length) {

          const result = await loginWithEmailPassword({ user, password });
         
          if (result.ok === true) {
            const response = await dispatch(validation(result));
          } else {
            message.error("Email o contraseña incorrectos");
          }
        } else {
          message.error('Ingresa los campos solicitados');
        }
      }
    } catch (errorInfo) {
      message.error('¡Hubo un problema! Inténtalo de nuevo');
    }
    handleSending(false);
  };

  return (
    <LoginUI
      login={login}
      form={form}
      sending={sending}
      code={code}
    />
  );
}

export default Main;
