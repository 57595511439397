import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {message, Modal} from 'antd';
import { deleteBitacora, getAllUser, getListLogs,getAllRestaurante } from '../../firebase/queryProvider';
import LogUI from './components/LogUI';
import moment from 'moment';


const Logs = () => {
  const [loading, handleLoading] = useState(true);
  const [editorVisible, handleEditorVisible] = useState(false);
  const [isModalDetails, handleIsModalDetails] = useState(false);
  const [logs, setLogs] = useState([]);
  const [item, setItem] = useState(null);
  const [restaurantes, setRestaurantes] = useState([]);
  const [users, setUsers] = useState([]);
  const [loadingBuscar, handleLoadingBuscar] = useState(false);
  const [fechaInicio, setFechaInicio] = useState(false);
  const [fechaFin, setFechaFin] = useState(false);
  const [fechaInicioSelect, setFechaInicioSelect] = useState(null);
  const [fechaFinSelect, setFechaFinSelect] = useState(null);

  const dateFormat = 'DD/MM/YYYY';
  const customFormat = (value) => `${value.format(dateFormat)}`;

  const dispatch = useDispatch();

  useEffect(() => {
   

    const initialFetch = async () => {

      let today = new Date();
      let fechaNueva =  new Date(moment(today).add(-6, 'day'));

      var fechaInicio = new Date(fechaNueva.setHours(0,0,0,0));
      var fechaFinal = new Date(new Date().setHours(23,59,59,59));
      fechaFinal.setHours(23); // establece la hora a las 23:00
      fechaFinal.setMinutes(59); // establece los minutos a 59
      fechaFinal.setSeconds(59); // establece los segundos a 59

      var formattedDate = moment(fechaInicio).format(dateFormat);
      var formattedDateF = moment(fechaFinal).format(dateFormat);
      
      setFechaInicio(formattedDate)
      setFechaFin(formattedDateF)

      setFechaInicioSelect(fechaInicio)
      setFechaFinSelect(fechaFinal)
      const result = await getListLogs(fechaInicio,fechaFinal);

      console.log("result",result)
      if(result.ok){
        setLogs(result.listadoLogs)
      }else{
        message.error('¡Hubo un problema!');
      }

      const resultUsers = await getAllUser();
      if(resultUsers.ok){
        setUsers(resultUsers.listadoUser)
      }

      const resultRestaurantes = await getAllRestaurante();
      if(resultRestaurantes.ok){
        setRestaurantes(resultRestaurantes.listadoUser)
      }


      handleLoading(false);
    };

    initialFetch();
  }, [dispatch]);

  const buscarFechas = async(fechaInicio,fechaFin) => {
    console.log("fechaInicio",fechaInicio)
    console.log("fechaFin",fechaFin)
    handleLoadingBuscar(true);
    const result = await getListLogs(fechaInicio,fechaFin);
    console.log("result",result)
    if(result.ok){
      setLogs(result.listadoLogs)
    }else{
      message.error('¡Hubo un problema!');
    }
    handleLoadingBuscar(false);

  }

  const handleCancelModalDetails = async () => {
    handleIsModalDetails(false)
  };

  return (
    fechaInicio != false ?
    <LogUI
      loading={loading}
      logs={logs}
      restaurantes={restaurantes}
      users={users}
      buscarFechas={buscarFechas}
      loadingBuscar={loadingBuscar}
      fechaInicioG={fechaInicio}
      fechaFinG={fechaFin}
    />
    : null
  );
}

export default Logs;
