import {useState, useRef} from 'react';

import {Button, Table, Spin, Modal, Col, Row,DatePicker, Space} from 'antd';
import Header from '../../../components/HeaderList';
import {ReactComponent as Edit} from '../../../assets/icons/edit.svg';
import {ReactComponent as Trash} from '../../../assets/icons/trash.svg';
import {getColumnSearchProps} from '../../../utils/tables';
import Editor from './Editor';
import styles from '../styles/BitacoraUI.module.css';
import locale from 'antd/es/date-picker/locale/es_ES';

const dateFormat = 'DD/MM/YYYY';
const { RangePicker } = DatePicker;
const customFormat = (value) => `${value.format(dateFormat)}`;


const BitacoraUI = props => {
  const searchInput = useRef(null);
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");

  const {
    loading,
    bitacoras,
    restaurantes,
    users,
    isModalDetails,
    item,
    cancelModalDetails,
    showDetails,
    removeBitacora,
    buscarFechas,
    loadingBuscar,
    assingBuscar
  } = props;

  const getUser = () => {
    const localities = [];
    users.map(d => {
      localities.push({
        text: d.nombre + " " +  d.apellido,
        value: d.nombre + " " +  d.apellido,
      });
    });
    return localities;
  };

  const getRestaurante = () => {
    const localities = [];
    restaurantes.map(d => {
      localities.push({
        text: d.nombre,
        value: d.nombre,
      });
    });
    return localities;
  };

  const geUserName = locality => {
   
    let name = "-";
    const index = users.findIndex(el => el.nombre + " " + el.apellido === locality);
    if (index > -1) {
      name = users[index].nombre + " " +  users[index].apellido;
    }
    return name;
  };

  const geRestauranteName = locality => {
    let name = "-";
    const index = restaurantes.findIndex(el => el.nombre === locality);
    if (index > -1) {
      name = restaurantes[index].nombre;
    }
    return name;
  };
 

  const onChangeFecha = (date, dateString) => {
    console.log(date, dateString);
    setFechaInicio(dateString[0])
    setFechaFin(dateString[1])
    //setStartDate(dateString[0])
    //setEndDate(dateString[1])
    //const [startDate, setstartDate] = useState("");
    //const [endDate, setendDate] = useState("");
  };

  const columns = [
    {
      title: 'Fecha',
      dataIndex: 'fechaCreado',
      width: '200px',
    },
    {
      title: 'Asunto',
      align: 'left',
      dataIndex: 'asunto',
      render: (id, record) => (
        <div className={styles.actions}>
          <div className={styles.edit} onClick={() => showDetails(record)}>
           {record.asunto}
          </div>
        </div>
      ),
      ...getColumnSearchProps('asunto'),
    },
    {
      title: 'De',
      dataIndex: 'de',
      align: 'center',
      width: '200px',
      filterSearch: true,
      filters: getUser(),
      onFilter: (val, record) => record.de === val,
      /*onFilter: (val, record) => {
        console.log(record.de,val)
        //record.de === val
        record.de.startsWith(val)
      },*/
      render: val => geUserName(val, searchInput),
    },
    {
      title: 'Sucursal',
      dataIndex: 'restaurante',
      align: 'center',
      width: '200px',
      filterSearch: true,
      filters: getRestaurante(),
      onFilter: (val, record) => record.restaurante === val,
      /*onFilter: (val, record) => {
        console.log(record.de,val)
        //record.de === val
        record.de.startsWith(val)
      },*/
      render: val => geRestauranteName(val, searchInput),
    },
  ];

  return (
    <div className={styles.view}>
      <Header
        title="Listado de Bitácoras"
      />
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <div style={{display:"flex",marginBottom:"20px"}}>
            <RangePicker format={customFormat} locale={locale} style={{
                  width: "400px",
                }}
                
                onChange={onChangeFecha} />
            <Button
              style={{marginLeft:"20px"}}
              size="large"
              onClick={() => {
                buscarFechas(fechaInicio,fechaFin)
              }}
              loading={loadingBuscar}
              type={'primary'}
              className={styles.submit}>
                BUSCAR
            </Button>
                
          </div>
          <Table
            columns={columns}
            dataSource={bitacoras}
            rowKey="id"
          />
        </div>
      )}

        {
          item != null ?
          <Modal 
          className='primary-modal'
          title="Detalle de bitácora" 
          visible={isModalDetails}
          onCancel={() => cancelModalDetails()}
          footer = { <Row>

            <Col span={6} style={{textAlign:"left"}}>
              
                <Button 
                  className={styles.btDelete}
                    type="primary" 
                    shape="round" 
                    style={{ width: "90%" }}
                    onClick={() => { 
                      //cancelModalDetails()
                      Modal.confirm({
                        title: '¿Desea eliminar este registro?',
                        content: '',
                        okText: 'Eliminar',
                        okType: 'danger',
                        cancelText: 'Cancelar',
                        async onOk() {
                          await removeBitacora(item.id);
                        },
                        onCancel() {},
                      });
                    }}
                    >
                    Eliminar
                </Button>
              
            </Col>

            <Col span={12} style={{textAlign:"right"}}></Col>

            <Col span={6} style={{textAlign:"right"}}>
              <Button 
                  // disabled={requests.isSaving} 
                  className={styles.btCerrar}
                  type="primary" 
                  shape="round" 
                  style={{ width: "90%" }} 
                  onClick={() => { 
                    cancelModalDetails()
                  }}
                  >
                  Cerrar
              </Button>
            </Col>
          </Row> }>

          <form action="">
          <div className={styles.fechaCreado}>{item.fechaCreado}</div>
          <div className={styles.restaurante}>Usuario: {item.de}</div>
          <div className={styles.restaurante}>Restaurante: {item.restaurante}</div>
          <div className={styles.asunto}>{item.asunto}</div>
              

          </form>

          
          </Modal>
          : null
        }
        
      
    </div>
  );
};

export default BitacoraUI;
