import { Layout, Menu, Dropdown } from "antd";
import moment from "moment-timezone";
import "moment/locale/es";
import { Link, useLocation, Routes, Route } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/logo/logo_cuadrado.svg";
import {ReactComponent as Arrow} from '../../../assets/icons/arrow-left.svg';
import {Col, Row} from 'antd';



import styles from "../styles/TerminosCondicionesUI.module.css";

const { Header, Content, Sider } = Layout;

const TerminosCondicionesUI = ({ closeSession, user, rol }) => {

  return (
    <Layout className={styles.layout}>
       <Row>
          <Col span={2}></Col>
          <Col span={20}>
            <Layout className={styles.layout}>
        
              <div
                className={styles.header} >
                  <Link to={"/"} className={styles.backWrap}>
                    <Arrow />
                  </Link>
                <div className={styles.headerInfo}>
                  <span className={styles.title}>Condiciones de uso y política de privacidad</span>
                </div>
              </div>
              <Content className={styles.content}>
                <h1>ACEPTACIÓN DE LAS CONDICIONES DE USO.</h1>
                <p>El presente documento contiene las reglas aplicables al uso de la aplicación CheckList Papa John's SV y Portal <a href="https://checklist.papajohns.com.sv">www.checklist.papajohns.com.sv</a> que es propiedad de Franquicias Internacionales, S.A. de C.V. y que en adelante nos referiremos como "Franquicias Internacionales, S.A. de C.V.".</p>
                <p>Cualquier persona natural o jurídica, que desee formar parte del personal de Franquicias Internacionales S.A. de C.V. puede hacerlo siempre y cuando posea un usuario autorizado por el departamento de Información y Tecnología, Además, deberá aceptar las Políticas de Uso, Políticas de Privacidad, las cuales tiene el carácter de vinculante entre las partes.</p>
                <p>Estas Condiciones de Uso rigen el uso del contenido y las funciones disponibles a través de los nombres de dominio <a href="https://checklist.papajohns.com.sv">www.checklist.papajohns.com.sv</a> operado por Franquicias Internacionales S.A. de C.V. por lo que al instalar o Utilizar la App CheckList Papa Johns’s SV, aceptas quedar obligado en virtud de estas Condiciones de Uso. Si no estás de acuerdo con estas Condiciones de Uso, no debes usar la App.</p>
                <p>Estas Condiciones de Uso constituyen un contrato continuo entre tú y Franquicias Internacionales y se aplican al uso que hagas en la App CheckList Papa Johns’s SV. Estas Condiciones de Uso afectan tus derechos; por lo tanto, debes leerlas con atención.</p>
                <br/>
                <h1>MODIFICACIÓN DE LAS CONDICIONES DE USO</h1>
                <p>Franquicias Internaciones S.A. de C.V. se reserva el derecho de modificar estas Condiciones de Uso en forma oportuna, con o sin aviso previo, a su entera y absoluta discreción. Se podrá consultar la versión más reciente de las Condiciones de Uso haciendo clic en "Condiciones de Uso", que se encuentra en la parte inferior de la pantalla de inicio de sesión de la App CheckList Papa Johns’s SV y portal www.checklist.papajohns.com.sv. La versión más reciente de las Condiciones de Uso reemplaza todas las versiones anteriores. El uso que hagas del la App CheckList Papa Johns’s SV una vez efectuadas las modificaciones implica que aceptas quedar obligado por dichas modificaciones.</p>
                <br/><h1>PRIVACIDAD E INFORMACIÓN PERSONAL</h1>
	              <p>Franquicias Internacionales S.A. de C.V. ha asumido el compromiso de proteger la privacidad de la información personal que proporcionas en nuestra App CheckList Papa Johns’s SV. Toda información enviada desde y hasta nuestros servidores está sujeta en nuestras condiciones de uso, cuyos términos se incorporan al presente.</p>
                <br/><h1>PERMISOS DE UBICACIÓN</h1>
                <p>Franquicias Internacionales S.A. de C.V. no compartirá ninguno de los registros enviados por el dispositivo con fines lucrativos ó de interés a instituciones ajenas a la entidad, El Acceso a la Aplicación CheckList Papa John's SV y Portal www.checklist.papajohns.com.sv será en mutuo acuerdo para que el usuario acepte compartir de manera total el acceso al uso del GPS, para propósitos de uso de la App CheckList Papa John's SV el cual será de utlidad para el usuario y conocer la totalidad de los kilómetros recorridos por cada trayecto que el active dicho servicio, cabe mencionar que El usuario puede iniciar o detener el servicio de rastreo en el momento que lo desee. El propósito principal de que los permisos de Ubicación sean otorgados a la App CheckList Papa John's SV serán para el establecimiento correcto de los kilómetros recorridos por el usuario y que este posteriormente pueda verificar el total de su trayecto, rutas ó puntos de partidas y destinos completamente identificados por el GPS de la app siempre y cuando el usuario haya activado la función de iniciar recorrido dentro de la App.</p>
                <p>Cualquier inconveniente con la aplicación ó uso de la misma el usuario puede reportarlo al correo de <a href="mailto:soporte@papajohns.com.sv">soporte@papajohns.com.sv</a>.</p>
                <br/>
                <h1>PERMISOS DE UBICACIÓN EN SEGUNDO PLANO</h1>
                <p>Franquicias Internacionales S.A. de C.V. mediante su Aplicación CheckList Papa John's SV y en Mutuo acuerdo con el Usuario de la App, solicita los permisos de accesos para que la Aplicación CheckList Papa John's SV pueda rastrear, almacenar, monitorear, mediante la herramienta de GPS del dispositivo en instalado como en Segundo Plano, por lo cual aparecerá oculto y no visible el servicio de monitoreo para el establecimiento correcto de los kilómetros recorridos por el usuario y que este posteriormente pueda verificar el total de su trayecto, rutas puntos de partidas y destinos completamente identificados por el GPS de la app siempre y cuando el usuario haya activado la función de iniciar recorrido dentro de la App. Los permisos otorgados no serán en ningún momento utilizado para fines publicitarios.</p>

                <br/><h1>FUNCIONES DE PERMISOS DE UBICACIÓN</h1>
                <p>A continuación se muestra una lista de funciones que podrían usarse mediante el acceso a la ubicación:</p>

                <ul>
                  <li>Es necesario el usuario conozca que haremos unos de función de la ubicación del usuario permitiendo que se muestren notificaciones, alertas y funciones aun cuando la aplicación CheckList Papa John's SV esté cerrada.</li>
                  <li>El Usuario siempre podrá colocar su punto de salida y punto de llegada mediante el seguimiento de entregas o servicios, por parte del usuario como conductor o transportista.</li>
                  <li>Usuario siempre será visible por el GPS siempre y cuando se haya presionado el botón de “INICIAR RECORRIDO” esta opción estará incluso en Segundo Plano del Sistema, aun si el usuario saliera de la app, la única manera para parar dicho monitoreo ó GPS es que el usuario presione en “FINALIZAR RUTA”.</li>
                  <li>Siempre sabras donde estás mediante la Función de navegación detallada mientras el usuario está dentro de la aplicación visualizara un mapa del lugar donde se encuentra, así como hacer un seguimiento pasivo de rutas o pasos, o detectar si el usuario empieza a conducir o deja de hacerlo.</li>
                  <li>Función que agrega los datos de ubicación del usuario para mostrar patrones de tráfico o puntos de alta congestión, o para mapear las velocidades de Internet cercanas.</li>
                </ul>

                <br/><h1>TU USUARIO</h1>
                <p>Para el acceso a nuestra App es necesario que poseas un usuario de acceso con los roles de uso, asumiendo la responsabilidad de mantener la confidencialidad de tu cuenta y contraseña, y de restringir el acceso a tu cuenta, y te comprometes a aceptar la responsabilidad respecto de todas las actividades que se realicen en tu cuenta o con tu contraseña. Si tienes menos de 18 años, no puedes registrar una cuenta, comprar productos, ni participar en ninguna oferta o promoción. Franquicias Internacionales S.A. de C.V. se reserva el derecho de negar el servicio, cancelar una cuenta, o eliminar o editar contenido, o cancelar los pedidos a su entera discreción.</p>


                <br/><h1>TU ACEPTACIÓN</h1>
                <p>Al instalar y/o utilizar nuestra App Checklist Papa John's SV, manifiestas que aceptas estos términos y condiciones (las "Condiciones del Servicio") y los términos y condiciones de la Notificación de Privacidad de Franquicias Internacionales S.A. de C.V., que se incorporan al presente por su sola mención. Si no estás de acuerdo con alguna de estas condiciones, solicitamos de favor que no utilices por ningún motivo nuestra App de monitoreo Checklist Papa John's SV. Asimismo, aceptas recibir llamadas, mensajes de texto y notificaciones, por medios automatizados. Inscribirse para recibir llamadas y mensajes de texto no es una condición para utilizarla.</p>

                <br/><h1>LIMITACIÓN DE RESPONSABILIDAD</h1>
                <p>Franquicias Internacionales S.A. de C.V., y sus ejecutivos, directores, empleados o agentes, no serán responsables frente a ti, bajo ninguna circunstancia, respecto de ningún daño directo, indirecto, incidental, especial, punitivo o mediato que surja de (I) errores o imprecisiones de contenido, (II) lesiones personales o daños materiales de ninguna naturaleza, que surjan del acceso y uso de nuestro sitio web, (III) algún acceso o uso no autorizado de nuestros servidores seguros y/o alguna información personal o financiera almacenada en estos, (IV) toda interrupción o cancelación de la transmisión desde y hacia la App, (V) errores, virus, troyanos, etc. que puedan transmitirse a nuestra App o a través de este por parte de un tercero y/o (VI) errores u omisiones en algún contenido ni respecto de pérdidas o daños de cualquier tipo en los que se haya incurrido como resultado del uso de algún contenido publicado en el sitio web de Papa John's, ya sea que se base en un supuesto de responsabilidad por garantía, contractual, extracontractual, o cualquier otra teoría legal, independientemente de que se haya advertido o no a Papa John's acerca de la posibilidad de que se produzcan. La limitación que antecede se aplicará en la máxima medida permitida por la ley en la jurisdicción aplicable.</p>
              
                <p>Tú reconoces específicamente que Franquicias Internacionales S.A. de C.V. no será responsable por envíos de información por parte del usuario ni por la conducta difamatoria, ofensiva o ilegal del usuario o un tercero, y que tú asumes totalmente el riesgo de sufrir un daño a causa de ello.</p>
              
                <br/><h1>INDEMNIDAD</h1>
                <p>Aceptas defender, indemnizar y mantener indemnes a FRANQUICIAS INTERNACIONALES S.A. DE C.V., y a sus sociedades controlantes, afiliadas, ejecutivos, directores, empleados y agentes, respecto de toda reclamación, daño, obligación, pérdida, responsabilidad, costo o deuda, y gasto (incluidos por ejemplo, los honorarios de abogados) que surjan de: (I) tu acceso al Sitio Web o el uso de este; (II) una violación de tu parte a algún término de estas Condiciones de Servicio; (III) una violación de tu parte de un derecho de terceros, como por ejemplo, un derecho de autor, de propiedad o de privacidad; o (IV) toda reclamación de que alguna Información Enviada como Usuario por parte tuya es ofensiva, difamatoria, obscena u objetable de otro modo, o de que has causado un daño a un tercero. Esta obligación de defensa e indemnidad prevalecerá vigente con posterioridad a la extinción de estas Condiciones de Servicio y su uso del App CheckList Papa Johns’s SV.</p>
              
                <br/><h1>PUBLICIDAD</h1>
                <p>Franquicias Internacionales S.A. de C.V. con el fin de evitar malinterpretaciones de publicidad se limitá a no compartir información interna con ninguna otra empresa, por lo que el uso de la aplicación no contiene publicidad que no corresponda a la marca de Papa John's, Adicionalmente, las imágenes que aparecen dentro de nuestra App ó Web son de carácter ilustrativo o identificación para el usuario.</p>
                <br/><h1>HABILIDAD PARA ACEPTAR LAS CONDICIONES DE SERVICIO</h1>
                <p>Afirmas que tienes más de 18 años o eres un menor emancipado, o tienes consentimiento legal de tus padres o tutor, y que tienes plena capacidad de aceptar los términos, las condiciones, las obligaciones, las afirmaciones, las manifestaciones y las garantías que se establecen en estas Condiciones de Servicio, y de obligarte en virtud de estas Condiciones de Servicio. En cualquier caso, afirmas que tienes más de 18, dado que la App CheckList Papa Johns’s SV no está destinado a niños menores de 18. Si tienes menos de 18 años de edad, hay muchos otras Apps sitios web grandiosos para ti. Habla con tus padres acerca de qué sitios son adecuados para ti.</p>
                              
              </Content>
              
            </Layout>
          </Col>

        </Row>
    </Layout>
  );
};

export default TerminosCondicionesUI;
