import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {logout, selectUser} from '../../redux/slices/user';
import {setLoaded} from '../../redux/slices/monitor';
import TerminosCondicionesUI from './components/TerminosCondicionesUI';

const Main = () => {
  const user =  useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {

  }, []);

 

  return (
    <TerminosCondicionesUI />
  );
}

export default Main;
