import {useRef,useState,useEffect} from 'react';
import {Button, Table, Spin, Modal, Col, Row,Form,Input,Select,TreeSelect,InputNumber,message,Checkbox} from 'antd';
import Header from '../../../components/HeaderList';
import {ReactComponent as Edit} from '../../../assets/icons/edit.svg';
import {ReactComponent as Trash} from '../../../assets/icons/trash.svg';
import styles from '../styles/SucursalUI.module.css';
import { updateDocument,createDocument} from '../../../firebase/queryProvider';
import {getColumnSearchProps} from '../../../utils/tables';
import moment from 'moment';
import instance from "../../../api/Request";

const {Option} = Select;
const {TreeNode} = TreeSelect;

const SucursalUI = props => {  
  const [form] = Form.useForm();
  const [sending, handleSending] = useState(false);
  const [todasTareas, setTodasTareas] = useState(false);

  

  const {
    loading,
    sucursales,
    item,
    isModalDetails,
    cancelModalDetails,
    showDetails,
    departamentos,
    actualizarData,
    agregarData,
    confirmDelete,
    newSucursal
  } = props;

  useEffect(() => {
    const initialFetch = async () => {
      if (item) {
        var departamento = ""
        var itemDepartamento = departamentos.find((data) => data.id == item.idDepartamento)
        console.log("itemDepartamento",itemDepartamento,departamentos)
        if(typeof itemDepartamento !== 'undefined'){
          departamento = itemDepartamento.nombre
        }

        

        const newValues = {
          name: item.nombre,
          webID: item.webID,
          departamento: departamento,
          caja1: "",
          caja2: "",
          caja3: ""
        };
                
        var cajas = item.cajas
        if(cajas != undefined){
            var contador = 0
            cajas.forEach( async (docummentC) => {
                if(contador == 0){
                    newValues.caja1 = docummentC
                }else if(contador == 1){
                    newValues.caja2 = docummentC
                }else if(contador == 2){
                    newValues.caja3 = docummentC
                }
                contador++
            })
        }

        

        console.log(newValues)
        form.setFieldsValue(newValues);
      }
    };
    initialFetch();
  }, [item]);

  const getUser = () => {
    const localities = [];
    sucursales.map(d => {
      localities.push({
        text: d.nombre + " " +  d.apellido,
        value: d.nombre + " " +  d.apellido,
      });
    });
    return localities;
  };

  const geUserName = locality => {
   
    let name = "-";
    const index = sucursales.findIndex(el => el.nombre + " " + el.apellido === locality);
    if (index > -1) {
      name = sucursales[index].nombre + " " +  sucursales[index].apellido;
    }
    return name;
  };
 
  const onChange = (e: CheckboxChangeEvent) => {
    setTodasTareas(e.target.checked)
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'webID',
      align: 'left',
      ...getColumnSearchProps('webID'),
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      align: 'left',
      ...getColumnSearchProps('nombre'),
    },
    {
      title: 'Departamento',
      dataIndex: 'departamento',
      align: 'left',
      ...getColumnSearchProps('departamento'),
    },
    {
      title: 'Editar',
      dataIndex: 'id',
      width: 140,
      render: (id, record) => (
        <div className={styles.actions}>
          <div className={styles.edit} onClick={() => showDetails(record)}>
            <Edit />
          </div>
          <div className={styles.divider} />
          <div className={styles.delete} onClick={() => confirmDelete(id)}>
            <Trash />
          </div>
        </div>
      )
    },
    
  ];


  const save = async () => {
   
    try {
     
      const values = await form.validateFields();
      if(values.name == ""){
        message.success('Ingrese nombre');
      }else if(values.departamento == ""){
        message.success('Ingrese departamento');
      }else if(values.webID == ""){
        message.success('Ingrese ID');
      }else{
        handleSending(true);
        var idDepartamento = ""
        var nombreDepartamento = ""
        //console.log(values.departamento)
        var itemDepartamento = departamentos.find((data) => data.id == values.departamento)
       
        if(typeof itemDepartamento !== 'undefined'){
          idDepartamento = itemDepartamento.id
          nombreDepartamento = itemDepartamento.nombre
        }

        const info = {
          nombre: values.name,
          webID: values.webID
        };

        try {
          var cajas = []

          if(values.caja1+"".trim() != '' && values.caja1 != null){
            cajas.push(values.caja1+"".trim()+"")
          }

          if(values.caja2+"".trim() != '' && values.caja2 != null){
            cajas.push(values.caja2+"".trim()+"")
          }

          if(values.caja3+"".trim() != '' && values.caja3 != null){
            cajas.push(values.caja3+"".trim()+"")
          }

        } catch (error) {
          console.log(error)
        }

        info.cajas = cajas

        console.log("item",info)

        if(item.nuevo == undefined){
          if(idDepartamento != ""){
            info.idDepartamento = idDepartamento
          }


          const response =  await updateDocument(info,`/Tiendas/${item.id}`)
          console.log("response",response)
          if (response.ok === true) {
            message.success('Cambios realizados con éxito');
            info.id = item.id
            info.activo = true
            
            
            if(idDepartamento != ""){
              info.departamento = itemDepartamento.nombre
            }else{
              info.departamento = item.departamento
              info.idDepartamento = item.idDepartamento
            }
            

            
            
            handleSending(false)
            actualizarData(info)
            cancelModalDetails()
          } else {
            message.error('¡Hubo un problema! Inténtalo de nuevo');
          }

         
        }else{
          info.idDepartamento = idDepartamento
          info.activo = true
          info.fechaCreado = moment(new Date(), 'DD/MM/YYYY').toDate();
          info.fechaActualizado = moment(new Date(), 'DD/MM/YYYY').toDate();
        
          const response =  await createDocument(`Tiendas`,info)
          console.log("response",response)
          var idTienda = response.info.id

          info.departamento = nombreDepartamento
          info.id = idTienda


          if(todasTareas){
            console.log('/nueva_tienda.php?id='+info.id)
            const request = await instance();
            let dataRequest = await request
            .get('/nueva_tienda.php?id='+info.id)
            .catch((error) => {
              return {
                error,
              };
            });
      
          }

          
          message.success('Sucursal creada');
          handleSending(false)
          agregarData(info)
          cancelModalDetails()

        }
        
      }
      
      /*if (zone) {
        info.id = zone.id;
      }
      if (zone) {
        const response = await dispatch(updateZone(info));
        if (response.status === 'success') {
          message.success('Cambios realizados con éxito');
        } else {
          message.error('¡Hubo un problema! Inténtalo de nuevo');
        }
      } else {
        const response = await dispatch(createZone(info));
        if (response.status === 'success') {
          message.success('Cambios realizados con éxito');
          handleZone(response.zone);
        } else {
          message.error('¡Hubo un problema! Inténtalo de nuevo');
        }
      }
      handleSending(false);*/
    } catch (e) {
    }
  };

  return (
    <div className={styles.view}>
      <Header
        title="Listado de Sucursales"
        actions={
          <Button
            onClick={newSucursal}
            type="primary"
            size="large"
            className="main-buttonHeader">
            AGREGAR SUCURSAL
          </Button>
        }
      />
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Table
            columns={columns}
            dataSource={sucursales}
            rowKey="id"
          />
        </div>
      )}


      {
          item != null ?
          <Modal 
          className='primary-modal'
          title="Sucursal"
          width={650}
          visible={isModalDetails}
          onCancel={() => cancelModalDetails()}
          footer = { <Row>

            <Col span={6} style={{textAlign:"left"}}>
              <Button 
                    // disabled={requests.isSaving} 
                    className={styles.btCerrar}
                    type="primary" 
                    shape="round" 
                    style={{ width: "90%" }} 
                    onClick={() => { 
                      cancelModalDetails()
                    }}
                    >
                  Cerrar
              </Button>
                
              
            </Col>

            <Col span={12} style={{textAlign:"right"}}>
              
            </Col>

            <Col span={6} style={{textAlign:"right"}}>
            

              <Button
                  loading={sending}
                  onClick={() => {
                    save()
                  }}
                  type="primary" 
                  shape="round" 
                  htmlType="submit"
                  style={{ width: "90%" }}
                  >
                  Guardar
              </Button>
            </Col>
          </Row> }>

            <Form
              name="branch-editor"
              autoComplete="off"
              form={form}
              colon={false}
              requiredMark={false}
              onFinish={save}
            >

              <Row gutter={20}>
                

                <Col span={12}>
                  <Form.Item
                    label={
                      <span className={styles.label}>
                        Nombre
                      </span>
                    }
                    name="name"
                    className={styles.itemColumn}
                    rules={[{required: true, message: 'Ingresar Nombre'}]}
                  >
                    <Input size="large" className={styles.input} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label={
                      <span className={styles.labelOnly}>
                        Departamento
                      </span>
                    }
                    name="departamento"
                    className={styles.itemColumn}
                    rules={[{required: true, message: 'Ingresar departamento'}]}
                  >
                    <Select size="large" className={styles.input}>
                      {departamentos.map(branch => (
                        <Option key={branch.id} value={branch.id}>{branch.nombre}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

              </Row>

              <Row gutter={0}>
                <Col span={6} className={styles.cajas}>
                  <Form.Item
                    label={
                      <span className={styles.label}>
                          ID  
                      </span>
                    }
                    labelCol={{ span: 24 }}
                    name="webID"
                    
                    className={styles.itemColumn}
                    rules={[{required: true, message: 'Ingresar ID'}]}
                  >
                    <InputNumber style={{ width:"85%" }} size="large" min={1} max={100} className={styles.input}/>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label={
                      <span className={styles.label}>
                          CAJA 1  
                      </span>
                    }
                    name="caja1"
                    labelCol={{ span: 24 }}
                    className={styles.itemColumn}
                  >
                    <InputNumber style={{ width:"85%" }} size="large" min={1} max={500} className={styles.input}/>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label={
                      <span className={styles.label}>
                          CAJA 2  
                      </span>
                    }
                    name="caja2"
                    labelCol={{ span: 24 }}
                    className={styles.itemColumn}
                  >
                    <InputNumber style={{ width:"85%" }} size="large" min={1} max={500} className={styles.input}/>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label={
                      <span className={styles.label}>
                          CAJA 3  
                      </span>
                    }
                    name="caja3"
                    labelCol={{ span: 24 }}
                    className={styles.itemColumn}
                  >
                    <InputNumber style={{ width:"100%" }} size="large" min={1} max={500} className={styles.input}/>
                  </Form.Item>
                </Col>
              </Row>

              {
                item.nuevo == true ?
                <Checkbox  checked={todasTareas} onChange={onChange}>Asignar todas las tareas</Checkbox>
                : null
              }

            </Form>

          
          </Modal>
          : null
        }
    </div>
  );
};

export default SucursalUI;
