import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {message, Modal} from 'antd';
import { getAllSucursales,getAllDepartamento,updateDocument} from '../../firebase/queryProvider';
import SucursalUI from './components/SucursalUI';



const Sucursales = () => {
  const [loading, handleLoading] = useState(true);
  const [editorVisible, handleEditorVisible] = useState(false);
  const [sucursales, setSucursales] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [item, setItem] = useState(null);
  const [isModalDetails, handleIsModalDetails] = useState(false);

  
  const dispatch = useDispatch();

  useEffect(() => {
   

    const initialFetch = async () => {
     
      /*const resultTe= await getTareaTemporal();
      console.log(resultTe)*/
      //const resultCorrelativo = await reasignarCorrelativo();
      const resultDepartamento = await getAllDepartamento()
      if(resultDepartamento.ok){
        setDepartamentos(resultDepartamento.listadoDepartamentos)
      }

      const result = await getAllSucursales();
      console.log(result)
      if(result.ok){
        setSucursales(result.listadoUser)
      }else{
        message.error('¡Hubo un problema!');
      }

      /*const resultTiendas = await getAllRestaurante();
      if(resultTiendas.ok){
        setRestaurantes(resultTiendas.listadoTiendas)
      }*/
      handleLoading(false);
    };

    initialFetch();
  }, [dispatch]);

  const handleDetail = async (item) => {
    setItem(item)
    handleIsModalDetails(true)
  };

  const confirmDelete = idSucursal => {
    Modal.confirm({
      title: '¿Estás segúro de eliminar esta sucursal?',
      content: 'Esta información no se podrá recuperar.',
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        await removeSucursal(idSucursal);
      },
      onCancel() {},
    });
  };

  const removeSucursal = async idSucursal => {
    console.log("idSucursal",idSucursal)
    const info = {
      activo: false
    };

    const response =  await updateDocument(info,`/Tiendas/${idSucursal}`)
    console.log("response",response)
    if (response.ok === true) {
      var allSucursales = [... sucursales]

      const index = allSucursales.findIndex(el => el.id === idSucursal);
      if (index > -1) {
        allSucursales.splice(index, 1);
      }

      setSucursales(allSucursales)

      message.success('Sucursal eliminada correctamente');
    }else{
      message.error('¡Hubo un problema!');
    }
    
  };

  const handleCancelModalDetails = async () => {
    setItem(null)
    handleIsModalDetails(false)
  };

  const agregarData = async (data) => {
    var allSucursales = [... sucursales]
    allSucursales.unshift(data)
    console.log(data)
    console.log(allSucursales)
    setSucursales(allSucursales)
  }

  const actualizarData = async (data) => {
    var allSucursales = [... sucursales]
    var itemSucursales = allSucursales.find((item) => item.id == data.id)
    if(typeof itemSucursales !== 'undefined'){
      var index = allSucursales.indexOf(itemSucursales);
      if (index != -1) {
        allSucursales[index] = data
      }

    }else{
      allSucursales.push(data)
    }


    setSucursales(allSucursales)

    
  };

  const newSucursal = async () => {
    var item = {}
    item.nuevo = true
    setItem(item)
    handleIsModalDetails(true)
    
  };
  

  return (
    <SucursalUI
      loading={loading}
      sucursales={sucursales}
      isModalDetails={isModalDetails}
      cancelModalDetails={handleCancelModalDetails}
      showDetails={handleDetail}
      actualizarData={actualizarData}
      agregarData={agregarData}
      item={item}
      departamentos={departamentos}
      confirmDelete={confirmDelete}
      newSucursal={newSucursal}
    />
  );
}

export default Sucursales;
