import {useRef,useState,useEffect,useCallback} from 'react';
import {Button, Table, Spin, Modal, Col, Row,Form,Input,Select,message,Upload,DatePicker} from 'antd';
import Header from '../../../components/HeaderList';
import {getColumnSearchProps} from '../../../utils/tables';
import Editor from './Editor';
import styles from '../styles/MonitorUI.module.css';
import ImageViewer from 'react-simple-image-viewer';
import {useSelector, useDispatch} from 'react-redux';
import { getRutas, getUbicaciones,createDocument,updateDocument} from '../../../firebase/queryProvider';
import {selectUser} from '../../../redux/slices/user';
import moment from 'moment';
import instance from "../../../api/Request";
import locale from 'antd/es/date-picker/locale/es_ES';
import dayjs from 'dayjs';
import {ReactComponent as Edit} from '../../../assets/icons/edit.svg';
import {ReactComponent as Trash} from '../../../assets/icons/trash.svg';

import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"

dayjs.extend(weekday)
dayjs.extend(localeData)


const {Option} = Select;
const { TextArea } = Input;

const dateFormat = 'DD/MM/YYYY';
const customFormat = (value) => `${value.format(dateFormat)}`;

const UbicacionesUI = props => {
  const [loading, handleLoading] = useState(true);
  const [sending, handleSending] = useState(false);
  const [form] = Form.useForm();
  const [isModalAsignar, setIsModalAsignar] = useState(false);
  const [loadingAsignar, setLoadingAsignar] = useState(false);
  const [rutas, setRutas] = useState([]);
  const [ubicaciones, setUbicaciones] = useState([]);
  const [item, setItem] = useState(null);

  
  const dispatch = useDispatch();
  
  const user = useSelector(selectUser);

  useEffect(() => {
    const initialFetch = async () => {
      const resultUbicaciones = await getUbicaciones();
      
      if(resultUbicaciones.ok){
        setUbicaciones(resultUbicaciones.listadoUbicaciones)
      }else{
        message.error('¡Hubo un problema!');
      }

      handleLoading(false);
    };
    initialFetch();
  }, [dispatch]);

  const agregarData = async (data) => {
    var allUbicaciones = [... ubicaciones]
    allUbicaciones.unshift(data)
    setUbicaciones(allUbicaciones)
  }

  const actualizarData = async (data) => {
    var allUbicaciones = [... ubicaciones]
    var itemData = allUbicaciones.find((item) => item.id == data.id)
    if(typeof itemData !== 'undefined'){
      var index = allUbicaciones.indexOf(itemData);
      if (index != -1) {
        allUbicaciones[index] = data
      }

    }
    setUbicaciones(allUbicaciones)
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      handleSending(true);

      var itemSend = {}
      itemSend.activo = true
      itemSend.nombre = values.nombre


      if(item == undefined){
        itemSend.fechaCreado = moment(new Date(), 'DD/MM/YYYY').toDate();
        const response =  await createDocument(`Ubicaciones`,itemSend)
        itemSend.id = response.info.id
        message.success('Ubicación creada');
        handleSending(false)
        agregarData(itemSend)
        setIsModalAsignar()
        form.resetFields();

      }else{
        itemSend.fechaCreado = moment(new Date(), 'DD/MM/YYYY').toDate()
  

        const response =  await updateDocument(itemSend,`/Ubicaciones/${item.id}`)
        console.log("response",response)
        if (response.ok === true) {
          message.success('Cambios realizados con éxito');
          itemSend.id = item.id
          itemSend.activo = true
          
          handleSending(false)
          actualizarData(itemSend)
          setIsModalAsignar()
          form.resetFields();
        } else {
          message.error('¡Hubo un problema! Inténtalo de nuevo');
        }
      }

     

    } catch (e) {
      console.log("====>",e)
      handleSending(false)
    }
  }
  
  const openEditor = (item) => {
    setItem(item);

    const newValues = {
      nombre: item.nombre
    };
    form.setFieldsValue(newValues);

    setIsModalAsignar(true);
  };

  const openNewItem = () => {
    setItem(null);

    const newValues = {
      origen: "",
      destino: "",
      recorrido: ""
    };
    form.setFieldsValue(newValues);

    setIsModalAsignar(true);
  };

  const confirmDelete = (idItem) => {
    Modal.confirm({
      title: '¿Estás segúro de eliminar esta ubicación?',
      content: 'Esta información no se podrá recuperar.',
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        await removeCategoria(idItem);
      },
      onCancel() {},
    });
  };

  const removeCategoria = async (idItem) => {
    const info = {
      activo: false
    };

    const response =  await updateDocument(info,`/Ubicaciones/${idItem}`)
    console.log("response",response)
    if (response.ok === true) {
      var UbicacionesAll = [... ubicaciones]

      const index = UbicacionesAll.findIndex(el => el.id === idItem);
      if (index > -1) {
        UbicacionesAll.splice(index, 1);
      }

      setUbicaciones(UbicacionesAll)

      message.success('Ubicación eliminada correctamente');
    }else{
      message.error('¡Hubo un problema!');
    }
  };

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      align: 'left',
      ...getColumnSearchProps('nombre'),
    },
    {
      title: 'Editar',
      align: 'center',
      dataIndex: 'id',
      render: (id, record) => (
        <div className={styles.actions}>
          <div className={styles.edit} onClick={() => openEditor(record)}>
            <Edit />
          </div>
          <div className={styles.divider} />
          <div className={styles.delete} onClick={() => confirmDelete(id)}>
            <Trash />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className={styles.view}>
      <Header
        title="UBICACIONES"
        back="/monitor"
        actions={
          <Button
            onClick={openNewItem}
            type="primary"
            size="large"
            className="main-buttonHeader">
            AGREGAR UBICACIÓN
          </Button>
        }
      />
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div  className={styles.sizeTable}>
          <Table
            columns={columns}
            dataSource={ubicaciones}
            rowKey="id"
          />
        </div>
      )}



        <Modal 
        className='primary-modal'
        title="Ubicación"
        visible={isModalAsignar}
        style={{ top: 20 }}
        onCancel={() => setIsModalAsignar(false)}
        footer = { <Row>

          <Col span={6} style={{textAlign:"left"}}>
            
              
            
          </Col>

          <Col span={12} style={{textAlign:"right"}}></Col>

          <Col span={6} style={{textAlign:"right"}}>
            <Button 
                // disabled={requests.isSaving}
                loading={sending}
                className={styles.btCerrar}
                type="primary" 
                shape="round" 
                style={{ width: "90%" }} 
                onClick={() => {
                  save()
                }}
                >
                Guardar
            </Button>
          </Col>
        </Row> }>

        <div>
        
          <Form
            name="branch-editor"
            autoComplete="off"
            form={form}
            colon={false}
            requiredMark={false}
            onFinish={save}
          >

          
            
            <Row>
              
              <Col span={24}>
                <Form.Item
                  label={
                    <span className={styles.label}>
                      Nombre
                    </span>
                  }
                  name="nombre"
                  className={styles.itemColumn}
                  rules={[{required: true, message: 'Ingrese nombre'}]}
                >
                <Input size="large" className={styles.input} />
                </Form.Item>
              </Col>
            </Row>

          
            
            
          </Form>
        </div>

        
        </Modal>

    </div>
  );
};

export default UbicacionesUI;
