import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {message, Modal} from 'antd';
import { getTipificacion,getAreaSoporte,updateDocument} from '../../firebase/queryProvider';
import TipificacionUI from './components/TipificacionUI';


const Tipificacion = () => {
  const [loading, handleLoading] = useState(true);
  const [editorVisible, handleEditorVisible] = useState(false);
  const [tipificaciones, setTipificaciones] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [item, setItem] = useState(null);
  const [isModalDetails, handleIsModalDetails] = useState(false);

  
  const dispatch = useDispatch();

  useEffect(() => {
   

    const initialFetch = async () => {
     
      const resultAreaSoporte = await getAreaSoporte()
      if(resultAreaSoporte.ok){
        setCategorias(resultAreaSoporte.listadoAreaSoporte)
      }

      const resultTipificacion = await getTipificacion()
      if(resultTipificacion.ok){
        setTipificaciones(resultTipificacion.listadoTipificacion)
      }else{
        message.error('¡Hubo un problema!');
      }

    
      handleLoading(false);
    };

    initialFetch();
  }, [dispatch]);

  const handleDetail = async (item) => {
    setItem(item)
    handleIsModalDetails(true)
  };

  const handleCancelModalDetails = async () => {
    handleIsModalDetails(false)
  };

  const newTipificacion = async () => {
    var item = {}
    item.nuevo = true
    setItem(item)
    handleIsModalDetails(true)
  };

  const agregarData = async (data) => {
    var allTipificaciones = [... tipificaciones]
    allTipificaciones.unshift(data)
    setTipificaciones(allTipificaciones)
  }

  const actualizarData = async (data) => {
    console.log("data",data)
    var allTipificaciones = [... tipificaciones]
    var itemTipificacion = allTipificaciones.find((item) => item.id == data.id)
    if(typeof itemTipificacion !== 'undefined'){
      var index = allTipificaciones.indexOf(itemTipificacion);
      if (index != -1) {
        allTipificaciones[index] = data
      }

    }
    setTipificaciones(allTipificaciones)
  };

  const confirmDelete = (idTificacion) => {
    Modal.confirm({
      title: '¿Estás segúro de eliminar esta tipificación?',
      content: 'Esta información no se podrá recuperar.',
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        await removeTipificacion(idTificacion);
      },
      onCancel() {},
    });
  };

  const removeTipificacion = async (idTificacion) => {
    const info = {
      visible: false
    };

    const response =  await updateDocument(info,`/Tipificaciones/${idTificacion}`)
    console.log("response",response)
    if (response.ok === true) {
      var allTipificaciones = [... tipificaciones]

      const index = allTipificaciones.findIndex(el => el.id === idTificacion);
      if (index > -1) {
        allTipificaciones.splice(index, 1);
      }

      setTipificaciones(allTipificaciones)

      message.success('Tipificación eliminada correctamente');
    }else{
      message.error('¡Hubo un problema!');
    }
  };



  return (
    <TipificacionUI
      loading={loading}
      tipificaciones={tipificaciones}
      isModalDetails={isModalDetails}
      cancelModalDetails={handleCancelModalDetails}
      showDetails={handleDetail}
      item={item}
      categorias={categorias}
      newTipificacion={newTipificacion}
      actualizarData={actualizarData}
      agregarData={agregarData}
      confirmDelete={confirmDelete}
    />
  );
}

export default Tipificacion;
