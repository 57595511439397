import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {message, Modal} from 'antd';
import { updateDocument,getAllCategorias} from '../../firebase/queryProvider';
import CategoriasUi from './components/CategoriasUI';


const Categorias = () => {
  const [loading, handleLoading] = useState(true);
  const [editorVisible, handleEditorVisible] = useState(false);
  const [categorias, setCategorias] = useState([]);
  const [item, setItem] = useState(null);
  const [isModalDetails, handleIsModalDetails] = useState(false);

  
  const dispatch = useDispatch();

  useEffect(() => {
   

    const initialFetch = async () => {
     
      const resultCategorias = await getAllCategorias()
      if(resultCategorias.ok){
        setCategorias(resultCategorias.listadoCategorias)
      }else{
        message.error('¡Hubo un problema!');
      }

    
      handleLoading(false);
    };

    initialFetch();
  }, [dispatch]);

  const handleDetail = async (item) => {
    setItem(item)
    handleIsModalDetails(true)
  };

  const handleCancelModalDetails = async () => {
    handleIsModalDetails(false)
  };

  const newCategoria = async () => {
    var item = {}
    item.nuevo = true
    setItem(item)
    handleIsModalDetails(true)
  };

  const agregarData = async (data) => {
    var allCategoria = [... categorias]
    allCategoria.unshift(data)
    setCategorias(allCategoria)
  }

  const actualizarData = async (data) => {
    console.log("data",data)
    var allCategoria = [... categorias]
    var itemUser = allCategoria.find((item) => item.id == data.id)
    if(typeof itemUser !== 'undefined'){
      var index = allCategoria.indexOf(itemUser);
      if (index != -1) {
        allCategoria[index] = data
      }

    }
    setCategorias(allCategoria)
  };

  const confirmDelete = (idCategoria) => {
    Modal.confirm({
      title: '¿Estás segúro de eliminar esta categoría?',
      content: 'Esta información no se podrá recuperar.',
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        await removeCategoria(idCategoria);
      },
      onCancel() {},
    });
  };

  const removeCategoria = async (idCategoria) => {
    const info = {
      activo: false
    };

    const response =  await updateDocument(info,`/Categorias/${idCategoria}`)
    console.log("response",response)
    if (response.ok === true) {
      var allCategoria = [... categorias]

      const index = allCategoria.findIndex(el => el.id === idCategoria);
      if (index > -1) {
        allCategoria.splice(index, 1);
      }

      setCategorias(allCategoria)

      message.success('Categoría eliminada correctamente');
    }else{
      message.error('¡Hubo un problema!');
    }
  };


  return (
    <CategoriasUi
      loading={loading}
      categorias={categorias}
      isModalDetails={isModalDetails}
      cancelModalDetails={handleCancelModalDetails}
      showDetails={handleDetail}
      item={item}
      newCategoria={newCategoria}
      actualizarData={actualizarData}
      agregarData={agregarData}
      confirmDelete={confirmDelete}
    />
  );
}

export default Categorias;
