import {useRef,useState,useEffect,useCallback} from 'react';
import {Button, Table, Spin, Modal, Col, Row,Form,Input,Select,message,Upload,DatePicker} from 'antd';
import Header from '../../../components/HeaderList';
import {ReactComponent as Edit} from '../../../assets/icons/edit.svg';
import {ReactComponent as Trash} from '../../../assets/icons/trash.svg';
import {getColumnSearchProps} from '../../../utils/tables';
import Editor from './Editor';
import styles from '../styles/TicketsUI.module.css';
import ImageViewer from 'react-simple-image-viewer';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { async } from '@firebase/util';
import { FirebaseStorage,FirebaseDB } from '../../../firebase/config';
import { collection,doc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { createReferencia,obtenerCorrelativo,updateTicketAsignar} from '../../../firebase/queryProvider';
import {useSelector} from 'react-redux';
import {selectUser} from '../../../redux/slices/user';
import moment from 'moment';
import { updateDocument,createDocument} from '../../../firebase/queryProvider';
import instance from "../../../api/Request";
import locale from 'antd/es/date-picker/locale/es_ES';
import dayjs from 'dayjs';
import XLSX from 'xlsx';

//import customParseFormat from 'dayjs/plugin/customParseFormat';

//dayjs.extend(customParseFormat);


import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"

dayjs.extend(weekday)
dayjs.extend(localeData)


const {Option} = Select;
const { TextArea } = Input;

const dateFormat = 'DD/MM/YYYY';
const { RangePicker } = DatePicker;
const customFormat = (value) => `${value.format(dateFormat)}`;

const TicketsUI = props => {
  const searchInput = useRef(null);
  const [sending, handleSending] = useState(false);
  const [form] = Form.useForm();
  const [formAsignar] = Form.useForm();
  const [loadingImages, setLoadingImages] = useState(false);
  const [imageUrlUno, setImageUrlUno] = useState(null);
  const [imageUrlDos, setImageUrlDos] = useState(null);
  const [idRef, setIdRef] = useState("");
  const [tipificacionSelect, setTipificacionSelect] = useState([]);
  const [itemAgente, setItemAgente] = useState(null);
  const [loadingAsignar, setLoadingAsignar] = useState(false);

  const user = useSelector(selectUser);

  
  const {
    loading,
    tickets,
    users,
    item,
    images,
    isModalDetails,
    cancelModalDetails,
    showDetails,
    newTicket,
    isModalNew,
    cancelModalNew,
    cancelModalAsignar,
    areas,
    restaurantes,
    tipificacion,
    agregarData,
    isModalAsignar,
    agentes,
    updateAsignar,
    buscarFechas,
    loadingBuscar,
    fechaInicio,
    fechaFin
  } = props;

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const [fechaInicioSelect, setFechaInicioSelect] = useState(null);
  const [fechaFinSelect, setFechaFinSelect] = useState(null);


  useEffect(() => {
    const initialFetch = async () => {
      const newValues = {};

      newValues.asignarAgenteSelect = ""
      formAsignar.setFieldsValue(newValues);

    };
    initialFetch();
  }, [agentes]);
  

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const getUser = () => {
    const localities = [];
    users.map(d => {
      localities.push({
        text: d.nombre + " " +  d.apellido,
        value: d.nombre + " " +  d.apellido,
      });
    });
    return localities;
  };

  const getEstado = () => {
    const localities = [];
    localities.push({
      text: "Sin asignar",
      value: "Sin asignar",
    });
    localities.push({
      text: "Finalizado",
      value: "Finalizado",
    });
    localities.push({
      text: "Asignado",
      value: "Asignado",
    });
    return localities;
  };

  const exportToExcel = (data) => {
    var dataRecibida = [... data]
    

    const newArray = dataRecibida.map(obj => {
      const newObj = {};
      newObj.Correlativo = obj.correlativo;
      newObj.Fecha = obj.fechaCreado;
      newObj.Asunto = obj.asunto;
      newObj.De = obj.de;
      newObj.TiempoAsignarse = obj.tiempoAsignarse;
      newObj.TiempoInicio = obj.tiempoIniciar;
      newObj.TiempoInicio = obj.tiempoIniciar;
      newObj.TiempoFinalizacion = obj.tiempoFinalizado;
      newObj.Asignado = obj.idUserAsignadoNombre;
      newObj.Descripcion = obj.descripcion;
      newObj.Estado = obj.estado === "Finalizado" ? "Finalizado" : obj.idUserAsignadoNombre ? "Asignado" : "Sin asignar"
      newObj.fechaFinalizado = obj.fechaFinalizado;
      newObj.Categoria = obj.categoria;
      newObj.Comentario = obj.comentario;
      newObj.HoraProceso = obj.horaProceso;
      newObj.imageUno = obj.imageUno;
      newObj.imageDos = obj.imageDos;
      return newObj;
    });



    console.log(newArray)
    const workbook = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(newArray);
    XLSX.utils.book_append_sheet(workbook, sheet, 'Sheet1');
    XLSX.writeFile(workbook, 'tickets.xlsx');
  }

  const getUserAgente = () => {
    const localities = [];
    var agentes = users.filter( request => (request.rol == "Agentes"));

    agentes.map(d => {
      localities.push({
        text: d.nombre + " " +  d.apellido,
        value: d.nombre + " " +  d.apellido,
      });
    });
    return localities;
  };

  const geUserName = locality => {
   
    let name = "-";
    const index = users.findIndex(el => el.nombre + " " + el.apellido === locality);
    if (index > -1) {
      name = users[index].nombre + " " +  users[index].apellido;
    }
    return name;
  };
 
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('¡Solo puede cargar un archivo JPG/PNG!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('¡La imagen debe tener un tamaño inferior a 2 MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    console.log(info)
    if (info.file.status === 'uploading') {
      setLoadingImages(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      console.log("listo para subir la imagen?")
      console.log(info.file.originFileObj)
      /*getBase64(info.file.originFileObj, (url) => {
        setLoadingImages(false);
        setImageUrl(url);
      });*/
    }
  };

  const uploadButton = (
    <div>
      {loadingImages ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Subir Imagen
      </div>
    </div>
  );

  const customUpload = async({ onError, onSuccess, file }) => {

    setLoadingImages(true);
   // const orderRef = collection( FirebaseDB, `Tickets`).doc();
    var idTicket = idRef
    if(idRef == ""){
      const idRefGenerate = await doc(collection(FirebaseDB, "Tickets"));
      setIdRef(idRefGenerate.id)
      idTicket = idRefGenerate.id
    }

    console.log("idRef",idTicket)

    const imageRef = ref(FirebaseStorage, 'tickets/'+idTicket+ "/" + file.name);
    uploadBytesResumable(imageRef, file)
    .then((snapshot) => {
      console.log('Uploaded', snapshot.totalBytes, 'bytes.');
      console.log('File metadata:', snapshot.metadata);
      // Let's get a download URL for the file.
      getDownloadURL(snapshot.ref).then((url) => {
        console.log('File available at', url);
        setLoadingImages(false);

        if(imageUrlUno == null){
          console.log("carga imagen uno")
          setImageUrlUno(url);
        }else{
          setImageUrlDos(url);
        }
       
        // ...
      });
    }).catch((error) => {
      console.error('Upload failed', error);
      setLoadingImages(false);
      // ...
    });

    
  };

  const asignarAgenteTicket = async (item,agente) => {

    setLoadingAsignar(true)

    var startTime =  moment(item.fechaCreadoDate.toDate())
    var endTime = moment(new Date())
    
    // calculate total duration
    var duration = moment.duration(endTime.diff(startTime));
    // duration in minutes
    var minutes = parseInt(duration.asMinutes());

    var data = {
        idUserAsignado: agente.id,
        idUserAsignadoNombre: agente.nombre,
        tiempoAsignarse: minutes,
        fechaAsignarse: new Date()
    }

    const result = await updateTicketAsignar(data,item.id);
    console.log("result",result)
    if(result.ok){

      var dataSend = {}
      dataSend.TareaID = ""
      dataSend.TicketID = item.id
      dataSend.MsgID = "NTAG001"
      dataSend.ProfileID = agente.id


      const request = await instance();
      let dataRequest = await request
      .post('/notificaciones.php', {dataSend})
      .catch((error) => {
        return {
          error,
        };
      });

      message.success('Ticket asignado');

      data.tiempoAsignarse = data.tiempoAsignarse != undefined ? secondsToHms(data.tiempoAsignarse * 60) : "-"
      data.tiempoIniciar = data.tiempoIniciar != undefined ? secondsToHms(data.tiempoIniciar * 60) : "-"
      data.tiempoFinalizado = data.tiempoFinalizado != undefined ? secondsToHms(data.tiempoFinalizado * 60) : "-"


      updateAsignar(item,data)
      setLoadingAsignar(false)
      cancelModalAsignar(false)
     
    }else{
      message.error('¡Hubo un problema!');
    }

}

  const save = async () => {
    try {
      const values = await form.validateFields();
      handleSending(true);

     
      //subir tarea
      var data = {}
      
      //buscar categoria
      let categoriaRef  = await createReferencia('/AreaSoporte/'+values.departamento)
      
      //buscar tienda
      let TiendaRef = await createReferencia('/Tiendas/'+values.sucursal)

      let correlativo = await obtenerCorrelativo()

      //buscar tipificacion
      var itemTipificacion = tipificacion.find((data) => data.id == values.tipificacion)
      var fechaGuardar = moment(new Date(), 'DD/MM/YYYY')

      console.log("===>",correlativo)
      data = {
        id: correlativo.correlativo,
        activo: true,
        imageUno: imageUrlUno,
        imageDos: imageUrlDos,
        fechaActualizado: fechaGuardar.toDate(),
        fechaCreado: fechaGuardar.toDate(),
        categoria: categoriaRef,
        tienda: TiendaRef,
        asunto: values.name,
        tipificacion: values.tipificacion,
        horaProceso: itemTipificacion.horas,
        retraso: 0,
        completo: 0,
        idUser: user.idUser,
        tipo: 1,
        descripcion: values.name,
      }


      const response =  await createDocument(`Tickets`,data)
      var idTicket = response.info.id
      data.correlativo = correlativo.correlativo
      data.id = idTicket
      data.estado = "Nuevo"

      var fechaCreado = data.fechaCreado
      var fechaFinalizado = data.fechaFinalizado

      data.fechaCreado = fechaCreado == null ? "" : moment(fechaCreado).format("DD/MM/Y hh:mm A")
      data.fechaFinalizado = fechaFinalizado == null ? "" : moment(fechaFinalizado).format("DD/MM/Y hh:mm A")

      data.fechaCreadoGrafica = fechaCreado == null ? "" : moment(fechaCreado).format("DD/MM/Y")
      data.fechaFinalizadoGrafica = fechaFinalizado == null ? "" : moment(fechaFinalizado).format("DD/MM/Y")
      data.fechaCreadoDate = fechaGuardar
      data.idCategoria = categoriaRef.path

      

      data.prioridad = "Normal"

      var itemUser = users.find((item) => item.id == data.idUser)
      if(typeof itemUser !== 'undefined'){
          data.de =  itemUser.nombre + " " + itemUser.apellido
      }else{
          data.de =  ""
      }

      
      //obtener categoria
      var itemCategoria = areas.find((item) => item.id == values.departamento)
      if(typeof itemCategoria !== 'undefined'){
          data.categoria =  itemCategoria.nombre
      }else{
          data.categoria =  ""
      }

      //obtener sucursal
      var itemSucursal = restaurantes.find((item) => item.id == values.sucursal)
      if(typeof itemSucursal !== 'undefined'){
          data.sucursal =  itemSucursal.nombre
      }else{
          data.sucursal =  ""
      }

      data.tiempoAsignarse = data.tiempoAsignarse != undefined ? secondsToHms(data.tiempoAsignarse * 60) : "-"
      data.tiempoIniciar = data.tiempoIniciar != undefined ? secondsToHms(data.tiempoIniciar * 60) : "-"
      data.tiempoFinalizado = data.tiempoFinalizado != undefined ? secondsToHms(data.tiempoFinalizado * 60) : "-"

      

      message.success('Ticket creado');
      handleSending(false)
      agregarData(data)

      const newValues = {};
      newValues.departamento = ""
      newValues.name = ""
      newValues.sucursal = ""
      newValues.tipificacion = ""
      form.setFieldsValue(newValues);
      setImageUrlDos(null)
      setImageUrlUno(null)

      cancelModalNew()

     

    } catch (e) {
      console.log("====>",e)
      handleSending(false)
      message.error('¡Hubo un problema! Inténtalo de nuevo');
    }
  }

  const eliminarImagen = async (numero) => {
      if(numero == 1){
        if(imageUrlDos != null){
          var image = imageUrlDos
          setImageUrlUno(image)
          setImageUrlDos(null);
        }else{
          setImageUrlUno(null);
        }
      }else if(numero == 2){
        setImageUrlDos(null);
      }
  }
  
  const handleChangeDepartamento = (value: string) => {

    var listTipificacion = tipificacion.filter((data) => data.categoria == value)
    setTipificacionSelect(listTipificacion)
    const newValues = {};
    newValues.tipificacion = ""
    form.setFieldsValue(newValues);
  };

  const handleChangeAsignar = (value: string) => {
   // console.log("value",value)
    var itemAgente = agentes.find((data) => data.id == value)
    if(itemAgente != undefined){
      setItemAgente(itemAgente)
    }
  };


  const onChangeFecha = (date, dateString) => {
    //console.log(date, dateString);
    setFechaInicioSelect(date[0])
    setFechaFinSelect(date[1])
  };

  const toHoursAndMinutes = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
  
    return `${padToTwoDigits(hours)}:${padToTwoDigits(minutes)}`;
  }

  const padToTwoDigits = (num) => {
    return num.toString().padStart(2, '0');
  }

  const secondsToHms = (d) => {
    d = Number(d);
    if(d == 0){
      return"0 minuto"; 
    }else{
      var h = Math.floor(d / 3600);
      var m = Math.floor(d % 3600 / 60);
      var s = Math.floor(d % 3600 % 60);

      var mShow = m < 10 ? "0"+m : m

      var hDisplay = h > 0 ? h + (h == 1 ? " hora, " : " horas, ") : "";
      var mDisplay = m > 0 ? mShow + (m == 1 ? " minuto, " : " minutos, ") : "";
      var sDisplay = s > 0 ? s + (s == 1 ? " segundo" : " segundos") : "";

      if(h>0){
        return h + ":" + mShow +  " horas"; 
      }else if(m>0){
        return m > 0 ? mShow + (m == 1 ? " minuto " : " minutos ") : "";; 
      }else{
        return hDisplay + mDisplay + sDisplay; 
      }
    }
    
    
  }

  const columns = [
    {
      title: 'Ticket',
      dataIndex: 'correlativo',
      align: 'left',
      ...getColumnSearchProps('asunto'),
    },
    {
      title: 'Fecha',
      dataIndex: 'fechaCreado',
      render: (id, record) => (
        <div>
         {record.fechaCreado.split(" ")[0]}<br></br>
         {record.fechaCreado.split(" ")[1]}
        </div>
      ),
    },
    {
      title: 'Asunto',
      dataIndex: 'asunto',
      render: (id, record) => (
        <div className={styles.actions}>
          <div className={styles.edit} onClick={() => showDetails(record)}>
           {record.asunto}
          </div>
        </div>
      ),
      ...getColumnSearchProps('asunto'),
    },
    {
      title: 'De',
      dataIndex: 'de',
      align: 'left',
      filterSearch: true,
      filters: getUser(),
      onFilter: (val, record) => record.de === val,
      render: val => geUserName(val, searchInput),
    },
    {
      title: 'Solicitante',
      dataIndex: 'datoSolicitante',
      align: 'left',
      width: 150,
      ...getColumnSearchProps('datoSolicitante'),
      render: (id, record) => (
        <div>
         {record.nombreSolicitante}<br></br>
         {record.telefonoSolicitante}
        </div>
      ),
    },
    {
      title:  (
        <div>
          Tiempo en<br/>asignarse
        </div>
      ),
      dataIndex: 'tiempoAsignarse',
      align: 'left',
      ...getColumnSearchProps('tiempoAsignarse'),
      render: (id, record) => (
        <div>
         {record.tiempoAsignarse}
        </div>
      ),
    },
    {
      title:  (
        <div>
          Tiempo de<br/>inicio
        </div>
      ),
      dataIndex: 'tiempoIniciar',
      align: 'left',
      ...getColumnSearchProps('tiempoIniciar'),
      render: (id, record) => (
        <div>
         {record.tiempoIniciar}
        </div>
      ),
    },
    {
      title:  (
        <div>
          Tiempo de<br/>finalización
        </div>
      ),
      dataIndex: 'tiempoFinalizado',
      align: 'left',
      ...getColumnSearchProps('tiempoFinalizado'),
      render: (id, record) => (
        <div>
         {record.tiempoFinalizado}
        </div>
      ),
    },
    {
      title:  (
        <div>
          Estado
        </div>
      ),
      dataIndex: 'estado',
      align: 'left',
      filterSearch: true,
      filters: getEstado(),
      onFilter: (val, record) => (record.estado === "Finalizado" ? "Finalizado" : record.idUserAsignadoNombre ? "Asignado" : "Sin asignar") === val,
      render: (id, record) => (
        <div>
        {record.estado === "Finalizado" ? "Finalizado" : record.idUserAsignadoNombre ? "Asignado" : "Sin asignar"}
       
        </div>
      ),
    },
    
    {
      title: 'Asignado a',
      filterSearch: true,
      filters: getUserAgente(),
      dataIndex: 'idUserAsignadoNombre',
      onFilter: (val, record) => record.idUserAsignadoNombre === val,
      align: 'left',
    },
  ];

  return (
    <div className={styles.view}>
      <Header
        title="Listado de Tickets"
        actions={
          <Button
            onClick={newTicket}
            type="primary"
            size="large"
            className="main-buttonHeader">
            AGREGAR TICKET
          </Button>
        }
      />
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <div style={{display:"flex",marginBottom:"20px"}}>
            <RangePicker style={{
                  width: "400px",
                }}
                format={dateFormat}
                defaultValue={[dayjs(fechaInicio, dateFormat), dayjs(fechaFin, dateFormat)]}
                onChange={onChangeFecha} />

                
            <Button
              style={{marginLeft:"20px"}}
              size="large"
              onClick={() => {
                buscarFechas(fechaInicioSelect,fechaFinSelect)
              }}
              loading={loadingBuscar}
              type={'primary'}
              className={styles.submit}>
                BUSCAR
            </Button>

            <Button
              style={{marginLeft:"20px"}}
              size="large"
              onClick={() => {
                exportToExcel(tickets)
              }}
              loading={loadingBuscar}
              type={'primary'}
              className={styles.submit}>
                Exportar a Excel
            </Button>

                
          </div>
          <Table
            columns={columns}
            
            dataSource={tickets}
            rowKey="id"
          />
        </div>
      )}

      {
        item != null ?
        <Modal 
        className='primary-modal'
        title={item.asunto}
        visible={isModalDetails}
        style={{ top: 20 }}
        onCancel={() => cancelModalDetails()}
        footer = { <Row>

          <Col span={6} style={{textAlign:"left"}}>
            
              
            
          </Col>

          <Col span={12} style={{textAlign:"right"}}></Col>

          <Col span={6} style={{textAlign:"right"}}>
            <Button 
                // disabled={requests.isSaving} 
                className={styles.btCerrar}
                type="primary" 
                shape="round" 
                style={{ width: "90%" }} 
                onClick={() => { 
                  cancelModalDetails()
                  cancelModalAsignar(true,item)
                }}
                >
                Asignar
            </Button>
          </Col>
        </Row> }>

        <div>
        <div className={styles.fechaCreado}>{item.fechaCreado}</div>
        <div className={styles.correlativo}>Número de Correlativo: {item.correlativo}</div>
        <div className={styles.correlativo}>Estado: {item.estado}</div>
        <div className={styles.correlativo}>Categoría: {item.categoria}</div>

        <div className={styles.sucursal}>{item.sucursal}</div>

        <div className={styles.descripcion}>{item.descripcion}</div>

        {
              (item.imageUno != null || item.imageDos != null) ?
                  <div>
                      <div className={styles.txAdjunto}>ARCHIVOS ADJUNTOS</div>
                      <div className={styles.contImages}>
                              {images.map((src, index) => (
                                <img
                                  src={ src }
                                  onClick={ () => openImageViewer(index) }
                                  width="120"
                                  height="120"
                                  key={ Math.random() }
                                  style={{ margin: '2px' }}
                                  alt=""
                                />
                              ))}
                      </div>
                  </div>
              : null
        }

      {isViewerOpen && (
        <ImageViewer
          src={ images }
          currentIndex={ currentImage }
          disableScroll={ false }
          closeOnClickOutside={ true }
          onClose={ closeImageViewer }
        />
      )}
        <div className={styles.linea}></div>

        <div className={styles.correlativo}>Asignado a: </div>
        <div className={styles.txDetalleTarea}>{typeof item.idUserAsignadoNombre === 'undefined' ? "Ninguno": item.idUserAsignadoNombre}</div>


        </div>

        
        </Modal>
        : null
      }

        <Modal 
        className='primary-modal'
        title="Asignar agente"
        visible={isModalAsignar}
        style={{ top: 20 }}
        onCancel={() => cancelModalAsignar(false)}
        footer = { <Row>

          <Col span={6} style={{textAlign:"left"}}>
            
              
            
          </Col>

          <Col span={12} style={{textAlign:"right"}}></Col>

          <Col span={6} style={{textAlign:"right"}}>
            <Button 
                // disabled={requests.isSaving}
                loading={loadingAsignar}
                className={styles.btCerrar}
                type="primary" 
                shape="round" 
                style={{ width: "90%" }} 
                onClick={() => { 
                  asignarAgenteTicket(item,itemAgente)
                }}
                >
                Guardar
            </Button>
          </Col>
        </Row> }>

        <div>
        
          <Form
            name="branch-editor"
            autoComplete="off"
            form={formAsignar}
            colon={false}
            requiredMark={false}
            onFinish={save}
          >

          
            
            <Row>
              <Col span={24}>
                <Form.Item
                  label={
                    <span className={styles.label}>
                      Agente
                    </span>
                  }
                  name="asignarAgenteSelect"
                  className={styles.itemColumn}
                  rules={[{required: true, message: 'Seleccione agente'}]}
                >
                  <Select size="large" className={styles.input}  onChange={handleChangeAsignar}>
                    {agentes.map(branch => (
                      <Option key={branch.id} value={branch.id}>{branch.nombre} {branch.apellido}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

          
            
            
          </Form>
        </div>

        
        </Modal>

      <Modal 
        className='primary-modal'
        title="Nuevo Ticket"
        visible={isModalNew}
        onCancel={() => cancelModalNew()}
        footer = { <Row>

        <Col span={6} style={{textAlign:"left"}}>
          
            
          
        </Col>

        <Col span={12} style={{textAlign:"right"}}>
          
        </Col>

        <Col span={6} style={{textAlign:"right"}}>
        

          <Button
              loading={sending}
              type="primary" 
              onClick={() => {
                save()
              }}
              shape="round" 
              htmlType="submit"
              style={{ width: "90%" }}
              >
              Guardar
          </Button>
        </Col>
      </Row> }>

        <Form
          name="branch-editor"
          autoComplete="off"
          form={form}
          colon={false}
          requiredMark={false}
          onFinish={save}
        >

         
          
          <Row>
            <Col span={24}>
              <Form.Item
                label={
                  <span className={styles.label}>
                    Departamento
                  </span>
                }
                name="departamento"
                className={styles.itemColumn}
                rules={[{required: true, message: 'Ingresar Departamento'}]}
              >
                <Select size="large" className={styles.input}  onChange={handleChangeDepartamento}>
                  {areas.map(branch => (
                    <Option key={branch.id} value={branch.id}>{branch.nombre}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                label={
                  <span className={styles.label}>
                    Sucursal
                  </span>
                }
                name="sucursal"
                className={styles.itemColumn}
                rules={[{required: true, message: 'Ingresar Sucursal'}]}
              >
                <Select size="large" className={styles.input}>
                  {restaurantes.map(branch => (
                    <Option key={branch.id} value={branch.id}>{branch.nombre}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                label={
                  <span className={styles.label}>
                    Tipificación
                  </span>
                }
                name="tipificacion"
                className={styles.itemColumn}
                rules={[{required: true, message: 'Ingresar Tipificación'}]}
              >
                <Select size="large" className={styles.input}>
                  {tipificacionSelect.map(branch => (
                    <Option key={branch.id} value={branch.id}>{branch.nombre}</Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                label={
                  <span className={styles.label}>
                    Detalle del<br></br>requerimiento
                  </span>
                }
                name="name"
                className={styles.itemColumn}
                rules={[{required: true, message: 'Ingresar requerimiento'}]}
              >
                 <TextArea rows={4} className={styles.input} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24} className="imagesBlock">
            {imageUrlUno ? (
              <span className='ant-upload-picture-card-wrapper avatar-uploader'>
                <div className='ant-upload ant-upload-select ant-upload-select-picture-card'>
                  <img
                    src={imageUrlUno}
                    alt="avatar"
                    style={{
                      width: '100%',
                    }}
                  />
                  <Button
                      type="primary" 
                      onClick={() => {
                        eliminarImagen(1)
                      }}
                      danger
                      shape="round" 
                      htmlType="submit"
                      style={{ width: "90%", marginTop:"10px", marginBottom:"10px" }}
                      >
                      Eliminar
                  </Button>
                </div>
              </span>
               
              ) : (
                null
              )}

            {imageUrlDos ? (
              <span className='ant-upload-picture-card-wrapper avatar-uploader'>
                <div className='ant-upload ant-upload-select ant-upload-select-picture-card'>
                  <img
                    src={imageUrlDos}
                    alt="avatar"
                    style={{
                      width: '100%',
                    }}
                  />
                  <Button
                      type="primary" 
                      onClick={() => {
                        eliminarImagen(2)
                      }}
                      danger
                      shape="round" 
                      htmlType="submit"
                      style={{ width: "90%", marginTop:"10px", marginBottom:"10px" }}
                      >
                      Eliminar
                  </Button>
                </div>
              </span>
               
              ) : (
                null
              )}
  

            {imageUrlUno == null || imageUrlDos == null ?
              <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              customRequest={customUpload}
              beforeUpload={beforeUpload}
              onChange={handleChange}
              >
              {imageUrlUno || imageUrlDos ? (
                uploadButton
              ) : (
                uploadButton
              )}
              </Upload>

            : null 
            }
           

            </Col>
          </Row>
          
          
        </Form>

      
      </Modal>
      
    </div>
  );
};

export default TicketsUI;
