import {useRef,useState,useEffect} from 'react';
import {Button, Table, Spin, Modal, Col, Row,Form,Input,Select,TreeSelect,InputNumber,message} from 'antd';
import Header from '../../../components/HeaderList';
import {ReactComponent as Edit} from '../../../assets/icons/edit.svg';
import {ReactComponent as Trash} from '../../../assets/icons/trash.svg';
import styles from '../styles/TipificacionUI.module.css';
import { updateDocument,createDocument} from '../../../firebase/queryProvider';
import {getColumnSearchProps} from '../../../utils/tables';
import moment from 'moment';

const {Option} = Select;
const {TreeNode} = TreeSelect;

const CategoriasUi = props => {  
  const [form] = Form.useForm();
  const [sending, handleSending] = useState(false);

  

  const {
    loading,
    tipificaciones,
    item,
    isModalDetails,
    cancelModalDetails,
    showDetails,
    categorias,
    confirmDelete,
    actualizarData,
    newTipificacion,
    agregarData
  } = props;

  useEffect(() => {
    const initialFetch = async () => {
      if (item) {
        
        var categoria = ""
        var itemCategoria = categorias.find((data) => data.id == item.categoria)
        console.log("itemCategoria",itemCategoria)
        if(typeof itemCategoria !== 'undefined'){
          categoria =  itemCategoria.id
        }

        const newValues = {
          name: item.nombre,
          horas: item.horas,
          categoria: categoria
        };
        form.setFieldsValue(newValues);
      }
    };
    initialFetch();
  }, [item]);

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      align: 'left',
      ...getColumnSearchProps('nombre'),
    },
    {
      title: 'Categoría',
      dataIndex: 'categoriaName',
      align: 'left',
      ...getColumnSearchProps('categoriaName'),
    },
    {
      title: 'Horas',
      dataIndex: 'horas',
      align: 'left',
      ...getColumnSearchProps('horas'),
    },
    {
      title: '',
      dataIndex: 'id',
      width: 140,
      render: (id, record) => (
        <div className={styles.actions}>
          <div className={styles.edit} onClick={() => showDetails(record)}>
            <Edit />
          </div>
          <div className={styles.divider} />
          <div className={styles.delete} onClick={() => confirmDelete(id)}>
            <Trash />
          </div>
        </div>
      )
    },
    
  ];


  const save = async () => {
    //console.log("aqui!!!")
    try {
      
      const values = await form.validateFields();
      console.log(values)

      const info = {
        nombre: values.name,
        categoria: values.categoria,
        horas: values.horas
      };

      if(item.nuevo == undefined){
        handleSending(true);
        const response =  await updateDocument(info,`/Tipificaciones/${item.id}`)
        console.log("response",response)
        if (response.ok === true) {
          message.success('Cambios realizados con éxito');
          info.id = item.id
          info.visible = true

          var itemCategoria = categorias.find((data) => data.id == values.categoria)
          if(typeof itemCategoria !== 'undefined'){
            info.categoriaName = itemCategoria.nombre
          }

          
          handleSending(false)
          actualizarData(info)
          cancelModalDetails()
        } else {
          message.error('¡Hubo un problema! Inténtalo de nuevo');
        }


      }else{

        handleSending(true);
        info.visible = true
        info.fechaCreado = moment(new Date(), 'DD/MM/YYYY').toDate();
        info.fechaActualizado = moment(new Date(), 'DD/MM/YYYY').toDate();
      
        const response =  await createDocument(`Tipificaciones`,info)
        console.log("response",response)
        var idTipificacion = response.info.id

        var itemCategoria = categorias.find((data) => data.id == values.categoria)
        if(typeof itemCategoria !== 'undefined'){
          info.categoriaName = itemCategoria.nombre
        }

        info.id = idTipificacion
        
        message.success('Tipificación creada');
        handleSending(false)
        agregarData(info)
        cancelModalDetails()
      }

      handleSending(false);
    } catch (e) {
    }
  };

  return (
    <div className={styles.view}>
      <Header
        title="Listado de Tipificaciones"
        actions={
          <Button
            onClick={newTipificacion}
            type="primary"
            size="large"
            className="main-buttonHeader">
            AGREGAR TIPIFICACIÓN
          </Button>
        }
      />
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Table
            columns={columns}
            dataSource={tipificaciones}
            rowKey="id"
          />
        </div>
      )}


      {
          item != null ?
          <Modal 
          className='primary-modal'
          title="Tipificación"
          visible={isModalDetails}
          onCancel={() => cancelModalDetails()}
          footer = { <Row>

            <Col span={6} style={{textAlign:"left"}}>
              
                
              
            </Col>

            <Col span={12} style={{textAlign:"right"}}>
              
            </Col>

            <Col span={6} style={{textAlign:"right"}}>
            

              <Button
                  loading={sending}
                  type="primary" 
                  onClick={() => {
                    save()
                  }}
                  shape="round" 
                  htmlType="submit"
                  style={{ width: "90%" }}
                  >
                  Guardar
              </Button>
            </Col>
          </Row> }>

          <Form
        name="branch-editor"
        autoComplete="off"
        form={form}
        colon={false}
        requiredMark={false}
        onFinish={save}
      >

        <Row>
          <Col span={24}>
            <Form.Item
              label={
                <span className={styles.label}>
                  Nombre
                </span>
              }
              name="name"
              className={styles.itemColumn}
              rules={[{required: true, message: 'Ingresar Nombre'}]}
            >
              <Input size="large" className={styles.input} />
            </Form.Item>
          </Col>
        </Row>
        
        <Row>
          <Col span={24}>
            <Form.Item
              label={
                <span className={styles.label}>
                  Categoría
                </span>
              }
              name="categoria"
              className={styles.itemColumn}
              rules={[{required: true, message: 'Ingresar Categoría'}]}
            >
              <Select size="large" className={styles.input}>
                {categorias.map(branch => (
                  <Option key={branch.id} value={branch.id}>{branch.nombre}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item
              label={
                <span className={styles.label}>
                  Horas
                </span>
              }
              name="horas"
              className={styles.itemColumn}
              rules={[{required: true, message: 'Ingresar Horas'}]}
            >
              <InputNumber size="large" className={styles.input} />
            </Form.Item>
          </Col>
        </Row>
       
        
      </Form>

          
          </Modal>
          : null
        }
    </div>
  );
};

export default CategoriasUi;
