import { Layout, Menu, Dropdown } from "antd";
import moment from "moment-timezone";
import "moment/locale/es";
import { Link, useLocation, Routes, Route } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/logo/logo_cuadrado.svg";
import { ReactComponent as Ordenes } from "../../../assets/navigation/ordenes.svg";
import { ReactComponent as Portada } from "../../../assets/navigation/portada.svg";
import { ReactComponent as Productos } from "../../../assets/navigation/statistics.svg";
import { ReactComponent as ToppingsIcon } from "../../../assets/navigation/toppings.svg";
import { ReactComponent as Cobertura } from "../../../assets/navigation/cobertura.svg";
import { ReactComponent as Contenidos } from "../../../assets/navigation/contenidos.svg";
import { ReactComponent as Ajustes } from "../../../assets/navigation/ajustes.svg";
import { ReactComponent as Radar } from "../../../assets/navigation/radar.svg";
import { ReactComponent as Clock } from "../../../assets/icons/clock.svg";
import { ReactComponent as Account } from "../../../assets/icons/account.svg";
import { ReactComponent as ChevronDown } from "../../../assets/icons/chevron-down.svg";
import { ReactComponent as Bitacora } from "../../../assets/icons/bitacora.svg";
import { ReactComponent as Logs } from "../../../assets/icons/periodico.svg";


import styles from "../styles/DashboardUI.module.css";
import Tickets from "../../tickets/Main";
import Bitacoras from "../../bitacora/Main";
import HomeEstadisticas from "../../home/Main";
import Usuarios from "../../usuarios/Main";
import Sucursales from "../../sucursales/Main";
import Categorias from "../../categorias/Main";
import Tipificacion from "../../tipificacion/Main";
import Tareas from "../../tareas/Main";
import Monitor from "../../monitor/Main";
import RutasUI from "../../monitor/components/RutasUI";
import UbicacionesUI from "../../monitor/components/UbicacionesUI";
import RutasTiempoRealUI from "../../monitor/components/RutasTiempoRealUI";
import RutasHistoricoUI from "../../monitor/components/RutasHistorico";
import MapaRutaUI from "../../monitor/components/MapaRuta";
import Logss from "../../logss/Main";
import TareasReporte from "../../tareasReporte/Main";

const { Header, Content, Sider } = Layout;

const DashboardUI = ({ closeSession, user, rol }) => {
  const location = useLocation();

  const NavItem = ({ to, label, Icon }) => {
    const path = location.pathname.split("/")[1];
    const base = `/${path}`;

    return (
      <Link
        to={to}
        className={`${styles.item} ${
          (base === to || (path === "historial" && to === "/")) && styles.active
        }`}
      >
        <Icon className={styles.icon} />
        <span>{label}</span>
      </Link>
    );
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={closeSession} danger>
        Cerrar sesión
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout className={styles.layout}>
      
        <Sider
          width={100}
          className={styles.sider}
        >
          <div className={styles.logoWrap}>
            <Logo className={styles.logo} />
          </div>
          <div className={styles.nav}>
            {
              rol == "GerenteOperaciones" ?
              <>
              <NavItem to="/" label="Progreso de ticketes" Icon={Productos} />
              <NavItem to="/progreso_tarea" label="Progreso de tareas" Icon={Productos} />
              <NavItem to="/tickets" label="Tickets" Icon={Ordenes} />
              <NavItem className={styles.iconBitacora} to="/bitacora" label="Bitacora" Icon={Bitacora} />
              <NavItem to="/monitor" label="Monitor de kilometraje" Icon={Radar} />
              <NavItem to="/sucursales" label="Sucursales" Icon={Cobertura} />
              <NavItem to="/usuarios" label="Usuarios" Icon={Portada} />
              <NavItem to="/categorias" label="Categorias" Icon={ToppingsIcon} />
              <NavItem to="/tareas" label="Tareas" Icon={Contenidos} />
              <NavItem to="/tipificacion" label="Tipificación" Icon={Ajustes} />
              <NavItem to="/logs" label="Logs" Icon={Logs} />
              </>

              : null
            }

            {
              rol == "SupervisorTicket" ?
              <>
               <NavItem to="/" label="Actividad" Icon={Productos} />
              <NavItem to="/tickets" label="Tickets" Icon={Ordenes} />
              </>

              : null
            }

            {
              user.idUser == "TpNVWvSveQoPgJKVaTi5" ?
              <NavItem to="/monitor" label="Monitor de kilometraje" Icon={Radar} />
              : null
            }

            {
              user.idUser == "WXDUCLIT88xsnmcUmOl7" ?
              <>
                <NavItem to="/monitor" label="Monitor de kilometraje" Icon={Radar} />
                <NavItem to="/tickets" label="Tickets" Icon={Ordenes} />
              </>
              : null
            }

            
          
        </div>
        </Sider>
      
      <Layout className={styles.layout}>
        <Header className={styles.header}>
          <div className={styles.headerInfo}>
            <span className={styles.title}>Panel Administrativo</span>
            <Logo className={styles.logoMobile} />
            <div className={styles.wrapDate}>
              <Clock />
              <span className={styles.date}>
                {moment()
                  .tz("America/El_Salvador")
                  .format("D [de] MMMM  -  hh:mm A")}
              </span>
            </div>
            <Dropdown overlay={menu}>
              <div className={styles.accountWrap}>
                <span className={styles.account}>
                  {user.nombre}
                </span>
                <ChevronDown className={styles.chevron} />
                <div className={styles.iconAccount}>
                  <Account />
                </div>
              </div>
            </Dropdown>
          </div>
        </Header>
        <Content className={styles.content}>
         
            {
              user.idUser == "TpNVWvSveQoPgJKVaTi5" ?
              <Routes>
              <Route path="/" element={<Monitor />} />
              <Route path="/monitor" element={<Monitor />} />
              <Route path="/monitor/edicion-rutas" element={<RutasUI />} />
              <Route path="/monitor/ubicaciones" element={<UbicacionesUI />} />
              <Route path="/monitor/rutas" element={<RutasTiempoRealUI />} />
              <Route path="/monitor/historico" element={<RutasHistoricoUI />} />
              <Route path="/monitor/mapa/:id" element={<MapaRutaUI />} />
            </Routes>
              : 
              user.idUser == "WXDUCLIT88xsnmcUmOl7" ?
              <Routes>
                <Route path="/" element={<Monitor />} />
                <Route path="/monitor" element={<Monitor />} />
                <Route path="/monitor/edicion-rutas" element={<RutasUI />} />
                <Route path="/monitor/ubicaciones" element={<UbicacionesUI />} />
                <Route path="/monitor/rutas" element={<RutasTiempoRealUI />} />
                <Route path="/monitor/historico" element={<RutasHistoricoUI />} />
                <Route path="/monitor/mapa/:id" element={<MapaRutaUI />} />
                <Route path="/tickets" element={<Tickets />} />
              </Routes>
              :
              <Routes>
                <Route path="/" element={<HomeEstadisticas />} />
                <Route path="/tickets" element={<Tickets />} />
                <Route path="/progreso_tarea" element={<TareasReporte />} />
                <Route path="/bitacora" element={<Bitacoras />} />
                <Route path="/usuarios" element={<Usuarios />} />
                <Route path="/sucursales" element={<Sucursales />} />
                <Route path="/categorias" element={<Categorias />} />
                <Route path="/tipificacion" element={<Tipificacion />} />
                <Route path="/tareas" element={<Tareas />} />
                <Route path="/monitor" element={<Monitor />} />
                <Route path="/monitor/edicion-rutas" element={<RutasUI />} />
                <Route path="/monitor/ubicaciones" element={<UbicacionesUI />} />
                <Route path="/monitor/rutas" element={<RutasTiempoRealUI />} />
                <Route path="/monitor/historico" element={<RutasHistoricoUI />} />
                <Route path="/monitor/mapa/:id" element={<MapaRutaUI />} />
                <Route path="/logs" element={<Logss />} />

                
              </Routes>
            }
            
          
        </Content>
       
      </Layout>
    </Layout>
  );
};

export default DashboardUI;
