import {Button, Table, Spin, Modal} from 'antd';
import Header from '../../../components/HeaderList';
import { Link, NavLink } from 'react-router-dom';
import styles from '../styles/MonitorUI.module.css';
import { ReactComponent as Rutas } from "../../../assets/icons/rutas_menu.svg";
import { ReactComponent as Ubicacion } from "../../../assets/icons/ubicaciones.svg";
import { ReactComponent as RutaReal } from "../../../assets/icons/carretera.svg";
import { ReactComponent as Expediente } from "../../../assets/icons/expediente.svg";

const MonitorUI = props => {
  const {
    loading
  } = props;

  return (
    <div className={styles.view}>
      <Header
        title="Monitor de kilometraje"
      />

      <div className={styles.contOpciones}>
        <ul className={styles.primaryCardList}>

          <NavLink to="/monitor/ubicaciones">
            <li className={styles.primaryCardCard}>
                <Ubicacion className={styles.iconUbicacion} />
                
                <div>UBICACIONES</div>
            </li>  
          </NavLink>

          <NavLink to="/monitor/edicion-rutas">
            <li className={styles.primaryCardCard}>
              <Rutas className={styles.icon} />
              <div>EDICIÓN DE RUTAS</div>
                
            </li>  
          </NavLink>

          

          <NavLink to="/monitor/rutas">
          <li className={styles.primaryCardCard}>
              <RutaReal className={styles.iconUbicacion} />
                
              <div>RUTAS EN TIEMPO REAL</div>
            </li>  
          </NavLink>

          <NavLink to="/monitor/historico">
          <li className={styles.primaryCardCard}>
              <Expediente className={styles.iconUbicacion} />
                
              <div>HISTORICO DE RUTAS</div>
            </li>  
          </NavLink>
        </ul>

      

      </div>
    </div>
  );
};

export default MonitorUI;
