import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {message, Modal} from 'antd';
import {
  getAllZones,
  selectZones,
  getAllLevels,
  removeMonitorZone,
  activeZone,
} from '../../redux/slices/zones';
import { getTipificacion, getAllUser,getInfoUser, getListTareasActividad,getAreaSoporte,getUserTickets,getAllRestaurante,getUserGZ} from '../../firebase/queryProvider';
import moment from 'moment';
import {selectUser} from '../../redux/slices/user';
import TareasReporteUI from './components/TareasReporteUI';


const TareasReporte = () => {
  const [loading, handleLoading] = useState(true);
  const [editorVisible, handleEditorVisible] = useState(false);
  const [dataDepartamento, setDataDepartamento] = useState([]);
  const [dataTipificacion, setDataTipificacion] = useState([]);
  const [dataPersonal, setDataPersonal] = useState([]);
  const [dataRestaurante, setDataRestaurante] = useState([]);
  const [dataGerenteZona, setDataGerenteZona] = useState([]);
  const [restaurantes, setRestaurantes] = useState([]);
  const [users, setUsers] = useState([]);
  const [datagrafica, setDataGrafica] = useState([]);

  const [resultAreaSoporte, setResultAreaSoporte] = useState([]);
  const [resultTipificacion, setResultTipificacion] = useState([]);
  const [resultUserTickets, setResultUserTickets] = useState([]);
  const [resultRestaurante, setResultRestaurante] = useState([]);
  const [resultUserGZ, setResultUserGZ] = useState([]);

  const user =  useSelector(selectUser);

  const dispatch = useDispatch();
  const uniqueByKey = (array, key) => {
    return [...new Map(array.map((x) => [x[key], x])).values()];
  }
  
  const getFirstDayOfMonth = (year, month)  => {
    return new Date(year, month, 1);
  }


  useEffect(() => {

   

    const initialFetch = async () => {
    
      const resultAreaSoporte = await getAreaSoporte();
      if(resultAreaSoporte.ok){
        setResultAreaSoporte(resultAreaSoporte.listadoAreaSoporte)
      }
      const resultTipificacion = await getTipificacion();
      if(resultTipificacion.ok){
        setResultTipificacion(resultTipificacion.listadoTipificacion)
      }

      const resultUserTickets = await getUserTickets();
      if(resultUserTickets.ok){
        setResultUserTickets(resultUserTickets.listadoUser)
      }

      const resultUserGZ = await getUserGZ();
      if(resultUserGZ.ok){
        setResultUserGZ(resultUserGZ.listadoUser)
      }
    

      const resultRestaurante = await getAllRestaurante();
      if(resultRestaurante.ok){
        setResultRestaurante(resultRestaurante.listadoUser)
      }

      const resultUsers = await getAllUser();
      if(resultUsers.ok){
        setUsers(resultUsers.listadoUser)
      }

      
      
    };

    initialFetch();
  }, [dispatch]);

  useEffect(() => {
      let today = new Date();
      let firstToday  = new Date();

      let fechaNueva =  new Date(moment(today).add(-6, 'day'));
      console.log("FirstToday",fechaNueva)

      var fechaInicio = new Date(fechaNueva.setHours(0,0,0,0));
      var fechaFinal = new Date(today.setHours(24,0,0,0));

      rellenarData(fechaInicio,fechaFinal)
  }, [users])

  const rellenarData = async (fechaInicio,fechaFinal) =>{
    var dataGra = []
    dataGra.push(["x", "Abiertos", "Finalizados"])
   
    var dataUser = ''
    if(user != undefined){
      dataUser = await getInfoUser(user.idUser)
    }

    const resultInicio = await getListTareasActividad(fechaInicio,fechaFinal);

    var dataRestaurantes = []
    var dataGerenteZona= []
    
    //console.log("result",result)
    if(resultInicio.ok){
      var listadoTicketInicio = resultInicio.listadoTareas
      console.log("listadoTicketInicio",listadoTicketInicio)


      var fechasUnicasInicio = uniqueByKey(listadoTicketInicio, 'fechaCreadoGrafica')
      var fechasUnicasFin = uniqueByKey(listadoTicketInicio, 'fechaFinalizadoGrafica')

      var fechasTodas = []
      var fechasOnlyGrafica = []
    
     

      var z = 0;
      while (z < fechasUnicasInicio.length) {
        fechasTodas.push(fechasUnicasInicio[z])
        ++z;
      }

    
      var j = 0;
      while (j < fechasTodas.length) {
        let cantidadCreados = listadoTicketInicio.filter( request => (request.fechaCreadoGrafica == fechasTodas[j].fechaCreadoGrafica));
        let totalTares = cantidadCreados.reduce((total, item) => total + item.tareasTotales, 0);

        let totalCompletadas = cantidadCreados.reduce((total, item) => total + item.tareasCompletadas, 0);
        totalCompletadas = Math.min(totalCompletadas, totalTares);
        let totalRetrasadas = cantidadCreados.reduce((total, item) => total + item.tareasRetrasadas, 0);

        let totalAbiertos = totalTares - totalCompletadas - totalRetrasadas

        dataGra.push([fechasTodas[j].fechaCreadoGrafica, totalAbiertos, totalCompletadas])
        ++j;
      }

  

      //estructura tabla restaurante
      var w = 0;
      while (w < resultRestaurante.length) {
        var item = resultRestaurante[w]
        
       
        var totalTicket = listadoTicketInicio.filter( request => (request.tienda == "/Tiendas/"+item.id));

        let totalTares = totalTicket.reduce((total, item) => total + item.tareasTotales, 0);
        let totalCompletadas = totalTicket.reduce((total, item) => total + item.tareasCompletadas, 0);
        totalCompletadas = Math.min(totalCompletadas, totalTares);
        let totalRetrasadas = totalTicket.reduce((total, item) => total + item.tareasRetrasadas, 0);
        let totalAbiertos = totalTares - totalCompletadas - totalRetrasadas
        totalAbiertos = Math.max(0, totalAbiertos);


        var cumplimiento = 0;

  
        if (totalTares === 0) {
          cumplimiento = 100;
        } else {
          cumplimiento = (totalCompletadas/ totalTares) * 100;

          if (cumplimiento > 100) {
            cumplimiento = 100;
          }
        }

        dataRestaurantes.push({
          nombre:item.nombre,
          abiertos: totalAbiertos,
          atrasados: totalRetrasadas,
          finalizados:totalCompletadas,
          totalTicket:totalTares,
          cumplimiento: cumplimiento.toFixed(2)
        })

        ++w;
      }
      
     //estructura tabla gerente zona
     var xw = 0;

     while (xw < resultUserGZ.length) {
       var item = resultUserGZ[xw]

       var totalTicket = listadoTicketInicio.filter(request => item.tiendaList.includes(request.tienda.substring(1)));
       let totalTares = totalTicket.reduce((total, item) => total + item.tareasTotales, 0);
       let totalCompletadas = totalTicket.reduce((total, item) => total + item.tareasCompletadas, 0);
       totalCompletadas = Math.min(totalCompletadas, totalTares);
       let totalRetrasadas = totalTicket.reduce((total, item) => total + item.tareasRetrasadas, 0);
       let totalAbiertos = totalTares - totalCompletadas - totalRetrasadas
       totalAbiertos = Math.max(0, totalAbiertos);

       var cumplimiento = 0;

  
       if (totalTares === 0) {
         cumplimiento = 100;
       } else {
         cumplimiento = (totalCompletadas/ totalTares) * 100;

         if (cumplimiento > 100) {
           cumplimiento = 100;
         }
       }

       dataGerenteZona.push({
         nombre:item.nombre,
         abiertos: totalAbiertos,
         atrasados: totalRetrasadas,
         finalizados:totalCompletadas,
         totalTicket:totalTares,
         cumplimiento: cumplimiento.toFixed(2)
       })


      ++xw;
     }
      
      
    }

    console.log("dataGra!!",dataGra)

    if(dataGra.length == 1){
      dataGra.push(["No Data", 0, 0])

      console.log("entro ======>")
    }


    setDataGrafica(dataGra)
    setDataDepartamento(dataDepartamento)
    setDataTipificacion(dataTipificacion)
    setDataPersonal(dataPersonal)
    setDataRestaurante(dataRestaurantes)
    setDataGerenteZona(dataGerenteZona)
    
    console.log("dataRestaurantes",dataRestaurantes)
    handleLoading(false);
  }

  const buscarTickets = async (tipo,startDate,endDate) => {
    handleLoading(true);
    var fechaInicio = ""
    var fechaFinal = ""

    if(tipo == "fecha"){
    
      let todayStartDate = new Date(moment(startDate,"DD/MM/YYYY"));
      let todayEndDate = new Date(moment(endDate,"DD/MM/YYYY"));

      console.log("startDate",todayStartDate)
      console.log("endDate",todayEndDate)

      var fechaInicio = new Date(todayStartDate.setHours(0,0,0,0));
      var fechaFinal = new Date(todayEndDate.setHours(24,0,0,0));

    }else if(tipo == "Hoy"){
      let today = new Date();
      var fechaInicio = new Date(today.setHours(0,0,0,0));
      var fechaFinal = new Date(today.setHours(24,0,0,0));
    }else if(tipo == "Ayer"){
      let today = new Date();
      let fechaNueva =  moment(today).add(-1, 'day');
      let fechaF = new Date(fechaNueva)
      var fechaInicio = new Date(fechaF.setHours(0,0,0,0));
      var fechaFinal = new Date(fechaF.setHours(24,0,0,0));
    }else if(tipo == "Últimos 7 días"){
      let today = new Date();
      let fechaNueva =  moment(today).add(-6, 'day');
      let fechaF = new Date(fechaNueva)
      var fechaInicio = new Date(fechaF.setHours(0,0,0,0));
      var fechaFinal = new Date(today.setHours(24,0,0,0));
    }else if(tipo == "Últimos 30 días"){
      let today = new Date();
      let fechaNueva =  moment(today).add(-29, 'day');
      let fechaF = new Date(fechaNueva)
      var fechaInicio = new Date(fechaF.setHours(0,0,0,0));
      var fechaFinal = new Date(today.setHours(24,0,0,0));
    }

    

    /*let today = new Date();
    let FirstToday  = getFirstDayOfMonth(
      today.getFullYear(),
      today.getMonth(),
    );

    var fechaInicio = new Date(FirstToday.setHours(0,0,0,0));
    var fechaFinal = new Date(today.setHours(24,0,0,0));*/

    rellenarData(fechaInicio,fechaFinal)

  };



  return (
    <TareasReporteUI
      loading={loading}
      dataDepartamento={dataDepartamento}
      dataTipificacion={dataTipificacion}
      dataPersonal={dataPersonal}
      dataRestaurante={dataRestaurante}
      dataGerenteZona={dataGerenteZona}
      users={users}
      datagrafica={datagrafica}
      buscarTickets={buscarTickets}
    />
  );
}

export default TareasReporte;
