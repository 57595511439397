import {useRef,useState,useEffect} from 'react';
import {message,Button, Table, Spin, Modal, Col, Row,Form,Input,Select,TreeSelect,DatePicker,Checkbox,TimePicker} from 'antd';
import Header from '../../../components/HeaderList';
import {ReactComponent as Edit} from '../../../assets/icons/edit.svg';
import styles from '../styles/TareaUI.module.css';
import {getColumnSearchProps} from '../../../utils/tables';
import {ReactComponent as Trash} from '../../../assets/icons/trash.svg';
import dayjs from 'dayjs';
import Moment from 'moment';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { updateDocument,createDocument,createReferencia} from '../../../firebase/queryProvider';
import { async } from '@firebase/util';
import { deleteField } from "firebase/firestore";

dayjs.extend(customParseFormat);

const {Option} = Select;
const {TreeNode} = TreeSelect;
const { TextArea } = Input;

const TareasUI = props => {  
  const [form] = Form.useForm();
  const [sending, handleSending] = useState(false);
  const [repetir, setRepetir] = useState([{key:1,value:"Una vez"},{key:2,value:"Diario"},{key:3,value:"Semanal"},{key:4,value:"Mensual"}]);
  const [repetirSelect, setRepetirSelect] = useState(1);
  const [disabledHasta, setDisabledHasta] = useState(false);
  const [adjuntar, setAdjuntar] = useState(false);
  const [caducidad, setCaducidad] = useState(false);
  const [horaInicio, setHoraInicio] = useState('');
  const [horaFin, setHoraFin] = useState('');


  const [dias, setDias] = useState([
    {key:"Lun",value:"Lunes"},
    {key:"Mar",value:"Martes"},
    {key:"Mie",value:"Miercoles"},
    {key:"Jue",value:"Jueves"},
    {key:"Vie",value:"Viernes"},
    {key:"Sab",value:"Sabado"},
    {key:"Dom",value:"Domingo"},
  ]);

  const [diasMes, setDiasMes] = useState([]);

  const [mostrarSemana, setMostrarSemana] = useState(false);
  const [mostrarMes, setMostrarMes] = useState(false);

  
  
  const {
    loading,
    tareas,
    item,
    isModalDetails,
    cancelModalDetails,
    showDetails,
    categorias,
    newTarea,
    confirmDelete,
    sucursales,
    actualizarData,
    agregarData
  } = props;

  useEffect(() => {
    const initialFetch = async () => {
      if (item) {
        const newValues = {};
       
        if(item.nuevo == undefined){
        
          const categoriaID = item.categoria.path.split('/');
          newValues.titulo = item.titulo
          newValues.categoria = categoriaID[1]
          newValues.notaAdicional = item.notas

          //recorer tienda
          var tiendasList = []
          for (let i = 0; i < item.tiendas.length; i++) {
            tiendasList.push(item.tiendas[i].id)
          }

          newValues.sucursalMultiple = tiendasList
          newValues.repetir = item.tipoTiempo
          newValues.fechaInicio = Moment(item.fechaInicio.toDate())
          newValues.fechaFin = Moment(item.fechaFin.toDate())
          var horaStart = convertirHoraMilitar(item.horaInicio)
          var horaEnd = convertirHoraMilitar(item.horaFin)
         
          newValues.horaInicio = Moment("2020-06-09T"+horaStart)
          newValues.horaFin = Moment("2020-06-09T"+horaEnd)

          setHoraInicio(horaStart)
          setHoraFin(horaEnd)
          setAdjuntar(item.adjunto)
          setCaducidad(item.caducidad)

          console.log("====",item.tipoTiempo)

          if(item.tipoTiempo == 3){
            setMostrarSemana(true)
            setMostrarMes(false)

            var diasSend = []
            for (let i = 0; i < item.semana.length; i++) {
              diasSend.push(item.semana[i].diaText)
            }

            newValues.dias = diasSend

          }else if(item.tipoTiempo == 4){
            setMostrarSemana(false)
            setMostrarMes(true)

            var diasSend = []
            for (let i = 0; i < item.diasCalendario.length; i++) {
              diasSend.push(item.diasCalendario[i].dia)
            }

            newValues.diasMes = diasSend
          }else{
            setMostrarSemana(false)
            setMostrarMes(false)
          }

        }else{
          newValues.fechaInicio = ""
          newValues.fechaFin = ""
          newValues.repetir = 1 

          setMostrarSemana(false)
          setMostrarMes(false)
        }
        
        /*var categoria = ""
        var itemCategoria = categorias.find((data) => data.id == item.categoria)
        console.log("itemCategoria",itemCategoria)
        if(typeof itemCategoria !== 'undefined'){
          categoria =  itemCategoria.nombre
        }*/

        var diasMes = []
        for (let i = 1; i < 32; i++) {
          diasMes.push({key:i,value:i})
        }

        setDiasMes(diasMes)

        
        form.setFieldsValue(newValues);
      }
    };
    initialFetch();
  }, [item]);

  const convertirHoraMilitar = (hora) => {
    var horas = hora.substring(0,2)
    var minutos = hora.substring(2,4)
    return horas+":"+minutos
  }

  const dateFormat = 'DD-MM-YYYY';


  const columns = [
    {
      title: 'Titulo',
      dataIndex: 'titulo',
      align: 'left',
      ...getColumnSearchProps('titulo'),
    },
    {
      title: 'Categoría',
      dataIndex: 'categoriaName',
      align: 'left',
      ...getColumnSearchProps('categoriaName'),
    },
    {
      title: 'Horario',
      dataIndex: 'horario',
      align: 'left',
      ...getColumnSearchProps('horario'),
    },
    {
      title: '',
      dataIndex: 'id',
      width: 140,
      render: (id, record) => (
        <div className={styles.actions}>
          <div className={styles.edit} onClick={() => showDetails(record)}>
            <Edit />
          </div>
          <div className={styles.divider} />
          <div className={styles.delete} onClick={() => confirmDelete(id)}>
            <Trash />
          </div>
        </div>
      )
    },
    
  ];


  const save = async () => {
    
    try {
      //handleSending(true);
      const values = await form.validateFields();
      console.log("values",values)
      //handleSending(true);

      const categoriaRef = await createReferencia('/Categorias/'+values.categoria)
      var tiendas = []

      for( var z = 0; z < values.sucursalMultiple.length ; z++){
        const tiendaRef = await createReferencia('/Tiendas/'+ values.sucursalMultiple[z])
        tiendas.push(tiendaRef)
      }

      
      
      const itemSend = {
        activo: true,
        adjunto: adjuntar,
        caducidad: caducidad,
        categoria: categoriaRef,
        fechaActualizado:  Moment(new Date(), 'DD/MM/YYYY').toDate(),
        fechaFin: Moment(values.fechaFin, 'DD/MM/YYYY').toDate(),
        fechaInicio:  Moment(values.fechaInicio, 'DD/MM/YYYY').toDate(),
        horaFin: horaFin.replace(':', ''),
        horaInicio: horaInicio.replace(':', ''),
        notas: values.notaAdicional == undefined ? "" : values.notaAdicional,
        tiendas:tiendas,
        tipoTiempo: values.repetir,
        titulo: values.titulo
      };

      console.log("values",item)


      if(values.repetir == 1 || values.repetir == 2){
        //solo si es una actualizacion
        if(item.nuevo == undefined){
            itemSend.semana = deleteField()
            itemSend.diasCalendario = deleteField()
        }
      }

      if(values.repetir == 3){

          var buscarSemana = values.dias
          var semana = []
          console.log( values.dias.length,values.dias)
          for( var j = 0; j < values.dias.length ; j++){
            semana.push({select:true,diaText: values.dias[j]})
          }

          itemSend.semana = semana
          //solo si es una actualizacion
          if(item.nuevo == undefined){
            itemSend.diasCalendario = deleteField()
          }
      }

      if(values.repetir == 4){
        
        
        var buscarCalendario = []
        for( var u = 0; u < values.diasMes.length ; u++){
          buscarCalendario.push({select:true,dia: values.diasMes[u]})
        }
        itemSend.diasCalendario = buscarCalendario

        //solo si es una actualizacion
        if(item.nuevo == undefined){
          itemSend.semana = deleteField()
        }
      }

      console.log("====",itemSend)

      if(item.nuevo == undefined){
        itemSend.id = item.id
        
      

        const response =  await updateDocument(itemSend,`/Tareas/${itemSend.id}`)
        
        if (response.ok === true) {
          message.success('Cambios realizados con éxito');
          
          var itemCategoria = categorias.find((item) => item.id == values.categoria)
          if(typeof itemCategoria !== 'undefined'){
            itemSend.categoriaName =  itemCategoria.nombre
          }else{
            itemSend.categoriaName =  ""
          }

          itemSend.fechaInicio = Moment(values.fechaInicio, 'DD/MM/YYYY')
          itemSend.fechaFin = Moment(values.fechaFin, 'DD/MM/YYYY')

          var horarioStart = itemSend.horaInicio
          var horaEnd = itemSend.horaFin
          var horario = horarioStart.slice(0, 2)+":"+horarioStart.slice(2, 4)+" - "+horaEnd.slice(0, 2)+":"+horaEnd.slice(2, 4)
          itemSend.horario = horario
          


          handleSending(false)
          actualizarData(itemSend)
          cancelModalDetails()
          form.resetFields();
        } else {
          message.error('¡Hubo un problema! Inténtalo de nuevo');
        }

      }else{
        itemSend.fechaCreado = Moment(new Date(), 'DD/MM/YYYY').toDate();
      
        const response =  await createDocument(`Tareas`,itemSend)
        console.log("response",response)
        var idTarea = response.info.id

       
        itemSend.id = idTarea
        itemSend.fechaInicio = Moment(values.fechaInicio, 'DD/MM/YYYY')
        itemSend.fechaFin = Moment(values.fechaFin, 'DD/MM/YYYY')
        
        var itemCategoria = categorias.find((item) => item.id == values.categoria)
        if(typeof itemCategoria !== 'undefined'){
          itemSend.categoriaName =  itemCategoria.nombre
        }else{
          itemSend.categoriaName =  ""
        }

        var horarioStart = itemSend.horaInicio
        var horaEnd = itemSend.horaFin
        var horario = horarioStart.slice(0, 2)+":"+horarioStart.slice(2, 4)+" - "+horaEnd.slice(0, 2)+":"+horaEnd.slice(2, 4)
        itemSend.horario = horario



        console.log("itemSend",itemSend)
        
        message.success('Tarea creada');
        handleSending(false)
        agregarData(itemSend)
        cancelModalDetails()
        form.resetFields();
      }

      /*const locality = values.locality.split('-');
      const info = {
        level: values.level,
        description: values.name,
        name: values.name,
        locationId: values.location,
        localityId: parseInt(locality[1], 10),
        delivery_fee: parseFloat(values.delivery_fee),
      };
      if (zone) {
        info.id = zone.id;
      }
      if (zone) {
        const response = await dispatch(updateZone(info));
        if (response.status === 'success') {
          message.success('Cambios realizados con éxito');
        } else {
          message.error('¡Hubo un problema! Inténtalo de nuevo');
        }
      } else {
        const response = await dispatch(createZone(info));
        if (response.status === 'success') {
          message.success('Cambios realizados con éxito');
          handleZone(response.zone);
        } else {
          message.error('¡Hubo un problema! Inténtalo de nuevo');
        }
      }
      handleSending(false);*/
    } catch (e) {
      console.log("eee",e)
    }
  };

  const onChange = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
    setAdjuntar(e.target.checked)
  };

  const onChangeHoraInicio = (time: Dayjs, timeString: string) => {
    console.log(time, timeString);
    setHoraInicio(timeString)
  };

  const onChangeHoraFin = (time: Dayjs, timeString: string) => {
    console.log(time, timeString);
    setHoraFin(timeString)
  };

  const handleChangeRepetir = async(value: string) => {
    setRepetirSelect(value)
    if(value == "1"){
      
      const values = await form.validateFields();
      const newValues = {};
      newValues.fechaFin = values.fechaInicio
      form.setFieldsValue(newValues);
    }

    if(value == "3"){
      setMostrarSemana(true)
      setMostrarMes(false)
    }else if(value == "4"){
      setMostrarSemana(false)
      setMostrarMes(true)
    }else{
      setMostrarSemana(false)
      setMostrarMes(false)
    }

  };

  const onChangeCaducidad = (e: CheckboxChangeEvent) => {
    //console.log(`checked = ${e.target.checked}`);
    console.log("repetirSelect",repetirSelect)
    setCaducidad(e.target.checked)
    if(e.target.checked){
      if(repetirSelect == "1"){
        setDisabledHasta(true)
      }else{
        const newValues = {};
        newValues.fechaFin = Moment("31-12-2999T00:00:00+0000",dateFormat)
        form.setFieldsValue(newValues);
        setDisabledHasta(true)
      }
    }else{
      if(repetirSelect == "1"){
        setDisabledHasta(false)
      }else{
        const newValues = {};
        newValues.fechaFin = ""
        form.setFieldsValue(newValues);
        setDisabledHasta(false)
      }
    }

  };

  const onChangeFechaDesde: DatePickerProps['onChange'] = async(date, dateString) => {
    const values = await form.getFieldsValue();
    
    if(values.repetir == 1){
      const newValues = {};
      newValues.fechaInicio = Moment(dateString+"T00:00:00+0000",dateFormat)
      newValues.fechaFin = Moment(dateString+"T00:00:00+0000",dateFormat)
      form.setFieldsValue(newValues);
    }else{
      const newValues = {};
      newValues.fechaInicio = Moment(dateString+"T00:00:00+0000",dateFormat)
      form.setFieldsValue(newValues);
    }

  };

  const onChangeFechaHasta: DatePickerProps['onChange'] = async(date, dateString) => {
    const values = await form.getFieldsValue();
    if(values.repetir == 1){
      const newValues = {};
      newValues.fechaInicio = Moment(dateString+"T00:00:00+0000",dateFormat)
      newValues.fechaFin = Moment(dateString+"T00:00:00+0000",dateFormat)
      form.setFieldsValue(newValues);
    }else{
      const newValues = {};
      newValues.fechaFin = Moment(dateString+"T00:00:00+0000",dateFormat)
      form.setFieldsValue(newValues);
    }

  };

  const disabledDate: DatePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().subtract(1, 'day');
    
    //dayjs().endOf('day')
  };

  
  return (
    <div className={styles.view}>
      <Header
        title="Listado de Tareas"
        actions={
          <Button
            onClick={() => {
              form.resetFields();
              newTarea()
              setCaducidad(false)
              setAdjuntar(false)
            }}
            type="primary"
            size="large"
            className="main-buttonHeader">
            AGREGAR TAREA
          </Button>
        }
      />
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Table
            columns={columns}
            dataSource={tareas}
            rowKey="id"
          />
        </div>
      )}


      {
          item != null ?
          <Modal 
          className='primary-modal'
          title="Tarea"
          style={{ top: 20 }}
          width={700}
          visible={isModalDetails}
          onCancel={() => cancelModalDetails()}
          footer = { <Row>

            <Col span={6} style={{textAlign:"left"}}>
              <Button 
                    // disabled={requests.isSaving} 
                    className={styles.btCerrar}
                    type="primary" 
                    shape="round" 
                    style={{ width: "90%" }} 
                    onClick={() => { 
                      cancelModalDetails()
                    }}
                    >
                  Cerrar
              </Button>
                
              
            </Col>

            <Col span={12} style={{textAlign:"right"}}>
              
            </Col>

            <Col span={6} style={{textAlign:"right"}}>
            

              <Button
                  loading={sending}
                  onClick={() => {
                    save()
                  }}
                  type="primary" 
                  shape="round" 
                  htmlType="submit"
                  style={{ width: "90%" }}
                  >
                  Guardar
              </Button>
            </Col>
          </Row> }>

          <Form
            name="branch-editor"
            autoComplete="off"
            form={form}
            colon={false}
            requiredMark={false}
            onFinish={save}
          >

          <Row>
            <Col span={24}>
              <Form.Item
                label={
                  <span className={styles.label}>
                    Titulo de la tarea
                  </span>
                }
                name="titulo"
                className={styles.itemColumn}
                rules={[{required: true, message: 'Ingresar Titulo'}]}
                
              >
                <Input size="large" className={styles.input} />
              </Form.Item>
            </Col>
          </Row>
        
          <Row>
            <Col span={24}>
              <Form.Item
                label={
                  <span className={styles.label}>
                    Categoría
                  </span>
                }
                name="categoria"
                className={styles.itemColumn}
                rules={[{required: true, message: 'Ingresar Categoría'}]}
              >
                <Select size="large" className={styles.input}>
                  {categorias.map(branch => (
                    <Option key={branch.id} value={branch.id}>{branch.nombre}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <div className={styles.check}>
            
            <Checkbox  checked={adjuntar} onChange={onChange}>Permitir adjuntar archivos</Checkbox>
          </div>
        

          <Row>
            <Col span={24}>
              <Form.Item
                label={
                  <span className={styles.label}>
                  Nota adicional
                  </span>
                }
                name="notaAdicional"
                className={styles.itemColumn}
              >
                  <TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>

            <Col span={24}>
              <Form.Item
                label={
                  <span className={styles.label}>
                  Tiendas
                  </span>
                }
                name="sucursalMultiple"
                className={styles.itemColumn}
              >
                <Select size="large" className={styles.input} mode="multiple">
                    {sucursales.map(branch => (
                      <Option key={branch.id} value={branch.id}>{branch.nombre}</Option>
                    ))}
                  </Select>

              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label={
                  <span className={styles.label}>
                    Repetir
                  </span>
                }
                name="repetir"
                className={styles.itemColumn}
              >
                <Select size="large" className={styles.input}  onChange={handleChangeRepetir} >
                    {repetir.map(branch => (
                      <Option key={branch.key} value={branch.key} >{branch.value}</Option>
                    ))}
                  </Select>

              </Form.Item>
            </Col>

            <div className={styles.check}>
              <Checkbox checked={caducidad} onChange={onChangeCaducidad}>Sin caducidad</Checkbox>
            </div>

            <Col span={25}>
              <Form.Item
                label={
                  <span className={styles.label}>
                    Fecha
                  </span>
                }
                name="fecha"
                className={styles.itemColumn}
              >
                <Row>
                  <Col span={12}> 
                  <Form.Item
                    name="fechaInicio"
                    label={
                      <span className={styles.labelFecha}>
                        Desde
                      </span>
                    }
                    className={styles.itemColumn}
                  >
                    <DatePicker format={dateFormat}  disabledDate={disabledDate} className={styles.input} onChange={onChangeFechaDesde} />
                  </Form.Item>
                  </Col>
                  <Col span={12}> 
                  <Form.Item
                    name="fechaFin"
                    label={
                      <span className={styles.labelFecha}>
                        Hasta
                      </span>
                    }
                    className={styles.itemColumn}
                  >
                    <DatePicker disabled={disabledHasta} format={dateFormat}  disabledDate={disabledDate} className={styles.input} onChange={onChangeFechaHasta} />
                  </Form.Item>
                 
                  </Col>
                </Row>
               

              </Form.Item>
            </Col>

            {
              mostrarSemana ?
              <Col span={24}>
                <Form.Item
                  label={
                    <span className={styles.label}>
                      Seleccionar días
                    </span>
                  }
                  name="dias"
                  className={styles.itemColumn}
                >
                  <Select size="large" className={styles.input}  mode="multiple">
                      {dias.map(branch => (
                        <Option key={branch.key} value={branch.key}>{branch.value}</Option>
                      ))}
                    </Select>

                </Form.Item>
              </Col>
              : null
            }

            {
              mostrarMes ?
              <Col span={24}>
                <Form.Item
                  label={
                    <span className={styles.label}>
                      Seleccionar días<br></br>del mes
                    </span>
                  }
                  name="diasMes"
                  className={styles.itemColumn}
                >
                  <Select size="large" className={styles.input}  mode="multiple">
                      {diasMes.map(branch => (
                        <Option key={branch.key} value={branch.key}>{branch.value}</Option>
                      ))}
                    </Select>

                </Form.Item>
              </Col>
              : null
            }

           

            <Col span={25}>
              <Form.Item
                label={
                  <span className={styles.label}>
                    Hora
                  </span>
                }
                name="fecha"
                className={styles.itemColumn}
              >
                <Row>
                  <Col span={12}>
                    <Form.Item
                      name="horaInicio"
                      label={
                        <span className={styles.labelFecha}>
                          Desde
                        </span>
                      }
                      className={styles.itemColumn}
                    >
                      <TimePicker format={'HH:mm'} onChange={onChangeHoraInicio} />
                    </Form.Item>

                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="horaFin"
                      label={
                        <span className={styles.labelFecha}>
                          Hasta
                        </span>
                      }
                      className={styles.itemColumn}
                    >
                      <TimePicker format={'HH:mm'} onChange={onChangeHoraFin} />
                    </Form.Item>

                  </Col>
                </Row>
               

              </Form.Item>
            </Col>
            
    
          </Form>

          
          </Modal>
          : null
        }
    </div>
  );
};

export default TareasUI;
