import { FirebaseAuth,FirebaseDB } from './config';
import { collection, query, where, getDocs, doc, getDoc, setDoc,limit,orderBy,updateDoc} from "firebase/firestore";
import moment from 'moment';
import { getDistance } from 'geolib';

export const getListBitacora = async(fechaInicio,fechaFin) => {

    const resultUser = await getAllUser();
    const resultRestaurantes = await getAllRestaurante();

    if(resultUser.ok){
        var listadoUser = resultUser.listadoUser
        var listadoRestaurante = resultRestaurantes.listadoUser

      
      //console.log("listadoUser",listadoUser)
      try {
        var listadoBitacora = []
        const collectionRef = collection( FirebaseDB, `Bitacora`);
        var queryMessages = query(collectionRef, 
            where("activo", "==", true),
            orderBy("fechaCreado","desc")
        );

        if(fechaInicio != "" && fechaFin != ""){

            if(fechaInicio != ""){
                var fechaI = new Date(moment(fechaInicio,'DD/MM/YYYY'))
                var fechaIn = new Date(fechaI.setHours(0,0,0,0));

                var fechaF = new Date(moment(fechaFin,'DD/MM/YYYY'))
                var fechaFi = new Date(fechaF.setHours(24,0,0,0));

                console.log("fechaIn",fechaIn)
                console.log("fechaFi",fechaFi)
                
                
                queryMessages = query(collectionRef, 
                    where("activo", "==", true),
                    where("fechaCreado",">=", fechaIn),
                    where("fechaCreado","<=", fechaFi),
                    where("activo", "==", true),
                    orderBy("fechaCreado","desc")
                );

            }


        }
        
        const querySnapshot = await getDocs(queryMessages);
    
        if(querySnapshot.size == 0){
            return {
                ok: true,
                listadoBitacora: []
            }
        }

        querySnapshot.forEach( async (documment) => {
            let data = documment.data();
            data.id = documment.id
            var fechaCreado = data.fechaCreado
            var fechaActualizado = data.fechaActualizado


            data.fechaCreado = fechaCreado == null ? "" : moment(fechaCreado.toDate()).format("DD/MM/Y - hh:mm A")
            data.fechaActualizado = fechaActualizado == null ? "" : moment(fechaActualizado.toDate()).format("DD/MM/Y - hh:mm A")

         
            var itemUser = listadoUser.find((item) => item.id == data.idUser)
            if(typeof itemUser !== 'undefined'){
                data.de =  itemUser.nombre + " " + itemUser.apellido
            }else{
                data.de =  ""
            }

            var itemRestaurante = listadoRestaurante.find((item) => item.id == data.tienda)
            if(typeof itemRestaurante !== 'undefined'){
                data.restaurante =  itemRestaurante.nombre
            }else{
                data.restaurante =  ""
            }

            listadoBitacora.push(data)
        });

        
        return {
            ok: true,
            listadoBitacora:listadoBitacora
        }

        } catch (error) {
            console.log("listadoBitacora",error)

            return { ok: false, errorMessage: error.message }

        }
    }else{
        return { ok: false, errorMessage: "Sin Resultados"}
    }
    

}


export const getListLogs = async(fechaInicio,fechaFin) => {

    const resultUser = await getAllUser();
    const resultRestaurantes = await getAllRestaurante();

    if(resultUser.ok){
        var listadoUser = resultUser.listadoUser
        var listadoRestaurante = resultRestaurantes.listadoUser

      
      //console.log("listadoUser",listadoUser)
      try {
        var listadoLogs = []
        const collectionRef = collection( FirebaseDB, `LogActividades`);
        var fechaI = new Date(moment(fechaInicio,'DD/MM/YYYY'))
        var fechaIn = new Date(fechaI.setHours(0,0,0,0));

        var fechaF = new Date(moment(fechaFin,'DD/MM/YYYY'))
        var fechaFi = new Date(fechaF.setHours(24,0,0,0));


        var queryMessages = query(collectionRef, 
            where("fechaCreado",">=", fechaIn),
            where("fechaCreado","<=", fechaFi),
            orderBy("fechaCreado","desc")
        );
        
        const querySnapshot = await getDocs(queryMessages)
    
        if(querySnapshot.size == 0){
            return {
                ok: true,
                listadoLogs: []
            }
        }

        querySnapshot.forEach( async (documment) => {
            let data = documment.data();
            data.id = documment.id
            var fechaCreado = data.fechaCreado
            
            data.fechaCreado = fechaCreado == null ? "" : moment(fechaCreado.toDate()).format("DD/MM/Y - hh:mm A")

         
            var itemUser = listadoUser.find((item) => item.id == data.idUsuario)
            if(typeof itemUser !== 'undefined'){
                data.de =  itemUser.nombre + " " + itemUser.apellido
            }else{
                data.de =  ""
            }

            var itemRestaurante = listadoRestaurante.find((item) => item.id == data.idSucursal)
            if(typeof itemRestaurante !== 'undefined'){
                data.restaurante =  itemRestaurante.nombre
            }else{
                data.restaurante =  ""
            }


            listadoLogs.push(data)
        });

        
        return {
            ok: true,
            listadoLogs:listadoLogs
        }

        } catch (error) {
            console.log("listadoBitacora",error)

            return { ok: false, errorMessage: error.message }

        }
    }else{
        return { ok: false, errorMessage: "Sin Resultados"}
    }
    

}

export const reasignarCorrelativo = async() => {
    const collectionRef = collection( FirebaseDB, `Tickets`);
    const queryMessages = query(collectionRef, 
        where("activo", "==", true),
        orderBy("fechaCreado","asc")
    );
    const querySnapshot = await getDocs(queryMessages);
    var total = 1
    var fechaActual = ""
    var contador = 0

    querySnapshot.forEach( async (documment) => {
        let data = documment.data();
        var id = documment.id
        var fechaCreado = data.fechaCreado
        var fechaFormato = moment(fechaCreado.toDate()).format("DDMMYY")

        if(fechaActual != fechaFormato){
            fechaActual = fechaFormato
            total = 0
        }

        total = parseInt(total) + 1
        if(total < 10){
            total = "00"+total
        }else if(total < 100){
            total = "0"+total
        }
        var correlativo = fechaActual + "-" + total

        //if(contador == 0){
            contador = parseInt(contador) + 1
            console.log("correlativo",correlativo,id,fechaActual)

            const ticketsRef = doc(FirebaseDB, "Tickets", id);

            await updateDoc(ticketsRef, {
                id: correlativo
            });
        //}

        
    });
}


export const getListTickets = async(user,fechaInicio,fechaFin) => {

    const resultUser = await getAllUser();
    const resultSucursales = await getAllRestaurante();
    const resultCategorias = await getAreaSoporte();
    var dataUser = ''
    if(user != undefined){
       dataUser = await getInfoUser(user.idUser)
    }

    

    if(resultUser.ok){
        var listadoUser = resultUser.listadoUser
        var listadoSucursales = resultSucursales.listadoUser
        var listadoAreaSoporte = resultCategorias.listadoAreaSoporte

      
      console.log("listadoUser",listadoUser.categoriaST)
      try {
        var listadoTicket = []
        const collectionRef = collection( FirebaseDB, `Tickets`);

        var queryMessages = query(collectionRef, 
            where("activo", "==", true),
            orderBy("fechaCreado","desc")
        );

        if(fechaInicio != "" && fechaFin != ""){

            if(fechaInicio != ""){
                var fechaI = new Date(moment(fechaInicio,'DD/MM/YYYY'))
                var fechaIn = new Date(fechaI.setHours(0,0,0,0));

                var fechaF = new Date(moment(fechaFin,'DD/MM/YYYY'))
                var fechaFi = new Date(fechaF.setHours(24,0,0,0));

                console.log("fechaIn",fechaIn)
                console.log("fechaFi",fechaFi)
                
                
                queryMessages = query(collectionRef, 
                    where("activo", "==", true),
                    where("fechaCreado",">=", fechaIn),
                    where("fechaCreado","<=", fechaFi),
                    where("activo", "==", true),
                    orderBy("fechaCreado","desc")
                );

            }

        }

        if(user != undefined){
            //console.log("====>",dataUser.infoUser)

            if(dataUser.infoUser.rol == "SupervisorTicket" && dataUser.infoUser.categoriaST.length == 1){
                const categoriaRef = doc(FirebaseDB, "AreaSoporte", dataUser.infoUser.categoriaST[0]);
                queryMessages = query(collectionRef, 
                    where("activo", "==", true),
                    where("categoria", "==", categoriaRef),
                    where("fechaCreado",">=", fechaIn),
                    where("fechaCreado","<=", fechaFi),
                    orderBy("fechaCreado","desc")
                );
                
            }
        }

        

        

        const querySnapshot = await getDocs(queryMessages);
    
        if(querySnapshot.size == 0){
            return {
                ok: false,
                errorMessage: "Credenciales no validas"
            }
        }

        querySnapshot.forEach( async (documment) => {
            let data = documment.data();
            data.correlativo = data.id
            data.id = documment.id
            var fechaCreado = data.fechaCreado
            var fechaFinalizado = data.fechaFinalizado

            if(data.tipo == 1){
                data.estado = "Nuevo"
            }else if(data.tipo == 2){
                data.estado = "En proceso"
            }else if(data.tipo == 3){
                data.estado = "Finalizado"
            }

            data.fechaCreadoDate = fechaCreado
            data.fechaCreado = fechaCreado == null ? "" : moment(fechaCreado.toDate()).format("DD/MM/Y hh:mm A")
            data.fechaFinalizado = fechaFinalizado == null ? "" : moment(fechaFinalizado.toDate()).format("DD/MM/Y hh:mm A")

            data.fechaCreadoGrafica = fechaCreado == null ? "" : moment(fechaCreado.toDate()).format("DD/MM/Y")
            data.fechaFinalizadoGrafica = fechaFinalizado == null ? "" : moment(fechaFinalizado.toDate()).format("DD/MM/Y")

            data.prioridad = data.retraso == 0 ? "Normal" : "Alta"
            data.datoSolicitante = data.nombreSolicitante + " " + data.telefonoSolicitante

            var itemUser = listadoUser.find((item) => item.id == data.idUser)
            if(typeof itemUser !== 'undefined'){
                data.de =  itemUser.nombre + " " + itemUser.apellido
            }else{
                data.de =  ""
            }

            data.idCategoria = data.categoria.path
            //obtener categoria
            var itemCategoria = listadoAreaSoporte.find((item) => "AreaSoporte/"+item.id == data.categoria.path)
            if(typeof itemCategoria !== 'undefined'){
                data.categoria =  itemCategoria.nombre
            }else{
                data.categoria =  ""
            }

            data.tiempoAsignarse = data.tiempoAsignarse != undefined ? secondsToHms(data.tiempoAsignarse * 60) : "-"
            data.tiempoIniciar = data.tiempoIniciar != undefined ? secondsToHms(data.tiempoIniciar * 60) : "-"
            data.tiempoFinalizado = data.tiempoFinalizado != undefined ? secondsToHms(data.tiempoFinalizado * 60) : "-"
            
            //obtener sucursal
            var itemSucursal = listadoSucursales.find((item) => "Tiendas/"+item.id == data.tienda.path)
            if(typeof itemSucursal !== 'undefined'){
                data.sucursal =  itemSucursal.nombre
            }else{
                data.sucursal =  ""
            }

            listadoTicket.push(data)
        });

        return {
            ok: true,
            listadoTicket:listadoTicket
        }

        } catch (error) {
            console.log(error)
            return { ok: false, errorMessage: error.message }
        }
    }else{
        return { ok: false, errorMessage: "Sin Resultados"}
    }
    

}

const secondsToHms = (d) => {
    d = Number(d);
    if(d == 0){
      return"0 minuto"; 
    }else{
      var h = Math.floor(d / 3600);
      var m = Math.floor(d % 3600 / 60);
      var s = Math.floor(d % 3600 % 60);

      var mShow = m < 10 ? "0"+m : m

      var hDisplay = h > 0 ? h + (h == 1 ? " hora, " : " horas, ") : "";
      var mDisplay = m > 0 ? mShow + (m == 1 ? " minuto, " : " minutos, ") : "";
      var sDisplay = s > 0 ? s + (s == 1 ? " segundo" : " segundos") : "";

      if(h>0){
        return h + ":" + mShow +  " horas"; 
      }else if(m>0){
        return m > 0 ? mShow + (m == 1 ? " minuto " : " minutos ") : "";; 
      }else{
        return hDisplay + mDisplay + sDisplay; 
      }
    }
    
    
}

export const updateTicketAsignar = async(data,id) => {
    try {
        const washingtonRef = doc(FirebaseDB, "Tickets", id);

        await updateDoc(washingtonRef, data);

        return {
          status: 'success',
          ok: true
        };
    } catch (e) {
      return {
        status: 'error',
        type: 'unknown',
      };
    }
}

export const getUserTickets = async() => {

    
    try {
        var listadoUser = []
        const collectionRef = collection( FirebaseDB, `Usuario`);
        const queryMessages = query(collectionRef, 
            where("activo", "==", true)
        );
        const querySnapshot = await getDocs(queryMessages);
    
        if(querySnapshot.size == 0){
            return {
                ok: false,
                errorMessage: "Sin usuarios"
            }
        }

        querySnapshot.forEach( async (documment) => {
            let data = documment.data();
            if(data.rol == "Agentes" || data.rol == "SupervisorTicket"){
                data.id = documment.id
                data.nombreCompleto = data.nombre + " " + data.apellido
                listadoUser.push(data)
            }
        });

        return {
            ok: true,
            listadoUser:listadoUser
        }

    } catch (error) {
        return { ok: false, errorMessage: error.message }
    }

}

export const getUserGZ = async() => {

    
    try {
        var listadoUser = []
        const collectionRef = collection( FirebaseDB, `Usuario`);
        const queryMessages = query(collectionRef,
            where('rol', "==", "GerenteZona"),
            where("activo", "==", true),
            orderBy("nombre","asc")
        );
        const querySnapshot = await getDocs(queryMessages);
        
        if(querySnapshot.size == 0){
            return {
                ok: false,
                errorMessage: "Sin usuarios"
            }
        }

        querySnapshot.forEach( async (documment) => {
            let data = documment.data();
            data.id = documment.id
            data.nombre = data.nombre + " " + data.apellido
            var tiendas = []
            data.tiendas.forEach( async (docummentTienda) => {
                tiendas.push(docummentTienda.path)
            });

            data.tiendaList = tiendas
            delete data.tiendas
            listadoUser.push(data)
        });

        return {
            ok: true,
            listadoUser:listadoUser
        }

    } catch (error) {
        return { ok: false, errorMessage: error.message }
    }

}

export const deleteBitacora = id => async dispatch => {
    try {
        const washingtonRef = doc(FirebaseDB, "Bitacora", id);

        await updateDoc(washingtonRef, {
            activo: false
        });

        //dispatch(removeZone(id));
        return {
          status: 'success',
        };
    } catch (e) {
      return {
        status: 'error',
        type: 'unknown',
      };
    }
};

export const getListTareasActividad = async(fechaInicio,fechaFinal) => {


    try {
        var listadoTareas = []
        const collectionRef = collection( FirebaseDB, `ProgresoTareas`);
        var queryMessages = query(collectionRef, 
            where('fecha', ">=", fechaInicio),
            where('fecha', "<=", fechaFinal)
        );

        const querySnapshot = await getDocs(queryMessages);
    
        if(querySnapshot.size == 0){
            return {
                ok: false,
                errorMessage: "Sin data"
            }
        }

        querySnapshot.forEach( async (documment) => {
            let data = documment.data();
            data.id = documment.id
            var fechaCreado = data.fecha
            var fechaFinalizado = data.fecha

            data.fechaOrdenar = new Date(data.fecha.toDate())
            data.fechaCreado = fechaCreado == null ? "" : moment(fechaCreado.toDate()).format("DD/MM/Y hh:mm A")
           
            data.fechaCreadoGrafica = fechaCreado == null ? "" : moment(fechaCreado.toDate()).format("DD/MM/Y")
            data.fechaFinalizadoGrafica = fechaFinalizado == null ? "" : moment(fechaFinalizado.toDate()).format("DD/MM/Y")

            listadoTareas.push(data)
        });

        return {
            ok: true,
            listadoTareas:listadoTareas
        }

        } catch (error) {
            return { ok: false, errorMessage: error.message }
        }
    

}



export const getListTicketsActividadInicio = async(fechaInicio,fechaFinal,dataUser) => {

    const resultUser = await getAllUser();



    if(resultUser.ok){
      var listadoUser = resultUser.listadoUser
      try {
        var listadoTicket = []
        const collectionRef = collection( FirebaseDB, `Tickets`);
        var queryMessages = query(collectionRef, 
            where("activo", "==", true),
            where('fechaCreado', ">=", fechaInicio),
            where('fechaCreado', "<=", fechaFinal),
            orderBy("fechaCreado","asc"),
            //limit(50)
        );

        if(dataUser != undefined){
            if(dataUser.infoUser.rol == "SupervisorTicket" && dataUser.infoUser.categoriaST.length == 1){
                const categoriaRef = doc(FirebaseDB, "AreaSoporte", dataUser.infoUser.categoriaST[0]);

                queryMessages = query(collectionRef, 
                    where("activo", "==", true),
                    where('fechaCreado', ">=", fechaInicio),
                    where('fechaCreado', "<=", fechaFinal),
                    where("categoria", "==", categoriaRef),
                    orderBy("fechaCreado","asc"),
                    //limit(50)
                );
                
            }
        }

        const querySnapshot = await getDocs(queryMessages);
    
        if(querySnapshot.size == 0){
            return {
                ok: false,
                errorMessage: "Credenciales no validas"
            }
        }

        querySnapshot.forEach( async (documment) => {
            let data = documment.data();
            data.id = documment.id
            var fechaCreado = data.fechaCreado
            var fechaFinalizado = data.fechaFinalizado

            data.fechaOrdenar = new Date(data.fechaCreado.toDate())
            data.fechaCreado = fechaCreado == null ? "" : moment(fechaCreado.toDate()).format("DD/MM/Y hh:mm A")
            data.fechaFinalizado = fechaFinalizado == null ? "" : moment(fechaFinalizado.toDate()).format("DD/MM/Y hh:mm A")
            data.categoria = data.categoria.path
            data.tiendaFind = data.tienda.path

            data.fechaCreadoGrafica = fechaCreado == null ? "" : moment(fechaCreado.toDate()).format("DD/MM/Y")
            data.fechaFinalizadoGrafica = fechaFinalizado == null ? "" : moment(fechaFinalizado.toDate()).format("DD/MM/Y")
            data.idUserAsignado = data.idUserAsignado  == null ? "" :data.idUserAsignado


            
            data.prioridad = data.retraso == 0 ? "Normal" : "Alta"

            var itemUser = listadoUser.find((item) => item.id == data.idUser)
            if(typeof itemUser !== 'undefined'){
                data.de =  itemUser.nombre + " " + itemUser.apellido
            }else{
                data.de =  ""
            }

            listadoTicket.push(data)
        });

        return {
            ok: true,
            listadoTicket:listadoTicket
        }

        } catch (error) {
            return { ok: false, errorMessage: error.message }
        }
    }else{
        return { ok: false, errorMessage: "Sin Resultados"}
    }
    

}

export const getListTicketsActividadFin = async(fechaInicio,fechaFinal,dataUser) => {

    const resultUser = await getAllUser();


    if(resultUser.ok){
      var listadoUser = resultUser.listadoUser
      try {
        var listadoTicket = []
        const collectionRef = collection( FirebaseDB, `Tickets`);
        var queryMessages = query(collectionRef, 
            where("activo", "==", true),
            where('fechaFinalizado', ">=", fechaInicio),
            where('fechaFinalizado', "<=", fechaFinal),
            orderBy("fechaFinalizado","asc"),
            //limit(50)
        );

        if(dataUser != undefined){
            if(dataUser.infoUser.rol == "SupervisorTicket" && dataUser.infoUser.categoriaST.length == 1){
                const categoriaRef = doc(FirebaseDB, "AreaSoporte", dataUser.infoUser.categoriaST[0]);

                queryMessages = query(collectionRef, 
                    where("activo", "==", true),
                    where('fechaFinalizado', ">=", fechaInicio),
                    where('fechaFinalizado', "<=", fechaFinal),
                    where("categoria", "==", categoriaRef),
                    orderBy("fechaFinalizado","asc"),
                    //limit(50)
                );
                
            }
        }

        const querySnapshot = await getDocs(queryMessages);
    
        if(querySnapshot.size == 0){
            return {
                ok: false,
                errorMessage: "Credenciales no validas"
            }
        }

        querySnapshot.forEach( async (documment) => {
            let data = documment.data();
            data.id = documment.id
            var fechaCreado = data.fechaCreado
            var fechaFinalizado = data.fechaFinalizado

            data.fechaOrdenar = new Date(data.fechaFinalizado.toDate())
            data.fechaCreado = fechaCreado == null ? "" : moment(fechaCreado.toDate()).format("DD/MM/Y hh:mm A")
            data.fechaFinalizado = fechaFinalizado == null ? "" : moment(fechaFinalizado.toDate()).format("DD/MM/Y hh:mm A")
            data.categoria = data.categoria.path
            data.tiendaFind = data.tienda.path

            data.fechaCreadoGrafica = fechaCreado == null ? "" : moment(fechaCreado.toDate()).format("DD/MM/Y")
            data.fechaFinalizadoGrafica = fechaFinalizado == null ? "" : moment(fechaFinalizado.toDate()).format("DD/MM/Y")
            data.idUserAsignado = data.idUserAsignado  == null ? "" :data.idUserAsignado
            
            
            data.prioridad = data.retraso == 0 ? "Normal" : "Alta"

            var itemUser = listadoUser.find((item) => item.id == data.idUser)
            if(typeof itemUser !== 'undefined'){
                data.de =  itemUser.nombre + " " + itemUser.apellido
            }else{
                data.de =  ""
            }

            listadoTicket.push(data)
        });

        return {
            ok: true,
            listadoTicket:listadoTicket
        }

        } catch (error) {
            console.log(error)
            return { ok: false, errorMessage: error.message }
        }
    }else{
        return { ok: false, errorMessage: "Sin Resultados"}
    }
    

}

export const getAreaSoporte = async() => {
    var listadoAreaSoporte = []

    const collectionRef = collection( FirebaseDB, `AreaSoporte`);
    const queryMessages = query(collectionRef, 
        where("activo", "==", true),
    );
    const querySnapshot = await getDocs(queryMessages);

    querySnapshot.forEach( async (documment) => {
        let data = documment.data();
        data.id = documment.id
        listadoAreaSoporte.push(data)
    });

    return {
        ok: true,
        listadoAreaSoporte:listadoAreaSoporte
    }

}

export const getRutas = async() => {
    var listadoRutas = []

    const resultUbicaciones = await getUbicaciones();
    if(resultUbicaciones.ok){
        var listadoUbicaciones = resultUbicaciones.listadoUbicaciones

        const collectionRef = collection( FirebaseDB, `Rutas`);
        const queryMessages = query(collectionRef, 
            where("activo", "==", true),
        );
        const querySnapshot = await getDocs(queryMessages);

        querySnapshot.forEach( async (documment) => {
            let data = documment.data();
            data.id = documment.id

            var itemUbicacionesOrigen = listadoUbicaciones.find((item) => item.id == data.origen)
            if(typeof itemUbicacionesOrigen !== 'undefined'){
                data.origenName =  itemUbicacionesOrigen.nombre
            }else{
                data.origenName =  ""
            }

            var itemUbicacionesDestino = listadoUbicaciones.find((item) => item.id == data.destino)
            if(typeof itemUbicacionesDestino !== 'undefined'){
                data.destinoName =  itemUbicacionesDestino.nombre
            }else{
                data.destinoName =  ""
            }




            listadoRutas.push(data)
        });

        return {
            ok: true,
            listadoRutas:listadoRutas
        }
    }else{
        return {
            ok: true,
            listadoRutas:[]
        }
    }

    
}

export const getRutasActivas = async() => {
    var listadoRutas = []

    const resultUser = await getAllUser();
    var listadoUser = resultUser.listadoUser

    const collectionRef = collection( FirebaseDB, `RegistroRutas`);
    const queryMessages = query(collectionRef, 
        where("activo", "==", true),
        orderBy("fechaCreado","desc"),
    );
    const querySnapshot = await getDocs(queryMessages);

    querySnapshot.forEach( async (documment) => {
        let data = documment.data();
        data.id = documment.id
        data.fechaCreado = data.fechaCreado == null ? "" : moment(data.fechaCreado.toDate()).format("DD/MM/Y hh:mm A")

        var itemUser = listadoUser.find((item) => item.id == data.idUser)
        console.log("========",itemUser)
        if(typeof itemUser !== 'undefined'){
            data.de =  itemUser.nombre + " " + itemUser.apellido
        }else{
            data.de =  ""
        }

        listadoRutas.push(data)
    });

    return {
        ok: true,
        listadoRutas:listadoRutas
    }

    
}


export const getRutasHistorico = async(fechaInicio,fechaFinal) => {
    var listadoRutas = []

    const resultUser = await getAllUser();
    var listadoUser = resultUser.listadoUser


    const collectionRef = collection( FirebaseDB, `RegistroRutas`);
    const queryMessages = query(collectionRef, 
        where("activo", "==", false),
        where('fechaCreado', ">=", fechaInicio),
        where('fechaCreado', "<=", fechaFinal),
        orderBy("fechaCreado","desc"),
        //limit(30)
    );
    const querySnapshot = await getDocs(queryMessages);

    const promises = [];

    querySnapshot.forEach( async (documment) => {
        let data = documment.data();
        data.id = documment.id
        var fechaInicio = data.fechaCreado.toDate()
        var fechaFin = data.fechaFinalizado.toDate()

        var diffMs = fechaFin - fechaInicio; // diferencia en milisegundos

        var diffHours = Math.floor(diffMs / 3600000); // diferencia en horas
        var diffMinutes = Math.floor((diffMs % 3600000) / 60000); // diferencia en minutos


        var diffString = ''; // variable para almacenar la cadena de texto
        
        diffString += diffHours.toString().padStart(2, '0') + ':'; // agregar ceros a la izquierda si es necesario
        diffString += diffMinutes.toString().padStart(2, '0'); // agregar ceros a la izquierda si es necesario
        

        data.fechaCreado = data.fechaCreado == null ? "" : moment(data.fechaCreado.toDate()).format("DD/MM/Y hh:mm A")
        data.fechaFinalizado = data.fechaFinalizado == null ? "" : moment(data.fechaFinalizado.toDate()).format("DD/MM/Y hh:mm A")
        data.tiempoTranscurrido = diffString
        data.fechaInicio = fechaInicio
        data.fechaFin = fechaFin
        data.statusFin =  data.status+" "+data.commentStatus

        var itemUser = listadoUser.find((item) => item.id == data.idUser)
        if(typeof itemUser !== 'undefined'){
            data.de =  itemUser.nombre + " " + itemUser.apellido
        }else{
            data.de =  ""
        }

        if(typeof data.recorridoGPS !== 'undefined'){
            let distance;
            distance = `${(data.recorridoGPS  / 1000).toFixed(2)}`;
            data.recorridoGPS = distance
            listadoRutas.push(data)
        }else{
            const promise = obtenerDistanciaRecorrida(data.id).then((resultCard) => {
                data.recorridoGPS = resultCard.distance;
                listadoRutas.push(data)
            });

            promises.push(promise);
            
        }

        
       
    });

    await Promise.all(promises);

    return {
        ok: true,
        listadoRutas:listadoRutas
    }

    
}

export const obtenerDistanciaRecorrida = async(id) => {

    console.log("id",id)
    const collectionRef = collection(FirebaseDB, `CoordenadasRutas`);
    const queryMessages = query(
      collectionRef,
      where("activo", "==", true),
      where("ruta", "==", id),
      orderBy("fechaCreado", "asc")
    );


    const querySnapshot = await getDocs(queryMessages);

    var pathCoordinates = []
    querySnapshot.forEach((documment) => {
        var item = documment.data()
        pathCoordinates.push({latitude: item.latitude, longitude: item.longitude})
    });


    // Calcular la distancia entre todos los puntos de la lista
    let totalDistance = 0;
    for (let i = 0; i < pathCoordinates.length - 1; i++) {
    const start = pathCoordinates[i];
    const end = pathCoordinates[i + 1];
    const distance = getDistance(start, end);
    totalDistance += distance;
    }




    let distance;
    distance = `${(totalDistance / 1000).toFixed(2)}`;

    var itemSend = {}
    itemSend.recorridoGPS = totalDistance
    await updateDocument(itemSend,`/RegistroRutas/${id}`)
    

    return {
        ok: true,
        distance:distance
    }
    
}

export const getRegistroRutas = async(id) => {
   
    const resultUser = await getAllUser();
    var listadoUser = resultUser.listadoUser

    const docRef = doc(FirebaseDB, "RegistroRutas", id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        
        let data = docSnap.data();
        data.id = docSnap.id
        data.fechaCreado = data.fechaCreado == null ? "" : moment(data.fechaCreado.toDate()).format("DD/MM/Y hh:mm A")


        var itemUser = listadoUser.find((item) => item.id == data.idUser)
        console.log("========",itemUser)
        if(typeof itemUser !== 'undefined'){
            data.de =  itemUser.nombre + " " + itemUser.apellido
        }else{
            data.de =  ""
        }

        return {
            ok: true,
            infoRuta:data
        }
    } else {
    // doc.data() will be undefined in this case
        console.log("No such document!");
        return { ok: false, errorMessage: "No such document!" }
    }

    
}


export const getUbicaciones = async() => {
    var listadoUbicaciones = []

    const collectionRef = collection( FirebaseDB, `Ubicaciones`);
    const queryMessages = query(collectionRef, 
        where("activo", "==", true),
        orderBy("nombre","asc")
    );
    const querySnapshot = await getDocs(queryMessages);

    querySnapshot.forEach( async (documment) => {
        let data = documment.data();
        data.id = documment.id
        listadoUbicaciones.push(data)
    });

    return {
        ok: true,
        listadoUbicaciones:listadoUbicaciones
    }
}

export const getTipificacion = async() => {
    var listadoTipificacion = []

    const resultCategorias = await getAreaSoporte();
    if(resultCategorias.ok){
        var listadoAreaSoporte = resultCategorias.listadoAreaSoporte

        console.log("listadoAreaSoporte",listadoAreaSoporte)

        const collectionRef = collection( FirebaseDB, `Tipificaciones`);
        const queryMessages = query(collectionRef, 
            where("visible", "==", true),
            orderBy("nombre","asc"),
        );
        const querySnapshot = await getDocs(queryMessages);

        querySnapshot.forEach( async (documment) => {
            let data = documment.data();
            data.id = documment.id

            var itemCategoria = listadoAreaSoporte.find((item) => item.id == data.categoria)
            if(typeof itemCategoria !== 'undefined'){
                data.categoriaName =  itemCategoria.nombre
            }else{
                data.categoriaName =  ""
            }

            listadoTipificacion.push(data)
        });

        return {
            ok: true,
            listadoTipificacion:listadoTipificacion
        }
    }else{
        return { ok: false, errorMessage: "Sin Resultados"}
    }

    

}

export const getTareas = async() => {
    var listadoTareas = []

    const resultCategorias = await getAllCategorias();
    if(resultCategorias.ok){
        var listadoCategorias = resultCategorias.listadoCategorias

        const collectionRef = collection( FirebaseDB, `Tareas`);
        const queryMessages = query(collectionRef, 
            where("activo", "==", true),
            orderBy("titulo","asc"),
        );
        const querySnapshot = await getDocs(queryMessages);

        querySnapshot.forEach( async (documment) => {
            let data = documment.data();
            data.id = documment.id

            var itemCategoria = listadoCategorias.find((item) => "Categorias/"+item.id == data.categoria.path)
            if(typeof itemCategoria !== 'undefined'){
                data.categoriaName =  itemCategoria.nombre
            }else{
                data.categoriaName =  ""
            }

            var horarioInicio = data.horaInicio
            var horaFin = data.horaFin
            var horario = horarioInicio.slice(0, 2)+":"+horarioInicio.slice(2, 4)+" - "+horaFin.slice(0, 2)+":"+horaFin.slice(2, 4)
            data.horario = horario

            listadoTareas.push(data)
        });

        return {
            ok: true,
            listadoTareas:listadoTareas
        }
    }else{
        return { ok: false, errorMessage: "Sin Resultados"}
    }

    

}


export const getAllRestaurante = async() => {


    try {
        var listadoUser = []
        const collectionRef = collection( FirebaseDB, `Tiendas`);
        const queryMessages = query(collectionRef, 
            where("activo", "==", true),
            orderBy("nombre","asc"),
        );
       // const queryMessages = query(collectionRef);
        const querySnapshot = await getDocs(queryMessages);
    
        if(querySnapshot.size == 0){
            return {
                ok: false,
                errorMessage: "Sin Tiendas"
            }
        }

        querySnapshot.forEach( async (documment) => {
            let data = documment.data();
            data.id = documment.id
            listadoUser.push(data)
        });

        return {
            ok: true,
            listadoUser:listadoUser
        }

    } catch (error) {
        return { ok: false, errorMessage: error.message }
    }

}


export const getAllSucursales = async() => {


    try {
        const resultDepartamento = await getAllDepartamento();
        if(resultDepartamento.ok){
            var listadoDepartamentos = resultDepartamento.listadoDepartamentos

            var listadoUser = []
            const collectionRef = collection( FirebaseDB, `Tiendas`);
            const queryMessages = query(collectionRef, 
                where("activo", "==", true),
                orderBy("fechaCreado","desc"),
            );

            const querySnapshot = await getDocs(queryMessages);


        
            if(querySnapshot.size == 0){
                return {
                    ok: false,
                    errorMessage: "Sin Tiendas"
                }
            }

            querySnapshot.forEach( async (documment) => {
                let data = documment.data();
                data.id = documment.id

                var itemDepartamento = listadoDepartamentos.find((item) => item.id == data.idDepartamento)
                if(typeof itemDepartamento !== 'undefined'){
                    data.departamento =  itemDepartamento.nombre
                }else{
                    data.departamento =  ""
                }
                
                
                listadoUser.push(data)
            });

            return {
                ok: true,
                listadoUser:listadoUser
            }

        }

    } catch (error) {
        return { ok: false, errorMessage: error.message }
    }

}


export const getAllDepartamento = async() => {


    try {
        var listadoDepartamentos = []
        const collectionRef = collection( FirebaseDB, `Departamentos`);
        const queryMessages = query(collectionRef);
        const querySnapshot = await getDocs(queryMessages);
    
        if(querySnapshot.size == 0){
            return {
                ok: false,
                errorMessage: "Sin Departamentos"
            }
        }

        querySnapshot.forEach( async (documment) => {
            let data = documment.data();
            data.id = documment.id
            listadoDepartamentos.push(data)
        });

        return {
            ok: true,
            listadoDepartamentos:listadoDepartamentos
        }

    } catch (error) {
        return { ok: false, errorMessage: error.message }
    }

}


export const getAllCategorias = async() => {


    try {
        var listadoCategorias = []
        const collectionRef = collection( FirebaseDB, `Categorias`);
        const queryMessages = query(collectionRef, 
            where("activo", "==", true),
            orderBy("nombre","asc")
        );
        const querySnapshot = await getDocs(queryMessages);
    
        if(querySnapshot.size == 0){
            return {
                ok: false,
                errorMessage: "Sin Categorias"
            }
        }

        querySnapshot.forEach( async (documment) => {
            let data = documment.data();
            data.id = documment.id
            listadoCategorias.push(data)
        });

        return {
            ok: true,
            listadoCategorias:listadoCategorias
        }

    } catch (error) {
        return { ok: false, errorMessage: error.message }
    }

}


export const getInfoUser = async(id) => {


    try {
        //const collectionRef = collection( FirebaseDB, `Usuario`);
        const docRef = doc(FirebaseDB, "Usuario", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            return {
                ok: true,
                infoUser:docSnap.data()
            }
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
            return { ok: false, errorMessage: "No such document!" }
          }

        

    } catch (error) {
        return { ok: false, errorMessage: error.message }
    }

}

export const getAllUser = async() => {


    try {
        var listadoUser = []
        const collectionRef = collection( FirebaseDB, `Usuario`);
        /*const queryMessages = query(collectionRef, 
            where("activo", "==", true)
        );*/
        const queryMessages = query(collectionRef);
        const querySnapshot = await getDocs(queryMessages);
    
        if(querySnapshot.size == 0){
            return {
                ok: false,
                errorMessage: "Sin usuarios"
            }
        }

        querySnapshot.forEach( async (documment) => {
            let data = documment.data();
            data.id = documment.id
            listadoUser.push(data)
        });

        return {
            ok: true,
            listadoUser:listadoUser
        }

    } catch (error) {
        return { ok: false, errorMessage: error.message }
    }

}

export const getAllUserList = async() => {


    try {
        var listadoUser = []
        const collectionRef = collection( FirebaseDB, `Usuario`);
        const queryMessages = query(collectionRef, 
            where("activo", "==", true),
            orderBy("nombre","asc"),
            limit(500)
        );
        //const queryMessages = query(collectionRef);
        const querySnapshot = await getDocs(queryMessages);
    
        if(querySnapshot.size == 0){
            return {
                ok: false,
                errorMessage: "Sin usuarios"
            }
        }

        querySnapshot.forEach( async (documment) => {
            let data = documment.data();
            data.id = documment.id
            listadoUser.push(data)
        });

        return {
            ok: true,
            listadoUser:listadoUser
        }

    } catch (error) {
        console.log("error",error)
        return { ok: false, errorMessage: error.message }
    }

}

export const getTareaTemporal = async() => {


    try {
        var listadoUser = []
        var tiendaRef = doc( FirebaseDB, "/Tiendas/VIitRS2IWCMA7cYptrOx" );
        var CategoriaRef = doc( FirebaseDB, "/Categorias/u8ylFfg8taMMfigwFolb");

        const collectionRef = collection( FirebaseDB, `Seguimiento`);
        const queryMessages = query(collectionRef, 
            where("tienda", "==", tiendaRef),
            orderBy("fecha","desc"),
            limit(50)
        );
        const querySnapshot = await getDocs(queryMessages);
    
    
        querySnapshot.forEach( async (documment) => {
            let data = documment.data();
            data.id = documment.id
            
            console.log("Data temporal",data)
        });

        return {
            ok: true,
            listadoUser:listadoUser
        }

    } catch (error) {
        return { ok: false, errorMessage: error.message }
    }

}

export const updateDocument = async(data,ref) => {


    try {
        
        const collectionRef = doc( FirebaseDB, ref);
        await updateDoc(collectionRef, data);
        return {
            ok: true
        }

    } catch (error) {
        return { ok: false, errorMessage: error.message }
    }

}

export const createDocument = async(collectionName,data) => {


    try {
        const collectionRef = doc(collection( FirebaseDB, collectionName ));
        await setDoc(collectionRef, data);

        return {
            ok: true,
            info: collectionRef
        }

    } catch (error) {
        return { ok: false, errorMessage: error.message }
    }

}


export const createReferencia = async(ref) => {


    try {
        const documentRef = doc( FirebaseDB, ref);

        return documentRef

    } catch (error) {
        return null
    }

}

export const obtenerCorrelativo = async(ref) => {
    
    
    let today = new Date();

    let fechaNueva =  new Date(moment(today));

    var fechaInicio = new Date(fechaNueva.setHours(0,0,0,0));
    var fechaFinal = new Date(fechaNueva.setHours(24,0,0,0));


    try {
        const collectionRef = collection( FirebaseDB, `Tickets`);
        const queryMessages = query(collectionRef, 
            where("activo", "==", true),
            where("fechaCreado", ">=", fechaInicio),
            where("fechaCreado", "<=", fechaFinal),
        );
        const querySnapshot = await getDocs(queryMessages);

        var total = querySnapshot.size + 1
        if(total < 10){
            total = "00"+total
        }else if(total < 100){
            total = "0"+total
        }

        var fechaCorrelativo = moment(Date()).format("DDMMYY")
        var correlativo = fechaCorrelativo + "-" + total

        return {
            ok: true,
            correlativo:correlativo
        }

    } catch (error) {
        return { ok: false, errorMessage: error.message }
    }

}