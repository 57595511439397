import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {message, Modal} from 'antd';
import {
  getAllZones,
  selectZones,
  getAllLevels,
  removeMonitorZone,
  activeZone,
} from '../../redux/slices/zones';
import { getAllSucursales, getAllUserList,getAreaSoporte,updateDocument} from '../../firebase/queryProvider';
import UsuariosUI from './components/UsuariosUI';


const Usuarios = () => {
  const [loading, handleLoading] = useState(true);
  const [editorVisible, handleEditorVisible] = useState(false);
  const [users, setUsers] = useState([]);
  const [item, setItem] = useState(null);
  const [itemPass, setItemPass] = useState(null);
  const [isModalDetails, handleIsModalDetails] = useState(false);
  const [isModalDetailsPass, handleIsModalDetailsPass] = useState(false);
  const [sucursales, setSucursales] = useState([]);
  const [categorias, setCategorias] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
   

    const initialFetch = async () => {

      const resultUsers = await getAllUserList();
      if(resultUsers.ok){
        setUsers(resultUsers.listadoUser)
      }

      const result = await getAllSucursales();
      if(result.ok){
        setSucursales(result.listadoUser)
      }

      const resultArea = await getAreaSoporte();
      if(result.ok){
        setCategorias(resultArea.listadoAreaSoporte)
      }

      handleLoading(false);

    };

    initialFetch();
  }, [dispatch]);

  const confirmDelete = (idUser) => {
    Modal.confirm({
      title: '¿Estás segúro de eliminar este usuario?',
      content: 'Esta información no se podrá recuperar.',
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        await removeUser(idUser);
      },
      onCancel() {},
    });
  };

  const removeUser = async (idUser) => {
    const info = {
      activo: false
    };

    const response =  await updateDocument(info,`/Usuario/${idUser}`)
    console.log("response",response)
    if (response.ok === true) {
      var allUser = [... users]

      const index = allUser.findIndex(el => el.id === idUser);
      if (index > -1) {
        allUser.splice(index, 1);
      }

      setUsers(allUser)

      message.success('Usuario eliminado correctamente');
    }else{
      message.error('¡Hubo un problema!');
    }
  };

  const handleActive = async (idZone, val) => {
    const response = await dispatch(activeZone(idZone, val));
    if (response.status !== 'success') {
      message.error('¡Hubo un problema!');
    }
  };

  const handleDetail = async (item) => {
    setItem(item)
    handleIsModalDetails(true)
  };

  const handleDetailPass = async (item) => {
    console.log("item",item)
    setItemPass(item)
    handleIsModalDetailsPass(true)
  };
  

  const handleCancelModalDetails = async () => {
    setItem(null)
    handleIsModalDetails(false)
  };

  const handleCancelModalDetailsPass = async () => {
    setItemPass(null)
    handleIsModalDetailsPass(false)
  };

  

  const newUser = async () => {
    var item = {}
    item.nuevo = true
    setItem(item)
    handleIsModalDetails(true)
    
    
  };

  const agregarData = async (data) => {
    var allUser = [... users]
    allUser.unshift(data)
    setUsers(allUser)
  }

  const actualizarData = async (data) => {
    console.log("data",data)
    var allUser = [... users]
    var itemUser = allUser.find((item) => item.id == data.id)
    if(typeof itemUser !== 'undefined'){
      var index = allUser.indexOf(itemUser);
      if (index != -1) {
        allUser[index] = data
      }

    }


    setUsers(allUser)

    
  };

  return (
    <UsuariosUI
      loading={loading}
      users={users}
      item={item}
      itemPass={itemPass}
      showDetails={handleDetail}
      showDetailsPass={handleDetailPass}
      confirmDelete={confirmDelete}
      handleActive={handleActive}
      isModalDetails={isModalDetails}
      isModalDetailsPass={isModalDetailsPass}
      sucursales={sucursales}
      categorias={categorias}
      cancelModalDetails={handleCancelModalDetails}
      cancelModalDetailsPass={handleCancelModalDetailsPass}
      newUser={newUser}
      actualizarData={actualizarData}
      agregarData={agregarData}
    />
  );
}

export default Usuarios;
