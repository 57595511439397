import {useRef,useState,useEffect,useCallback} from 'react';
import {Grid, Table, Spin, Modal, Col, Row,Form,Input,Select,message,Upload,DatePicker} from 'antd';
import Header from '../../../components/HeaderList';
import {getColumnSearchProps} from '../../../utils/tables';
import Editor from './Editor';
import styles from '../styles/MonitorUI.module.css';
import ImageViewer from 'react-simple-image-viewer';
import {useSelector, useDispatch} from 'react-redux';
import { getRegistroRutas} from '../../../firebase/queryProvider';
import {selectUser} from '../../../redux/slices/user';
import moment from 'moment';
import instance from "../../../api/Request";
import locale from 'antd/es/date-picker/locale/es_ES';
import dayjs from 'dayjs';
import { GoogleMap, useJsApiLoader, Polyline } from '@react-google-maps/api';
import { google } from "google-maps";
import { useParams } from "react-router-dom";
import { collection, query, where,orderBy,onSnapshot} from "firebase/firestore";
import { FirebaseAuth,FirebaseDB } from '../../../firebase/config';



import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"

dayjs.extend(weekday)
dayjs.extend(localeData)


const {Option} = Select;
const { TextArea } = Input;

const dateFormat = 'DD/MM/YYYY';
const customFormat = (value) => `${value.format(dateFormat)}`;

const MapaRutaUI = props => {
  const [loading, handleLoading] = useState(true);
  const [sending, handleSending] = useState(false);
  const [form] = Form.useForm();
  const [tipificacionSelect, setTipificacionSelect] = useState([]);
  const [isModalAsignar, setIsModalAsignar] = useState(false);
  const [loadingAsignar, setLoadingAsignar] = useState(false);
  const [distance, setDistance] = useState("");
  const [rutas, setRutas] = useState("");
  const [ruta, setRuta] = useState({});
  const [ubicaciones, setUbicaciones] = useState([]);
  const [bounds, setBounds] = useState(null);

  const [item, setItem] = useState(null);
  const [center, setCenter] = useState({lat: 13.701319108430834,lng: -89.22441469943618});
  const [pathCoordinates, setPathCoordinates] = useState([]);
  const { useBreakpoint } = Grid;
  const { id } = useParams();

  const dispatch = useDispatch();
  
  const user = useSelector(selectUser);
  const defaultMapOptions = { fullscreenControl: false, };
  //const map = useRef(null);
  const screens = useBreakpoint();
  //const libraries: Array<any> = [];
  const libraries = ["geometry"];
  const [map, setMap] = useState(null);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyCuHgP6e-m4ZrtObL9xRc3EvBoFeCI4xrw',
    libraries,
  });

  

  useEffect(() => {
    const initialFetch = async () => {
      if(rutas.length != 0){
        setBounds(new window.google.maps.LatLngBounds())
      }
     
      const result = await getRegistroRutas(id);
      console.log("result",result)

      
            
      if(result.ok){
        setRuta(result.infoRuta)
      }else{
        message.error('¡Hubo un problema!');
      }

      handleLoading(false);
    };
    initialFetch();
  }, [dispatch]);

  useEffect(() => {
    if (map !== null) {
      obtenerInfoRuta();
    }
  }, [map]);

  const obtenerInfoRuta = async() => {
    const collectionRef = collection(FirebaseDB, `CoordenadasRutas`);
    const queryMessages = query(
      collectionRef,
      where("activo", "==", true),
      where("ruta", "==", id),
      orderBy("fechaCreado", "asc")
    );

    const unsubscribe = onSnapshot(queryMessages, (querySnapshot) => {
      const newlistadoRutas = [];

      querySnapshot.forEach((documment) => {
        let data = documment.data();
        data.id = documment.id;
        newlistadoRutas.push(data);
      });

      setRutas(newlistadoRutas);

      var pathCoordinates = []
      for( var z = 0; z < newlistadoRutas.length ; z++){
        var item = newlistadoRutas[z]
        console.log("====",item.latitude,item.longitude)

        pathCoordinates.push({lat: item.latitude, lng: item.longitude})
      }

      setPathCoordinates(pathCoordinates)


      const bounds = new window.google.maps.LatLngBounds();
      for (let i = 0; i < pathCoordinates.length; i++) {
        bounds.extend(new window.google.maps.LatLng(pathCoordinates[i].lat, pathCoordinates[i].lng));
      }

      setBounds(bounds)
      console.log("map",map)
      if (map !== null) {
        map.fitBounds(bounds);
      }
      //map.fitBounds(bounds);
      setCenter(bounds.getCenter())

      const distanceInMeters = pathCoordinates.reduce((distance, coords, index, array) => {
        if (index < array.length - 1) {
          const nextCoords = array[index + 1];
          const distanceBetweenPoints = window.google.maps.geometry.spherical.computeDistanceBetween(
            new window.google.maps.LatLng(coords),
            new window.google.maps.LatLng(nextCoords)
          );
          return distance + distanceBetweenPoints;
        } else {
          return distance;
        }
      }, 0);
    
      let distance;
      if(typeof ruta.recorridoGPS !== 'undefined'){
        if (ruta.recorridoGPS  < 1000) {
          distance = `${ruta.recorridoGPS .toFixed(0)} m`;
        } else {
          distance = `${(ruta.recorridoGPS  / 1000).toFixed(2)} km`;
        }
      }else{
        if (distanceInMeters < 1000) {
          distance = `${distanceInMeters.toFixed(0)} m`;
        } else {
          distance = `${(distanceInMeters / 1000).toFixed(2)} km`;
        }
      }
      setDistance(distance)

      //console.log("==== mapOptions",distance)

    });

  }

  const openEditor = (item) => {
    setItem(item);

    const newValues = {
      origen: item.origen,
      destino: item.destino,
      recorrido: item.recorrido
    };
    form.setFieldsValue(newValues);

    setIsModalAsignar(true);
  };

  const columns = [
    {
      title: 'Fecha Inicio',
      dataIndex: 'fechaCreado',
      align: 'left'
    },
    {
      title: 'Fecha Fin',
      dataIndex: 'fechaFinalizado',
      align: 'left'
    },
    {
      title: 'Origen',
      dataIndex: 'origenNombre',
      align: 'left',
      ...getColumnSearchProps('origenNombre'),
    },
    {
      title: 'Destino',
      dataIndex: 'destinoNombre',
      ...getColumnSearchProps('destinoNombre'),
    },
    {
      title: 'Usuario',
      dataIndex: 'de',
      render: (id, record) => (
        <div>
          {record.de}
        </div>
      ),
      ...getColumnSearchProps('de'),
    },
    {
      title: 'Ver',
      align: 'center',
      dataIndex: 'id',
      render: (id, record) => (
        <div className={styles.actions}>
          <div className={styles.edit} onClick={() => openEditor(record)}>
            
          </div>
        </div>
      ),
    },
  ];

  const save = () => {
    
  }

  const drawMapLoaded = (objmap, objmaps) => {
    //if(address.latitude && address.longitude) {
    //    objmap.setCenter({lat: address.latitude, lng: address.longitude});
    //}
  };


  const renderMap = () => {
    const onLoadMap = (ref: any) => {
      map.current = ref;
      //map.fitBounds(bounds);
    };

    const onMapLoad = (map) => {
      //map.current = ref;
      map.fitBounds(bounds);
    };

    const onLoad = (map) => {
      setMap(map);
    };

    return (
      <GoogleMap
        mapContainerClassName={styles.map}
        //center={ceonLoadnter}
        onLoad={onLoad}
        zoom={14}
        options={{
          disableDefaultUI: true,
          zoomControl: true,
          fullscreenControl: false,
          center: center
        }}
      >
        <Polyline
          path={pathCoordinates}
          options={
            {
              strokeColor: '#00B3FD',
              strokeOpacity: 0.6,
              strokeWeight: 8,
              geodesic: true,
              icons: [
                {
                  icon: {
                    path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    scale: 1,
                    fillOpacity: 0,
                    strokeColor: "#000",
                    strokeWeight: 1,
                    strokeOpacity: 1,
                  },
                  repeat: '50px',
                }
              ]
            }
          }
          strokeColor="#fff"
          geodesic={true}
          strokeOpacity={0.6}
          strokeWeight={8}
        />
      </GoogleMap>
    );
  };
  return (
    <div className={styles.view}>
      <Header
        title="MAPA"
        back={ruta.activo ? "/monitor/rutas" : "/monitor/historico"}
      />
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <div className={styles.contRuta}>
            <div className={styles.ruta}>{ruta.de}</div>
            <div className={styles.ruta}>{ruta.origenNombre} -{">"} {ruta.destinoNombre}</div>
            <div className={styles.ruta}>Distancia recorrida: {distance}</div>
          </div>
          <div className={styles.contMapa}>
            {isLoaded && renderMap()}
          </div>
    
         
        </div>
      )}
    </div>
  );
};

export default MapaRutaUI;
