import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {message, Modal} from 'antd';
import {
  getAllZones,
  selectZones,
  getAllLevels,
  removeMonitorZone,
  activeZone,
} from '../../redux/slices/zones';
import { getTipificacion, getAllUser,getInfoUser, getListTicketsActividadInicio,getAreaSoporte,getUserTickets,getListTicketsActividadFin,getAllRestaurante,getUserGZ} from '../../firebase/queryProvider';
import HomeUI from './components/HomeUI';
import moment from 'moment';
import {selectUser} from '../../redux/slices/user';


const HomeEstadisticas = () => {
  const [loading, handleLoading] = useState(true);
  const [editorVisible, handleEditorVisible] = useState(false);
  const [dataDepartamento, setDataDepartamento] = useState([]);
  const [dataTipificacion, setDataTipificacion] = useState([]);
  const [dataPersonal, setDataPersonal] = useState([]);
  const [dataRestaurante, setDataRestaurante] = useState([]);
  const [dataGerenteZona, setDataGerenteZona] = useState([]);
  const [restaurantes, setRestaurantes] = useState([]);
  const [users, setUsers] = useState([]);
  const [datagrafica, setDataGrafica] = useState([]);

  const [resultAreaSoporte, setResultAreaSoporte] = useState([]);
  const [resultTipificacion, setResultTipificacion] = useState([]);
  const [resultUserTickets, setResultUserTickets] = useState([]);
  const [resultRestaurante, setResultRestaurante] = useState([]);
  const [resultUserGZ, setResultUserGZ] = useState([]);

  const user =  useSelector(selectUser);

  const dispatch = useDispatch();
  const uniqueByKey = (array, key) => {
    return [...new Map(array.map((x) => [x[key], x])).values()];
  }
  
  const getFirstDayOfMonth = (year, month)  => {
    return new Date(year, month, 1);
  }


  useEffect(() => {

   

    const initialFetch = async () => {
    
      const resultAreaSoporte = await getAreaSoporte();
      if(resultAreaSoporte.ok){
        setResultAreaSoporte(resultAreaSoporte.listadoAreaSoporte)
      }
      const resultTipificacion = await getTipificacion();
      if(resultTipificacion.ok){
        setResultTipificacion(resultTipificacion.listadoTipificacion)
      }

      const resultUserTickets = await getUserTickets();
      if(resultUserTickets.ok){
        setResultUserTickets(resultUserTickets.listadoUser)
      }

      const resultUserGZ = await getUserGZ();
      if(resultUserGZ.ok){
        setResultUserGZ(resultUserGZ.listadoUser)
      }
    

      const resultRestaurante = await getAllRestaurante();
      if(resultRestaurante.ok){
        setResultRestaurante(resultRestaurante.listadoUser)
      }

      const resultUsers = await getAllUser();
      if(resultUsers.ok){
        setUsers(resultUsers.listadoUser)
      }

      
      
    };

    initialFetch();
  }, [dispatch]);

  useEffect(() => {
      let today = new Date();
      let firstToday  = new Date();

      /*let FirstToday  = getFirstDayOfMonth(
        today.getFullYear(),
        today.getMonth(),
      );*/


      let fechaNueva =  new Date(moment(today).add(-6, 'day'));
      console.log("FirstToday",fechaNueva)

      var fechaInicio = new Date(fechaNueva.setHours(0,0,0,0));
      var fechaFinal = new Date(today.setHours(24,0,0,0));

      rellenarData(fechaInicio,fechaFinal)
  }, [users])

  const rellenarData = async (fechaInicio,fechaFinal) =>{
    var dataGra = []
    dataGra.push(["x", "Creados", "Finalizados"])
   
    var dataUser = ''
    if(user != undefined){
      dataUser = await getInfoUser(user.idUser)
    }

    const resultInicio = await getListTicketsActividadInicio(fechaInicio,fechaFinal,dataUser);
    const resultFin = await getListTicketsActividadFin(fechaInicio,fechaFinal,dataUser);

    
    var dataTipificacion = []
    var dataPersonal = []
    var dataDepartamento = []
    var dataRestaurantes = []
    var dataGerenteZona= []
    
    //console.log("result",result)
    if(resultInicio.ok && resultFin.ok){
      var listadoTicketInicio = resultInicio.listadoTicket
      var listadoTicketFin = resultFin.listadoTicket


      var fechasUnicasInicio = uniqueByKey(listadoTicketInicio, 'fechaCreadoGrafica')
      var fechasUnicasFin = uniqueByKey(listadoTicketFin, 'fechaFinalizadoGrafica')
      var fechasTodas = []
      var fechasOnlyGrafica = []
    
     

      var z = 0;
      while (z < fechasUnicasInicio.length) {
        fechasTodas.push(fechasUnicasInicio[z])
        ++z;
      }

      var zz = 0;
      while (zz < fechasUnicasFin.length) {
        let findFecha = fechasTodas.filter( request => (request.fechaCreadoGrafica == fechasUnicasFin[zz].fechaFinalizadoGrafica));
        if(findFecha.length == 0){
          fechasTodas.push(fechasUnicasFin[zz])
        }
        ++zz;
      }



      var j = 0;
      while (j < fechasTodas.length) {
        let cantidadCreados = listadoTicketInicio.filter( request => (request.fechaCreadoGrafica == fechasTodas[j].fechaCreadoGrafica));
        let cantidadFinalizados = listadoTicketFin.filter( request => (request.fechaFinalizadoGrafica == fechasTodas[j].fechaCreadoGrafica));

        dataGra.push([fechasTodas[j].fechaCreadoGrafica, cantidadCreados.length, cantidadFinalizados.length])
        ++j;
      }

      
      //data personal
      var listadoP = resultUserTickets
      //console.log("!!====",listadoP)
      var t = 0;
      while (t < listadoP.length) {

        var item = listadoP[t]

        var abiertos = listadoTicketInicio.filter( request => (request.idUserAsignado ==  "" && request.idUserAsignado == item.id));
        //var asignados = listadoTicketInicio.filter( request => (request.idUserAsignado !=  "" && request.idUserAsignado == item.id));
        var atrasados = listadoTicketInicio.filter( request => (request.retraso ==  1 && request.fechaFinalizado ==  "" && request.idUserAsignado == item.id));
        var finalizados = listadoTicketFin.filter( request => (request.fechaFinalizado !=  "" && request.idUserAsignado == item.id));
        var totalTicket = listadoTicketInicio.filter( request => (request.idUserAsignado == item.id));
        var nombre = item.nombre
        var area = resultAreaSoporte.find( request => (request.id == item.categoria));

        if(typeof area !== 'undefined'){
            nombre = nombre + " ("+area.nombre+")"
        }

        var cumplimiento = 0;

       
        if (totalTicket.length === 0) {
          cumplimiento = 100;
        } else {
          cumplimiento = (finalizados.length / totalTicket.length) * 100;

          if (cumplimiento > 100) {
            cumplimiento = 100;
          }
        }
        
        if(dataUser.infoUser.rol == "SupervisorTicket"){

          if(item.categoria != undefined){
            var itemFind = dataUser.infoUser.categoriaST.find(data => data === item.categoria.id)
            if(typeof itemFind !== 'undefined'){
              dataPersonal.push({
                nombre:item.nombreCompleto,
                abiertos:abiertos.length,
                //asignados:asignados.length,
                atrasados:atrasados.length,
                finalizados:finalizados.length,
                totalTicket:totalTicket.length,
                cumplimiento: cumplimiento.toFixed(2)
              })
            }
          }
          
            
          

        }else{
          dataPersonal.push({
            nombre:item.nombreCompleto,
            abiertos:abiertos.length,
            //asignados:asignados.length,
            atrasados:atrasados.length,
            finalizados:finalizados.length,
            totalTicket:totalTicket.length,
            cumplimiento: cumplimiento.toFixed(2)
          })
        }
        ++t;
      }

      //tipificacion
      var listadoT = resultTipificacion
      var t = 0;
      while (t < listadoT.length) {

        var item = listadoT[t]
       
        var abiertos = listadoTicketInicio.filter( request => (request.idUserAsignado ==  "" && request.tipificacion == item.id));
        var asignados = listadoTicketInicio.filter( request => (request.idUserAsignado !=  "" && request.tipificacion == item.id));
        var atrasados = listadoTicketInicio.filter( request => (request.retraso ==  1 && request.fechaFinalizado ==  "" && request.tipificacion == item.id));
        var finalizados = listadoTicketFin.filter( request => (request.fechaFinalizado !=  "" && request.tipificacion == item.id));
        var totalTicket = listadoTicketInicio.filter( request => (request.tipificacion == item.id));
        var nombre = item.nombre
        var area = resultAreaSoporte.find( request => (request.id == item.categoria));

        if(typeof area !== 'undefined'){
           nombre = nombre + " ("+area.nombre+")"
        }


        var cumplimiento = 0;

       
        if (totalTicket.length === 0) {
          cumplimiento = 100;
        } else {
          cumplimiento = (finalizados.length / totalTicket.length) * 100;

          if (cumplimiento > 100) {
            cumplimiento = 100;
          }
        }

        if(dataUser.infoUser.rol == "SupervisorTicket"){
            
          var itemFind = dataUser.infoUser.categoriaST.find(data => data === item.categoria)
          if(typeof itemFind !== 'undefined'){
            dataTipificacion.push({
              nombre:item.nombre,
              abiertos:abiertos.length,
              asignados:asignados.length,
              atrasados:atrasados.length,
              finalizados:finalizados.length,
              totalTicket:totalTicket.length,
              cumplimiento: cumplimiento.toFixed(2)
            })
          }

        }else{
          dataTipificacion.push({
            nombre:nombre,
            abiertos:abiertos.length,
            asignados:asignados.length,
            atrasados:atrasados.length,
            finalizados:finalizados.length,
            totalTicket:totalTicket.length,
            cumplimiento: cumplimiento.toFixed(2)
          })
        }
        
        

        ++t;
      }

      //estructura tabla departamento
     
      var l = 0;
      while (l < resultAreaSoporte.length) {
        var item = resultAreaSoporte[l]
     
        var abiertos = listadoTicketInicio.filter( request => (request.idUserAsignado ==  "" && request.categoria == "AreaSoporte/"+item.id));
        var asignados = listadoTicketInicio.filter( request => (request.idUserAsignado !=  "" && request.categoria == "AreaSoporte/"+item.id));
        var atrasados = listadoTicketInicio.filter( request => (request.retraso ==  1 && request.fechaFinalizado ==  "" && request.categoria == "AreaSoporte/"+item.id));
        var finalizados = listadoTicketFin.filter( request => (request.fechaFinalizado !=  "" && request.categoria == "AreaSoporte/"+item.id));
        var totalTicket = listadoTicketInicio.filter( request => (request.categoria == "AreaSoporte/"+item.id));
       
        var tiempoAsignarse = 0
        var tiempoIniciar = 0
        var tiempoFinalizado = 0

        var tiempoAsignarseContador = 0
        var tiempoIniciarContador = 0
        var tiempoFinalizadoContador = 0

        var m = 0;
        while (m < totalTicket.length) {
          var itemT = totalTicket[m]
          if(itemT.tiempoAsignarse != undefined){
            tiempoAsignarse = tiempoAsignarse + (itemT.tiempoAsignarse != undefined ? itemT.tiempoAsignarse : 0)
            tiempoAsignarseContador = tiempoAsignarseContador + 1
          }

          if(itemT.tiempoIniciar != undefined){
            tiempoIniciar = tiempoIniciar + (itemT.tiempoIniciar != undefined ? itemT.tiempoIniciar : 0)
            tiempoIniciarContador = tiempoIniciarContador + 1
          }

          if(itemT.tiempoFinalizado != undefined){
            tiempoFinalizado = tiempoFinalizado + (itemT.tiempoFinalizado != undefined ? itemT.tiempoFinalizado : 0)
            tiempoFinalizadoContador = tiempoFinalizadoContador + 1
          }
         
          ++m;
        }

        tiempoAsignarse = tiempoAsignarse == 0 ? 0 : (tiempoAsignarse*60) / tiempoAsignarseContador
        tiempoIniciar  = tiempoIniciar == 0 ? 0 :  (tiempoIniciar*60) / tiempoIniciarContador
        tiempoFinalizado  = tiempoFinalizado == 0 ? 0 :  (tiempoFinalizado*60) / tiempoFinalizadoContador

        var cumplimiento = 0;

       
          if (totalTicket.length === 0) {
            cumplimiento = 100;
          } else {
            cumplimiento = (finalizados.length / totalTicket.length) * 100;

            if (cumplimiento > 100) {
              cumplimiento = 100;
            }
          }

          if(dataUser.infoUser.rol == "SupervisorTicket"){
            
            var itemFind = dataUser.infoUser.categoriaST.find(data => data === item.id)
            if(typeof itemFind !== 'undefined'){
              dataDepartamento.push({
                nombre:item.nombre,
                abiertos:abiertos.length,
                asignados:asignados.length,
                atrasados:atrasados.length,
                finalizados:finalizados.length,
                totalTicket:totalTicket.length,
                tiempoAsignarse:tiempoAsignarse,
                tiempoIniciar:tiempoIniciar,
                tiempoFinalizado:tiempoFinalizado,
                cumplimiento: cumplimiento.toFixed(2)
              })
            }

          }else{
            dataDepartamento.push({
              nombre:item.nombre,
              abiertos:abiertos.length,
              asignados:asignados.length,
              atrasados:atrasados.length,
              finalizados:finalizados.length,
              totalTicket:totalTicket.length,
              tiempoAsignarse:tiempoAsignarse,
              tiempoIniciar:tiempoIniciar,
              tiempoFinalizado:tiempoFinalizado,
              cumplimiento: cumplimiento.toFixed(2)
            })
          }

        ++l;
      }

      //estructura tabla restaurante
      var w = 0;
      while (w < resultRestaurante.length) {
        var item = resultRestaurante[w]
        
        var abiertos = listadoTicketInicio.filter( request => (request.idUserAsignado ==  "" && request.tiendaFind == "Tiendas/"+item.id));
        var asignados = listadoTicketInicio.filter( request => (request.idUserAsignado !=  "" && request.tiendaFind == "Tiendas/"+item.id));
        var atrasados = listadoTicketInicio.filter( request => (request.retraso ==  1 && request.fechaFinalizado ==  "" && request.tiendaFind == "Tiendas/"+item.id));
        var finalizados = listadoTicketFin.filter( request => (request.fechaFinalizado !=  "" && request.tiendaFind == "Tiendas/"+item.id));
        var totalTicket = listadoTicketInicio.filter( request => (request.tiendaFind == "Tiendas/"+item.id));

       
        var tiempoAsignarse = 0
        var tiempoIniciar = 0
        var tiempoFinalizado = 0

        var tiempoAsignarseContador = 0
        var tiempoIniciarContador = 0
        var tiempoFinalizadoContador = 0

        var m = 0;
        while (m < totalTicket.length) {
          var itemT = totalTicket[m]
          if(itemT.tiempoAsignarse != undefined){
            tiempoAsignarse = tiempoAsignarse + (itemT.tiempoAsignarse != undefined ? itemT.tiempoAsignarse : 0)
            tiempoAsignarseContador = tiempoAsignarseContador + 1
          }

          if(itemT.tiempoIniciar != undefined){
            tiempoIniciar = tiempoIniciar + (itemT.tiempoIniciar != undefined ? itemT.tiempoIniciar : 0)
            tiempoIniciarContador = tiempoIniciarContador + 1
          }

          if(itemT.tiempoFinalizado != undefined){
            tiempoFinalizado = tiempoFinalizado + (itemT.tiempoFinalizado != undefined ? itemT.tiempoFinalizado : 0)
            tiempoFinalizadoContador = tiempoFinalizadoContador + 1
          }
         
          ++m;
        }

        tiempoAsignarse = tiempoAsignarse == 0 ? 0 : (tiempoAsignarse*60) / tiempoAsignarseContador
        tiempoIniciar  = tiempoIniciar == 0 ? 0 :  (tiempoIniciar*60) / tiempoIniciarContador
        tiempoFinalizado  = tiempoFinalizado == 0 ? 0 :  (tiempoFinalizado*60) / tiempoFinalizadoContador


        var cumplimiento = 0;

       
          if (totalTicket.length === 0) {
            cumplimiento = 100;
          } else {
            cumplimiento = (finalizados.length / totalTicket.length) * 100;

            if (cumplimiento > 100) {
              cumplimiento = 100;
            }
          }

          if(dataUser.infoUser.rol == "SupervisorTicket"){
            
            var itemFind = dataUser.infoUser.categoriaST.find(data => data === item.id)
            if(typeof itemFind !== 'undefined'){
              dataRestaurantes.push({
                nombre:item.nombre,
                abiertos:abiertos.length,
                asignados:asignados.length,
                atrasados:atrasados.length,
                finalizados:finalizados.length,
                totalTicket:totalTicket.length,
                tiempoAsignarse:tiempoAsignarse,
                tiempoIniciar:tiempoIniciar,
                tiempoFinalizado:tiempoFinalizado,
                cumplimiento: cumplimiento.toFixed(2)
              })
            }

          }else{
            dataRestaurantes.push({
              nombre:item.nombre,
              abiertos:abiertos.length,
              asignados:asignados.length,
              atrasados:atrasados.length,
              finalizados:finalizados.length,
              totalTicket:totalTicket.length,
              tiempoAsignarse:tiempoAsignarse,
              tiempoIniciar:tiempoIniciar,
              tiempoFinalizado:tiempoFinalizado,
              cumplimiento: cumplimiento.toFixed(2)
            })
          }

        ++w;
      }
      
     //estructura tabla gerente zona
     var xw = 0;
     console.log("resultUserGZ",resultUserGZ)
     while (xw < resultUserGZ.length) {
       var item = resultUserGZ[xw]
       
       var abiertos = listadoTicketInicio.filter(request => request.idUserAsignado == "" && item.tiendaList.includes(request.tiendaFind));
       var asignados = listadoTicketInicio.filter(request => request.idUserAsignado != "" && item.tiendaList.includes(request.tiendaFind));
       var atrasados = listadoTicketInicio.filter(request => (request.retraso ==  1 && request.fechaFinalizado ==  "" && item.tiendaList.includes(request.tiendaFind)));
       var finalizados = listadoTicketFin.filter( request => (request.fechaFinalizado !=  "" && item.tiendaList.includes(request.tiendaFind)));
       var totalTicket = listadoTicketInicio.filter( request => (item.tiendaList.includes(request.tiendaFind)));

       //var abiertos = listadoTicketInicio.filter( request => (request.idUserAsignado ==  "" && request.tiendaFind == "Tiendas/"+item.id));
       //var asignados = listadoTicketInicio.filter( request => (request.idUserAsignado !=  "" && request.tiendaFind == "Tiendas/"+item.id));
       //var atrasados = listadoTicketInicio.filter( request => (request.retraso ==  1 && request.fechaFinalizado ==  "" && request.tiendaFind == "Tiendas/"+item.id));
       //var finalizados = listadoTicketFin.filter( request => (request.fechaFinalizado !=  "" && request.tiendaFind == "Tiendas/"+item.id));
       //var totalTicket = listadoTicketInicio.filter( request => (request.tiendaFind == "Tiendas/"+item.id));

      
       var tiempoAsignarse = 0
       var tiempoIniciar = 0
       var tiempoFinalizado = 0

       var tiempoAsignarseContador = 0
       var tiempoIniciarContador = 0
       var tiempoFinalizadoContador = 0

       var m = 0;
       while (m < totalTicket.length) {
         var itemT = totalTicket[m]
         if(itemT.tiempoAsignarse != undefined){
           tiempoAsignarse = tiempoAsignarse + (itemT.tiempoAsignarse != undefined ? itemT.tiempoAsignarse : 0)
           tiempoAsignarseContador = tiempoAsignarseContador + 1
         }

         if(itemT.tiempoIniciar != undefined){
           tiempoIniciar = tiempoIniciar + (itemT.tiempoIniciar != undefined ? itemT.tiempoIniciar : 0)
           tiempoIniciarContador = tiempoIniciarContador + 1
         }

         if(itemT.tiempoFinalizado != undefined){
           tiempoFinalizado = tiempoFinalizado + (itemT.tiempoFinalizado != undefined ? itemT.tiempoFinalizado : 0)
           tiempoFinalizadoContador = tiempoFinalizadoContador + 1
         }
        
         ++m;
       }

       tiempoAsignarse = tiempoAsignarse == 0 ? 0 : (tiempoAsignarse*60) / tiempoAsignarseContador
       tiempoIniciar  = tiempoIniciar == 0 ? 0 :  (tiempoIniciar*60) / tiempoIniciarContador
       tiempoFinalizado  = tiempoFinalizado == 0 ? 0 :  (tiempoFinalizado*60) / tiempoFinalizadoContador


      var cumplimiento = 0;

  
      if (totalTicket.length === 0) {
        cumplimiento = 100;
      } else {
        cumplimiento = (finalizados.length / totalTicket.length) * 100;

        if (cumplimiento > 100) {
          cumplimiento = 100;
        }
      }

      if(dataUser.infoUser.rol == "SupervisorTicket"){
        
        var itemFind = dataUser.infoUser.categoriaST.find(data => data === item.id)
        if(typeof itemFind !== 'undefined'){
          dataGerenteZona.push({
            nombre:item.nombre,
            abiertos:abiertos.length,
            asignados:asignados.length,
            atrasados:atrasados.length,
            finalizados:finalizados.length,
            totalTicket:totalTicket.length,
            tiempoAsignarse:tiempoAsignarse,
            tiempoIniciar:tiempoIniciar,
            tiempoFinalizado:tiempoFinalizado,
            cumplimiento: cumplimiento.toFixed(2)
          })
        }

      }else{
        dataGerenteZona.push({
          nombre:item.nombre,
          abiertos:abiertos.length,
          asignados:asignados.length,
          atrasados:atrasados.length,
          finalizados:finalizados.length,
          totalTicket:totalTicket.length,
          tiempoAsignarse:tiempoAsignarse,
          tiempoIniciar:tiempoIniciar,
          tiempoFinalizado:tiempoFinalizado,
          cumplimiento: cumplimiento.toFixed(2)
        })
      }

      ++xw;
     }
      
      
    }

    console.log("dataGra!!",dataGra)

    if(dataGra.length == 1){
      dataGra.push(["No Data", 0, 0])

      console.log("entro ======>")
    }


    setDataGrafica(dataGra)
    setDataDepartamento(dataDepartamento)
    setDataTipificacion(dataTipificacion)
    setDataPersonal(dataPersonal)
    setDataRestaurante(dataRestaurantes)
    setDataGerenteZona(dataGerenteZona)
    
    console.log("dataRestaurantes",dataRestaurantes)
    handleLoading(false);
  }

  const buscarTickets = async (tipo,startDate,endDate) => {
    handleLoading(true);
    var fechaInicio = ""
    var fechaFinal = ""

    if(tipo == "fecha"){
    
      let todayStartDate = new Date(moment(startDate,"DD/MM/YYYY"));
      let todayEndDate = new Date(moment(endDate,"DD/MM/YYYY"));

      console.log("startDate",todayStartDate)
      console.log("endDate",todayEndDate)

      var fechaInicio = new Date(todayStartDate.setHours(0,0,0,0));
      var fechaFinal = new Date(todayEndDate.setHours(24,0,0,0));

    }else if(tipo == "Hoy"){
      let today = new Date();
      var fechaInicio = new Date(today.setHours(0,0,0,0));
      var fechaFinal = new Date(today.setHours(24,0,0,0));
    }else if(tipo == "Ayer"){
      let today = new Date();
      let fechaNueva =  moment(today).add(-1, 'day');
      let fechaF = new Date(fechaNueva)
      var fechaInicio = new Date(fechaF.setHours(0,0,0,0));
      var fechaFinal = new Date(fechaF.setHours(24,0,0,0));
    }else if(tipo == "Últimos 7 días"){
      let today = new Date();
      let fechaNueva =  moment(today).add(-6, 'day');
      let fechaF = new Date(fechaNueva)
      var fechaInicio = new Date(fechaF.setHours(0,0,0,0));
      var fechaFinal = new Date(today.setHours(24,0,0,0));
    }else if(tipo == "Últimos 30 días"){
      let today = new Date();
      let fechaNueva =  moment(today).add(-29, 'day');
      let fechaF = new Date(fechaNueva)
      var fechaInicio = new Date(fechaF.setHours(0,0,0,0));
      var fechaFinal = new Date(today.setHours(24,0,0,0));
    }

    

    /*let today = new Date();
    let FirstToday  = getFirstDayOfMonth(
      today.getFullYear(),
      today.getMonth(),
    );

    var fechaInicio = new Date(FirstToday.setHours(0,0,0,0));
    var fechaFinal = new Date(today.setHours(24,0,0,0));*/

    rellenarData(fechaInicio,fechaFinal)

  };



  return (
    <HomeUI
      loading={loading}
      dataDepartamento={dataDepartamento}
      dataTipificacion={dataTipificacion}
      dataPersonal={dataPersonal}
      dataRestaurante={dataRestaurante}
      dataGerenteZona={dataGerenteZona}
      users={users}
      datagrafica={datagrafica}
      buscarTickets={buscarTickets}
    />
  );
}

export default HomeEstadisticas;
