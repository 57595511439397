import {useRef,useState,useEffect} from 'react';
import {Button, Table, Spin, Modal, Col, Row,Form,Input,Select,TreeSelect,message} from 'antd';
import Header from '../../../components/HeaderList';
import {ReactComponent as Edit} from '../../../assets/icons/edit.svg';
import {ReactComponent as Trash} from '../../../assets/icons/trash.svg';
import styles from '../styles/CategoriasUI.module.css';
import {validateFloatKeyPress} from '../../../utils/format';
import moment from 'moment';
import { updateDocument,createDocument} from '../../../firebase/queryProvider';

const {Option} = Select;
const {TreeNode} = TreeSelect;

const CategoriasUi = props => {  
  const [form] = Form.useForm();
  const [sending, handleSending] = useState(false);

  

  const {
    loading,
    categorias,
    item,
    isModalDetails,
    cancelModalDetails,
    showDetails,
    newCategoria,
    confirmDelete,
    actualizarData,
    agregarData
  } = props;

  useEffect(() => {
    const initialFetch = async () => {
      if (item) {
        
        
        const newValues = {
          name: item.nombre,
          webID: item.webID,
          departamento: ""
        };
        form.setFieldsValue(newValues);
      }
    };
    initialFetch();
  }, [item]);

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      align: 'left',
    },
    {
      title: 'Editar',
      dataIndex: 'id',
      width: 120,
      render: (id, record) => (
        <div className={styles.actions}>
          <div className={styles.edit} onClick={() => showDetails(record)}>
            <Edit />
          </div>
          <div className={styles.divider} />
          <div className={styles.delete} onClick={() => confirmDelete(id)}>
            <Trash />
          </div>
          
        </div>
      )
    },
    
  ];


  const save = async () => {
    console.log("aqui!!!")
    try {
      //handleSending(true);
      const values = await form.validateFields();
      console.log("values",values)
      
      var itemSend = {}
      itemSend.nombre = values.name
      itemSend.tipo = 1
      itemSend.fecha_actualizacion = moment(new Date(), 'DD/MM/YYYY').toDate()


      if(item.nuevo == undefined){
       
        const response =  await updateDocument(itemSend,`/Categorias/${item.id}`)
        console.log("response",response)
        if (response.ok === true) {
          message.success('Cambios realizados con éxito');
          itemSend.id = item.id
          itemSend.activo = true
          
          handleSending(false)
          actualizarData(itemSend)
          cancelModalDetails()
          form.resetFields();
        } else {
          message.error('¡Hubo un problema! Inténtalo de nuevo');
        }

          
      }else{
        itemSend.fecha_creado = moment(new Date(), 'DD/MM/YYYY').toDate()
        itemSend.descripcion = ""
        itemSend.activo = true

        const response =  await createDocument(`Categorias`,itemSend)
        console.log("response",response)
        var idUsuario = response.info.id

        
        itemSend.id = idUsuario
        console.log("itemSend",itemSend)
        
        message.success('Categoría creada');
        handleSending(false)
        agregarData(itemSend)
        cancelModalDetails()
        form.resetFields();
      }

    } catch (e) {

    }

  };

  return (
    <div className={styles.view}>
      <Header
        title="Listado de Categorias"
        actions={
          <Button
            onClick={newCategoria}
            type="primary"
            size="large"
            className="main-buttonHeader">
            AGREGAR CATEGORÍA
          </Button>
        }
      />
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Table
            columns={columns}
            dataSource={categorias}
            rowKey="id"
          />
        </div>
      )}


      {
          item != null ?
          <Modal 
          className='primary-modal'
          title="Categoría"
          width={650}
          visible={isModalDetails}
          onCancel={() => cancelModalDetails()}
          footer = { <Row>

            <Col span={6} style={{textAlign:"left"}}>
              <Button 
                    // disabled={requests.isSaving} 
                    className={styles.btCerrar}
                    type="primary" 
                    shape="round" 
                    style={{ width: "90%" }} 
                    onClick={() => { 
                      cancelModalDetails()
                    }}
                    >
                  Cerrar
              </Button>
                
              
            </Col>

            <Col span={12} style={{textAlign:"right"}}>
              
            </Col>

            <Col span={6} style={{textAlign:"right"}}>
            

              <Button
                  loading={sending}
                  onClick={() => {
                    save()
                  }}
                  type="primary" 
                  shape="round" 
                  htmlType="submit"
                  style={{ width: "90%" }}
                  >
                  Guardar
              </Button>
            </Col>
          </Row> }>

          <Form
        name="branch-editor"
        autoComplete="off"
        form={form}
        colon={false}
        requiredMark={false}
        onFinish={save}
      >

        <Row gutter={20}>
          

          <Col span={24}>
            <Form.Item
              label={
                <span className={styles.label}>
                  Nombre
                </span>
              }
              name="name"
              className={styles.itemColumn}
              rules={[{required: true, message: 'Ingresar Nombre'}]}
            >
              <Input size="large" className={styles.input} />
            </Form.Item>
          </Col>

         

        </Row>
       
        
      </Form>

          
          </Modal>
          : null
        }
    </div>
  );
};

export default CategoriasUi;
