import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {message, Modal} from 'antd';
import {getAllVehicles, selectVehicles, removeMonitorVehicle} from '../../redux/slices/vehicles';
import {getAllBranches, selectBranches} from '../../redux/slices/branches';
import MonitorUI from './components/MonitorUI';

const Monitor = () => {
  const [loading, handleLoading] = useState(false);
  const [editorVisible, handleEditorVisible] = useState(false);
  const [vehicle, handleVehicle] = useState(null);
  const vehicles = useSelector(selectVehicles);
  const branches = useSelector(selectBranches);
  const dispatch = useDispatch();

  useEffect(() => {
    const initialFetch = async () => {
      handleLoading(false);
    };
    initialFetch();
  }, [dispatch]);

  return (
    <MonitorUI
      loading={loading}
    />
  );
}

export default Monitor;
