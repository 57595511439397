import {useState, useRef,useEffect} from 'react';

import {Button, Table, Spin, Modal, Col, Row,DatePicker, Space} from 'antd';
import Header from '../../../components/HeaderList';
import {ReactComponent as Edit} from '../../../assets/icons/edit.svg';
import {ReactComponent as Trash} from '../../../assets/icons/trash.svg';
import {getColumnSearchProps} from '../../../utils/tables';
import styles from '../styles/BitacoraUI.module.css';
import locale from 'antd/es/date-picker/locale/es_ES';
import dayjs from 'dayjs';
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import { NavLink } from 'react-router-dom';
import XLSX from 'xlsx';
import {useSelector, useDispatch} from 'react-redux';

dayjs.extend(weekday)
dayjs.extend(localeData)


const dateFormat = 'DD/MM/YYYY';
const { RangePicker } = DatePicker;
const customFormat = (value) => `${value.format(dateFormat)}`;


const LogUI = props => {
  const searchInput = useRef(null);
  

  const {
    loading,
    logs,
    restaurantes,
    users,
    buscarFechas,
    loadingBuscar,
    fechaInicioG,
    fechaFinG,
  } = props;
  


  const [fechaInicio, setFechaInicio] = useState(props.fechaInicioG);
  const [fechaFin, setFechaFin] = useState(props.fechaFinG);
  const [fechaInicioSelect, setFechaInicioSelect] = useState(null);
  const [fechaFinSelect, setFechaFinSelect] = useState(null);
  const dispatch = useDispatch();

  const getUser = () => {
    const localities = [];
    users.map(d => {
      localities.push({
        text: d.nombre + " " +  d.apellido,
        value: d.nombre + " " +  d.apellido,
      });
    });
    return localities;
  };

  const getRestaurante = () => {
    const localities = [];
    restaurantes.map(d => {
      localities.push({
        text: d.nombre,
        value: d.nombre,
      });
    });
    return localities;
  };

  const geUserName = locality => {
   
    let name = "-";
    const index = users.findIndex(el => el.nombre + " " + el.apellido === locality);
    if (index > -1) {
      name = users[index].nombre + " " +  users[index].apellido;
    }
    return name;
  };

  const geRestauranteName = locality => {
    let name = "-";
    console.log("locality",locality)
    const index = restaurantes.findIndex(el => el.nombre === locality);
    if (index > -1) {
      name = restaurantes[index].nombre;
    }
    return name;
  };
 

  useEffect(() => {
    const initialFetch = async () => {
      console.log(props.fechaInicioG,"--------->")
      setFechaInicio(props.fechaInicioG)
      setFechaFin(props.fechaFinG)
    };
    initialFetch();
  }, [dispatch]);



  const onChangeFecha = (date, dateString) => {
    console.log(date, dateString);
    setFechaFin(dateString[1])
    setFechaInicio(dateString[0])
  };

  const columns = [
    {
      title: 'Fecha',
      dataIndex: 'fechaCreado',
      width: '200px',
    },
    {
      title: 'Tipo',
      align: 'left',
      dataIndex: 'tipo',
      render: (id, record) => (
        <div>
          <div>
           {record.tipo}
          </div>
        </div>
      ),
      ...getColumnSearchProps('tipo'),
    },
    {
      title: 'Asunto',
      align: 'left',
      dataIndex: 'asunto',
      render: (id, record) => (
        <div>
          <div>
           {record.asunto}
          </div>
        </div>
      ),
      ...getColumnSearchProps('asunto'),
    },
    {
      title: 'De',
      dataIndex: 'de',
      align: 'center',
      width: '200px',
      filterSearch: true,
      filters: getUser(),
      onFilter: (val, record) => record.de === val,
      /*onFilter: (val, record) => {
        console.log(record.de,val)
        //record.de === val
        record.de.startsWith(val)
      },*/
      render: val => geUserName(val, searchInput),
    },
    {
      title: 'Sucursal',
      dataIndex: 'restaurante',
      align: 'center',
      width: '200px',
      filterSearch: true,
      filters: getRestaurante(),
      onFilter: (val, record) => record.restaurante === val,
      render: val => geRestauranteName(val, searchInput),
    },
  ];

  const paginationOptions = {
    pageSize: 50, // mostrar 10 filas por página
    pageSizeOptions: ['10', '20', '30'], // permitir al usuario elegir entre 10, 20 o 30 filas por página
  };

  const exportToExcel = (data) => {
    var dataRecibida = [... data]
    

    const newArray = dataRecibida.map(obj => {
      const newObj = {};
      newObj.FechaCreado= obj.fechaCreado;
      newObj.Tipo = obj.tipo;
      newObj.Asunto = obj.asunto;
      newObj.Usuario = obj.de;
      newObj.Sucursal = obj.restaurante
      return newObj;
    });



    console.log(newArray)
    const workbook = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(newArray);
    XLSX.utils.book_append_sheet(workbook, sheet, 'Sheet1');
    XLSX.writeFile(workbook, 'historico_logs.xlsx');
  }

  return (
    <div className={styles.view}>
      <Header
        title="Listado de Logs"
      />
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <div style={{display:"flex",marginBottom:"20px"}}>
            <RangePicker format={customFormat} locale={locale} style={{
                  width: "400px",
                }}
                defaultValue={[dayjs(fechaInicio, dateFormat), dayjs(fechaFin, dateFormat)]}
                onChange={onChangeFecha} />
            <Button
              style={{marginLeft:"20px"}}
              size="large"
              onClick={() => {
                buscarFechas(fechaInicio,fechaFin)
              }}
              loading={loadingBuscar}
              type={'primary'}
              className={styles.submit}>
                BUSCAR
            </Button>
            <Button
              style={{marginLeft:"20px"}}
              size="large"
              onClick={() => {
                exportToExcel(logs)
              }}
              loading={loadingBuscar}
              type={'primary'}
              className={styles.submit}>
                Exportar a Excel
            </Button>

                
          </div>
          <Table
            columns={columns}
            dataSource={logs}
            rowKey="id"
            pagination={paginationOptions}
          />
        </div>
      )}
      
    </div>
  );
};

export default LogUI;
