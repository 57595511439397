import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {message, Modal} from 'antd';
import { getTareas,getAllCategorias,getAllSucursales,updateDocument} from '../../firebase/queryProvider';
import TareasUI from './components/TareasUI';


const Tareas = () => {
  const [loading, handleLoading] = useState(true);
  const [editorVisible, handleEditorVisible] = useState(false);
  const [tareas, setTareas] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [sucursales, setSucursales] = useState([]);
  const [item, setItem] = useState(null);
  const [isModalDetails, handleIsModalDetails] = useState(false);
  
  
  const dispatch = useDispatch();

  useEffect(() => {
   

    const initialFetch = async () => {
     
      const resultadoTipificacion = await getAllCategorias()
      if(resultadoTipificacion.ok){
        setCategorias(resultadoTipificacion.listadoCategorias)
      }

      const result = await getAllSucursales();
      if(result.ok){
        setSucursales(result.listadoUser)
      }



      const resultTarea = await getTareas()
      if(resultTarea.ok){
        setTareas(resultTarea.listadoTareas)
      }

    
      handleLoading(false);
    };

    initialFetch();
  }, [dispatch]);

  const handleDetail = async (item) => {
    setItem(item)
    handleIsModalDetails(true)
  };

  const handleCancelModalDetails = async () => {
    handleIsModalDetails(false)
    setItem(null)
  };

  const newTarea = async () => {
    var item = {}
    item.nuevo = true
    setItem(item)
    handleIsModalDetails(true)
    console.log(categorias)
  };

  const agregarData = async (data) => {
    var alltareas = [... tareas]
    alltareas.unshift(data)
    setTareas(alltareas)
  }

  const actualizarData = async (data) => {
   
    var allTareas = [... tareas]
    var itemTarea = allTareas.find((item) => item.id == data.id)
    

    if(typeof itemTarea !== 'undefined'){
      var index = allTareas.indexOf(itemTarea);
      if (index != -1) {
        allTareas[index] = data
      }
    }
    setTareas(allTareas)
    setItem(null)
  };

  const confirmDelete = (idTarea) => {
    Modal.confirm({
      title: '¿Estás segúro de eliminar esta tarea?',
      content: 'Esta información no se podrá recuperar.',
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        await removeTarea(idTarea);
      },
      onCancel() {},
    });
  };

  const removeTarea = async (idTarea) => {
    const info = {
      activo: false
    };

    const response =  await updateDocument(info,`/Tareas/${idTarea}`)
    console.log("response",response)
    if (response.ok === true) {
      var allTarea = [... tareas]

      const index = allTarea.findIndex(el => el.id === idTarea);
      if (index > -1) {
        allTarea.splice(index, 1);
      }

      setTareas(allTarea)

      message.success('Tarea eliminada correctamente');
    }else{
      message.error('¡Hubo un problema!');
    }
  };

  return (
    <TareasUI
      loading={loading}
      tareas={tareas}
      isModalDetails={isModalDetails}
      cancelModalDetails={handleCancelModalDetails}
      showDetails={handleDetail}
      item={item}
      categorias={categorias}
      sucursales={sucursales}
      newTarea={newTarea}
      actualizarData={actualizarData}
      agregarData={agregarData}
      confirmDelete={confirmDelete}
    />
  );
}

export default Tareas;
