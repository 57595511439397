import {useRef,useState,useEffect,useCallback} from 'react';
import {Button, Table, Spin, Modal, Col, Row,Form,Input,Select,message,Upload,DatePicker} from 'antd';
import Header from '../../../components/HeaderList';
import {getColumnSearchProps} from '../../../utils/tables';
import Editor from './Editor';
import styles from '../styles/MonitorUI.module.css';
import ImageViewer from 'react-simple-image-viewer';
import {useSelector, useDispatch} from 'react-redux';
import { getRutasHistorico,updateDocument} from '../../../firebase/queryProvider';
import {selectUser} from '../../../redux/slices/user';
import moment from 'moment';
import instance from "../../../api/Request";
import locale from 'antd/es/date-picker/locale/es_ES';
import dayjs from 'dayjs';
import {ReactComponent as Edit} from '../../../assets/icons/edit.svg';
import {ReactComponent as Trash} from '../../../assets/icons/trash.svg';
import { EditOutlined } from '@ant-design/icons';

import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import { NavLink } from 'react-router-dom';
import XLSX from 'xlsx';

dayjs.extend(weekday)
dayjs.extend(localeData)


const {Option} = Select;
const { TextArea } = Input;

const dateFormat = 'DD/MM/YYYY';
const customFormat = (value) => `${value.format(dateFormat)}`;

const RutasHistoricoUI = props => {
  const [loading, handleLoading] = useState(true);
  const [sending, handleSending] = useState(false);
  const [form] = Form.useForm();
  const [tipificacionSelect, setTipificacionSelect] = useState([]);
  const [isModalAsignar, setIsModalAsignar] = useState(false);
  const [loadingAsignar, setLoadingAsignar] = useState(false);
  const [rutas, setRutas] = useState([]);
  const [ubicaciones, setUbicaciones] = useState([]);
  const [item, setItem] = useState(null);
  const [loadingBuscar, handleLoadingBuscar] = useState(false);
  const [fechaInicio, setFechaInicio] = useState(false);
  const [fechaFin, setFechaFin] = useState(false);
  const [fechaInicioSelect, setFechaInicioSelect] = useState(null);
  const [fechaFinSelect, setFechaFinSelect] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState(false);

  const dispatch = useDispatch();
  
  const user = useSelector(selectUser);
  const dateFormat = 'DD/MM/YYYY';
  const { RangePicker } = DatePicker;
  
  const onChangeFecha = (date, dateString) => {
    //console.log(date, dateString,"!!!!!");
    setFechaInicioSelect(date[0])
    setFechaFinSelect(date[1])
  };


  const [status, setStatus] = useState(null); 
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [comment, setComment] = useState("");
  const [selectedId, setSelectedId] = useState(null)

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
     setLoadingStatus(true);
      setStatus(selectedStatus);

      var itemSend = {}
      itemSend.status = status;  // el estado del select
      if(status === "CON OBSERVACIÓN" || status === "RECHAZADO") {
        itemSend.commentStatus = comment;  // el valor del textarea
      }else{
        itemSend.commentStatus = "";  // el valor del textarea
      }
      
      try {
        await updateDocument(itemSend,`/RegistroRutas/${selectedId}`); // Usa el id seleccionado
        // Actualizar en el estado local
        setRutas(prevRutas => 
          prevRutas.map(ruta => 
              ruta.id === selectedId 
                  ? {...ruta, status, commentStatus: itemSend.commentStatus} 
                  : ruta
          )
        );

        // Si la actualización fue exitosa, cierra el modal
        setIsModalVisible(false);
      } catch(error) {
        console.error("Error al actualizar el documento:", error);
      } finally {
        setLoadingStatus(false);
      }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleStatusChange = (record) => {
    console.log(record.commentStatus )
    setSelectedId(record.id); // Guarda el id del registro seleccionado
    setStatus(record.status ? record.status : "SOLICITADO")
    setComment(record.commentStatus ? record.commentStatus : "")
    showModal();
  };

  const handleSelectChange = (value) => {
    setStatus(value);
    if(value === "Con observación" || value === "Rechazado"){
      showModal();
    }
  };


  useEffect(() => {
    const initialFetch = async () => {

      let today = new Date();
      let fechaNueva =  new Date(moment(today).add(-6, 'day'));

      var fechaInicio = new Date(fechaNueva.setHours(0,0,0,0));
      var fechaFinal = new Date(new Date().setHours(23,59,59,59));
      fechaFinal.setHours(23); // establece la hora a las 23:00
      fechaFinal.setMinutes(59); // establece los minutos a 59
      fechaFinal.setSeconds(59); // establece los segundos a 59

      var formattedDate = moment(fechaInicio).format(dateFormat);
      var formattedDateF = moment(fechaFinal).format(dateFormat);
      
      setFechaInicio(formattedDate)
      setFechaFin(formattedDateF)

      setFechaInicioSelect(fechaInicio)
      setFechaFinSelect(fechaFinal)
      const result = await getRutasHistorico(fechaInicio,fechaFinal);
      
      if(result.ok){
        setRutas(result.listadoRutas)
      }else{
        message.error('¡Hubo un problema!');
      }

      handleLoading(false);
    };
    initialFetch();
  }, [dispatch]);

  const buscarFechas = async(fechaInicio,fechaFin) => {


    let today = new Date(fechaInicio);
    let fechaNueva =  new Date(fechaFin);
    fechaNueva.setHours(23); // establece la hora a las 23:00
    fechaNueva.setMinutes(59); // establece los minutos a 59
    fechaNueva.setSeconds(59); // establece los segundos a 59

    console.log("fecha ==>",fechaInicio,fechaFin)

    
    handleLoadingBuscar(true);
    const result = await getRutasHistorico(today,fechaNueva);
    console.log("result",result)
    if(result.ok){
      setRutas(result.listadoRutas)
    }else{
      message.error('¡Hubo un problema!');
    }
    handleLoadingBuscar(false);

  }


  const openEditor = (item) => {
    setItem(item);

    const newValues = {
      origen: item.origen,
      destino: item.destino,
      recorrido: item.recorrido
    };
    form.setFieldsValue(newValues);

    setIsModalAsignar(true);
  };

  const columns = [
    {
      title: 'Fecha Inicio',
      dataIndex: 'fechaCreado',
      align: 'left'
    },
    {
      title: 'Fecha Fin',
      dataIndex: 'fechaFinalizado',
      align: 'left'
    },
    {
      title: 'Tiempo transcurrido',
      dataIndex: 'tiempoTranscurrido',
      align: 'left',
      sorter: (a, b) => {
        // convertir las fechas a objetos Date
        const fechaInicioA = new Date(a.fechaInicio);
        const fechaInicioB = new Date(b.fechaInicio);
        const fechaFinA = new Date(a.fechaFin);
        const fechaFinB = new Date(b.fechaFin);
  
        // calcular la diferencia de tiempo en minutos
        const diffA = (fechaFinA - fechaInicioA) / 1000 / 60;
        const diffB = (fechaFinB - fechaInicioB) / 1000 / 60;
        console.log("fechaInicioA",diffA - diffB)

        return diffA - diffB;
      },
    },
    {
      title: 'Origen',
      dataIndex: 'origenNombre',
      align: 'left',
      ...getColumnSearchProps('origenNombre'),
    },
    {
      title: 'Destino',
      dataIndex: 'destinoNombre',
      ...getColumnSearchProps('destinoNombre'),
    },
    {
      title: ' Kilometraje Establecido',
      dataIndex: 'recorridoEstimado',
      render: (id, record) => (
        <div>
          {record.recorridoEstimado}
        </div>
      ),
    },
    {
      title: 'Kilometraje GPS',
      dataIndex: 'recorridoGPS',
      render: (id, record) => (
        <div>
          {record.recorridoGPS}
        </div>
      ),
    },
    {
      title: 'Usuario',
      dataIndex: 'de',
      render: (id, record) => (
        <div>
          {record.de}
        </div>
      ),
      ...getColumnSearchProps('de'),
    },
    {
      title: 'Comentario',
      dataIndex: 'comentario',
      render: (id, record) => (
        <div>
          {record.comentario}
        </div>
      ),
      ...getColumnSearchProps('comentario'),
    },
    {
      title: 'Ver',
      align: 'center',
      dataIndex: 'id',
      render: (id, record) => (
        <div className={styles.actions}>
          <NavLink to={`/monitor/mapa/${id}`}>
              <div>MAPA</div>
          </NavLink>
        </div>
      ),
    },
  ];


  if(user.idUser == "TpNVWvSveQoPgJKVaTi5" || user.rol == "GerenteOperaciones") {
    const newColumn = {
      title: 'Estado',
      dataIndex: 'estatus',
      render: (text, record) => (
        <div style={{display: "flex", alignItems: "center"}}>
          <div style={{marginRight: "10px"}}>
          {record.status ? record.status : "SOLICITADO"}
          {record.commentStatus && <><br />{record.commentStatus}</>}
          </div>
          {record.status !== "Pagado" && (
            <EditOutlined 
              onClick={() => handleStatusChange(record, "Nuevo estado")} 
            />
          )}
        </div>
      ),
      ...getColumnSearchProps('statusFin'),
    };
    
    columns.splice(columns.length - 2, 0, newColumn); // Esto insertará newColumn en la antepenúltima posición.
  }

  const save = () => {
    
  }

  const paginationOptions = {
    pageSize: 100, // mostrar 10 filas por página
    pageSizeOptions: ['10', '20', '30'], // permitir al usuario elegir entre 10, 20 o 30 filas por página
  };
  
  const exportToExcel = (data) => {
    var dataRecibida = [... data]
    

    const newArray = dataRecibida.map(obj => {
      const newObj = {};
      newObj.FechaInicio = obj.fechaCreado;
      newObj.FechaFin = obj.fechaFinalizado;
      newObj.TiempoTranscurrido = obj.tiempoTranscurrido;
      newObj.Origen = obj.origenNombre;
      newObj.Destino = obj.destinoNombre;
      newObj.KilometrajeEstablecido = obj.recorridoEstimado;
      newObj.KilometrajeGPS = obj.recorridoGPS;
      newObj.Usuario = obj.de;
      newObj.Comentario = obj.comentario;
      if(user.idUser == "TpNVWvSveQoPgJKVaTi5" || user.rol == "GerenteOperaciones") {
        newObj.Estado = (obj.status ? obj.status : "SOLICITADO") + " " + (obj.commentStatus ? obj.commentStatus : "");
      }
      newObj.Mapa = {
        v: "Ver Mapa",
        t: "s",
        f: `HYPERLINK("https://checklist.papajohns.com.sv/monitor/mapa/${obj.id}", "Ver Mapa")`
      };
      return newObj;
    });

    const workbook = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(newArray);
    sheet['!cols'] = [{ width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }];

    XLSX.utils.book_append_sheet(workbook, sheet, 'Sheet1');
    XLSX.writeFile(workbook, 'historico_rutas.xlsx');
    
  }

  return (
    <div className={styles.view}>
      <Header
        title="HISTORICO DE RUTAS"
        back="/monitor"
      />
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <div style={{display:"flex",marginBottom:"20px"}}>
            <RangePicker style={{
                  width: "400px",
                }}
                format={dateFormat}
                defaultValue={[dayjs(fechaInicio, dateFormat), dayjs(fechaFin, dateFormat)]}
                onChange={onChangeFecha} />

                
            <Button
              style={{marginLeft:"20px"}}
              size="large"
              onClick={() => {
                buscarFechas(fechaInicioSelect,fechaFinSelect)
              }}
              loading={loadingBuscar}
              type={'primary'}
              className={styles.submit}>
                BUSCAR
            </Button>

            <Button
              style={{marginLeft:"20px"}}
              size="large"
              onClick={() => {
                exportToExcel(rutas)
              }}
              loading={loadingBuscar}
              type={'primary'}
              className={styles.submit}>
                Exportar a Excel
            </Button>

                
          </div>
          <Table
            columns={columns}
            dataSource={rutas}
            rowKey="id"
            pagination={paginationOptions}
          />
        </div>
      )}



        <Modal 
        className='primary-modal'
        title="Ruta"
        visible={isModalAsignar}
        style={{ top: 20 }}
        onCancel={() => setIsModalAsignar(false)}
        footer = { <Row>

          <Col span={6} style={{textAlign:"left"}}>
            
              
            
          </Col>

          <Col span={12} style={{textAlign:"right"}}></Col>

          <Col span={6} style={{textAlign:"right"}}>
            <Button 
                // disabled={requests.isSaving}
                loading={loading}
                className={styles.btCerrar}
                type="primary" 
                shape="round" 
                style={{ width: "90%" }} 
                onClick={() => {
                  //save()
                  //asignarAgenteTicket(item,itemAgente)
                }}
                >
                Guardar
            </Button>
          </Col>
        </Row> }>

        <div>
        
          <Form
            name="branch-editor"
            autoComplete="off"
            form={form}
            colon={false}
            requiredMark={false}
            onFinish={save}
          >

          
            
            <Row>
              <Col span={24}>
                <Form.Item
                  label={
                    <span className={styles.label}>
                      Origen
                    </span>
                  }
                  name="origen"
                  className={styles.itemColumn}
                  rules={[{required: true, message: 'Seleccione origen'}]}
                >
                <Select size="large" className={styles.input}>
                  {ubicaciones.map(branch => (
                    <Option key={branch.id} value={branch.id}>{branch.nombre}</Option>
                    ))}
                </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label={
                    <span className={styles.label}>
                      Destino   
                    </span>
                  }
                  name="destino"
                  className={styles.itemColumn}
                  rules={[{required: true, message: 'Seleccione destino'}]}
                >
                <Select size="large" className={styles.input}>
                  {ubicaciones.map(branch => (
                    <Option key={branch.id} value={branch.id}>{branch.nombre}</Option>
                    ))}
                </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label={
                    <span className={styles.label}>
                      Recorrido (km)
                    </span>
                  }
                  name="recorrido"
                  className={styles.itemColumn}
                  rules={[{required: true, message: 'Ingrese recorrido'}]}
                >
                <Input size="large" className={styles.input} />
                </Form.Item>
              </Col>
            </Row>

          
            
            
          </Form>
        </div>

        
        </Modal>

        <Modal  
          okText="Confirmar"
          cancelText="Cancelar"
          confirmLoading={loadingStatus}
          title="Cambiar estado" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
          <Select value={status} style={{ width: "100%" }} onChange={handleSelectChange}>
            <Option value="SOLICITADO">SOLICITADO</Option>
            <Option value="PAGADO">PAGADO</Option>
            <Option value="CON OBSERVACIÓN">CON OBSERVACIÓN</Option>
            <Option value="RECHAZADO">RECHAZADO</Option>
          </Select>
          {(status === "CON OBSERVACIÓN" || status === "RECHAZADO") && (
            <TextArea rows={4}  value={comment} style={{ marginTop: "15px" }}  onChange={e => setComment(e.target.value)} placeholder="Añade tus comentarios aquí..."/>
          )}
        </Modal>

    </div>
  );
};

export default RutasHistoricoUI;
